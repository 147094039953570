import React from 'react';
import GeolevelTable from './GeolevelTable';
import StepTitle from './StepTitle';

interface GeolevelStepContentComponentProps {
  selectedGeolevel: string | null;
  setSelectedGeolevel: (geolevel: string) => void;
}

const GeolevelStepContent = (props: GeolevelStepContentComponentProps) => {
  const { selectedGeolevel, setSelectedGeolevel } = props;
  return (
    <div>
      <StepTitle title="What geographic level do you need?" />
      <GeolevelTable
        selectedGeolevel={selectedGeolevel}
        setSelectedGeolevel={setSelectedGeolevel}
      />
    </div>
  );
};

export default GeolevelStepContent;
