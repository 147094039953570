import React from 'react';
import { Link } from '@reach/router';
import { Menu } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  SendOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

interface SettingsMenuComponentProps {
  path: string;
}

const SettingsMenu = (props: SettingsMenuComponentProps) => {
  const { path } = props;
  return (
    <div className="p-4 bg-white shadow">
      <Menu mode="inline" selectedKeys={[path]} className="border-none">
        <Menu.Item key="/platform/settings/profile" icon={<UserOutlined />}>
          <Link to="profile">Profile</Link>
        </Menu.Item>
        <Menu.Item key="/platform/settings/security" icon={<LockOutlined />}>
          <Link to="security">Security</Link>
        </Menu.Item>
        <Menu.Item
          key="/platform/settings/subscription"
          icon={<ProfileOutlined />}
        >
          <Link to="subscription">Subscription</Link>
        </Menu.Item>
        <Menu.Item key="/platform/settings/sales" icon={<SendOutlined />}>
          <Link to="sales">Contact Sales</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SettingsMenu;
