import React from 'react';
import { Spin, Table, TableColumnType, Tag } from 'antd';
import { DownloadDatum } from '../../../types';

interface DownloadsTableComponentProps {
  loading: boolean;
  data: any[];
}

const DownloadsTable = (props: DownloadsTableComponentProps) => {
  const { loading, data } = props;

  const columns: TableColumnType<any>[] = [
    {
      title: 'Name',
      dataIndex: 'jobName',
      key: 'jobName',
    },
    {
      title: 'Created',
      dataIndex: 'timeRequested',
      key: 'timeRequested',
      render: (timeRequested: string) => {
        const formattedTime = new Date(timeRequested);
        return formattedTime.toLocaleString();
      },
    },
    {
      title: 'Status',
      dataIndex: 'jobStatus',
      key: 'jobStatus',
      render: (jobStatus: string, record: DownloadDatum) => {
        const timeReq = new Date(record.timeRequested).getTime();
        const now = Date.now();

        if ((now - timeReq) / (24 * 3600 * 1000) > 29) {
          return (
            <Tag
              className={'bg-gray-400 text-white border-gray-400 font-semibold'}
            >
              Expired
            </Tag>
          );
        }

        switch (jobStatus) {
          case 'CANCELED':
            return (
              <Tag
                className={'bg-red-400 text-white border-red-400 font-semibold'}
              >
                Canceled
              </Tag>
            );
          case 'FAILED':
            return (
              <Tag
                className={'bg-red-400 text-white border-red-400 font-semibold'}
              >
                Failed
              </Tag>
            );
          case 'IN_PROGRESS':
            return (
              <Tag
                className={
                  'bg-blue-400 text-white border-blue-400 font-semibold'
                }
              >
                In Progress
              </Tag>
            );
          case 'COMPLETED':
            return (
              <Tag
                className={
                  'bg-green-400 text-white border-green-400 font-semibold'
                }
              >
                Completed
              </Tag>
            );
        }
      },
    },
    {
      title: '',
      dataIndex: 'dataUri',
      key: 'dataUri',
      align: 'right',
      render: (dataUri: string, record: DownloadDatum) => {
        const timeReq = new Date(record.timeRequested).getTime();
        const now = Date.now();

        if (
          (now - timeReq) / (24 * 3600 * 1000) < 30 &&
          record.jobStatus === 'COMPLETED'
        ) {
          return (
            <a
              className={'text-blue-400 font-semibold'}
              href={dataUri}
              target={'_blank'}
            >
              Download
            </a>
          );
        }

        return <p className={'text-gray-400'}></p>;
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table columns={columns} dataSource={data} />
    </Spin>
  );
};

export default DownloadsTable;
