import { Spin, Table } from 'antd';
import React from 'react';
import DateCell from './DatasetTable/DateCell';
import ProductFamilyCell from './DatasetTable/ProductFamilyCell';

const columns = [
  {
    width: 30,
  },
  {
    title: 'Dataset',
    dataIndex: 'name',
    render: (text, record) => {
      const { isChild, description, name, enabled } = record;

      if (!isChild) {
        return (
          <ProductFamilyCell
            name={name}
            description={description}
            available={enabled}
          />
        );
      }

      return <DateCell dateString={text} />;
    },
  },
];

interface DatasetTableComponentProps {
  loading: boolean;
  products: any;
  selectedProducts: string[];
  setSelectedProducts: (productInstanceIds: string[]) => void;
}

const DatasetTable = (props: DatasetTableComponentProps) => {
  const { loading, products, selectedProducts, setSelectedProducts } = props;

  return (
    <Spin spinning={loading}>
      <Table
        size="middle"
        className="border"
        columns={columns}
        dataSource={products}
        pagination={false}
        scroll={{ y: 350 }}
        rowSelection={{
          selectedRowKeys: selectedProducts,
          onChange: (rowKeys) => {
            const productInstanceIds = rowKeys.map((val) => val.toString());
            setSelectedProducts(productInstanceIds);
          },
          checkStrictly: false,
          getCheckboxProps: (record) => ({
            disabled: !record.enabled,
          }),
        }}
        expandable={{
          expandIconColumnIndex: 2,
        }}
        rowClassName={(record) => !record.enabled && 'bg-gray-100'}
      />
    </Spin>
  );
};

export default DatasetTable;
