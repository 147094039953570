import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import AttributeListItem from './AttributeListItem';
import { DeepLayerFamily, FilterAttribute } from '../../../../../types';
import { getFilterAttributes } from '../../../../../utils/filter/filterUtils';

interface AttributesPromptProps {
  setActiveAttribute: any;
  hide: any;
  layerFamilies: Map<string, DeepLayerFamily>;
}

const sortAttributes = (
  firstAttr: FilterAttribute,
  secondAttr: FilterAttribute
) => {
  if (firstAttr.name < secondAttr.name) return -1;
  if (firstAttr.name > secondAttr.name) return 1;
  return 0;
};

const AttributesPrompt = (props: AttributesPromptProps) => {
  const { hide, setActiveAttribute, layerFamilies } = props;
  const filterAttributes = getFilterAttributes(layerFamilies);

  return (
    <>
      <div className="flex items-center mb-2">
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={hide}
          className="mr-2"
        />
        <span className="font-bold text-lg pt-2">Select Attribute</span>
      </div>
      <div className="max-h-80 overflow-y-auto">
        {filterAttributes.sort(sortAttributes).map((attribute) => (
          <AttributeListItem
            attribute={attribute}
            key={attribute.name}
            setActiveAttribute={setActiveAttribute}
          />
        ))}
      </div>
    </>
  );
};

export default AttributesPrompt;
