import { useReactiveVar } from '@apollo/client';
import { TreeSelect } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { currGeolevelIdVar, currLayerVar } from '../../../../services/cache';
import { Layer } from '../../../../types';
import {
  getDataNodesFromLayerMap,
  getTreeKeyProductInstanceIdMap,
} from './DownloadUtils';

const { SHOW_PARENT } = TreeSelect;

interface ProductTreeSelectComponentProps {
  layersTree: Map<string, Map<string, Set<Layer>>>;
  setProductInstanceIdsToDownload: Dispatch<SetStateAction<string[]>>;
}

const ProductTreeSelect = (props: ProductTreeSelectComponentProps) => {
  const { layersTree, setProductInstanceIdsToDownload } = props;
  const currLayer = useReactiveVar(currLayerVar);
  const [val, setVal] = useState<string[]>([]);
  const currGeolevelId = useReactiveVar(currGeolevelIdVar);
  const treeData = getDataNodesFromLayerMap(
    layersTree.get(currGeolevelId)!,
    ''
  );
  const treeKeyProductInstanceIdMap: Map<string, string[]> = new Map();

  getTreeKeyProductInstanceIdMap(
    treeData,
    treeKeyProductInstanceIdMap,
    new Set()
  );

  const handleChange = (value: string[]) => {
    const productInstanceIdsToDownload: string[] = [];

    setVal(value);

    value.forEach((key) => {
      productInstanceIdsToDownload.push(
        ...treeKeyProductInstanceIdMap.get(key)!
      );
    });

    setProductInstanceIdsToDownload(productInstanceIdsToDownload);
  };

  useEffect(() => {
    const { product_id } = currLayer;

    if (product_id && !product_id.includes('filter')) {
      setVal([product_id]);
      setProductInstanceIdsToDownload([product_id]);
    } else {
      setVal([]);
    }
  }, [currLayer]);

  const treeProps = {
    treeData,
    value: val,
    onChange: handleChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Click to select the products you would like to download.',
    style: {
      width: '100%',
    },
  };
  return (
    <TreeSelect
      maxTagCount={8}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      {...treeProps}
    />
  );
};

export default ProductTreeSelect;
