import { gql } from '@apollo/client';

const GET_METRICS = gql`
  query GetMetrics(
    $productInstanceId: ID!
    $parentBoundaryId: Int!
    $metricType: MetricType!
    $resultCount: Int
  ) {
    metrics(
      productInstanceId: $productInstanceId
      parentBoundaryId: $parentBoundaryId
      metricType: $metricType
      resultCount: $resultCount
    ) {
      boundaryName
      value
    }
  }
`;

const GET_DISTRIBUTIONS = gql`
  query GetDistributions($productInstanceId: ID!) {
    distributions(productInstanceId: $productInstanceId) {
      productInstanceId
      bucketId
      bucketStart
      bucketEnd
    }
  }
`;

const GET_LEGEND_BINS = gql`
  query LegendBins($productInstanceId: ID!) {
    legendBins(productInstanceId: $productInstanceId) {
      binId
      max
      min
    }
  }
`;

export { GET_LEGEND_BINS, GET_DISTRIBUTIONS, GET_METRICS };
