import React from 'react';
import { Layout } from 'antd';
import Geocoder from './Geocoder';
import DownloadWidget from './DownloadWidget/DownloadWidget';
import { DeepLayerFamily } from '../../../types';
import RegionSelector from './Regions/RegionSelector';

const { Header } = Layout;

interface InsightsHeaderComponentProps {
  layerFamilyMap: Map<string, DeepLayerFamily>;
  regionModalVisible: boolean;
}

const InsightsHeader = (props: InsightsHeaderComponentProps) => {
  const { layerFamilyMap, regionModalVisible } = props;
  return (
    <Header
      style={{ padding: 8, height: 48 }}
      className="bg-white flex justify-between items-center shadow-lg z-20"
    >
      <RegionSelector disabled={regionModalVisible} />
      <Geocoder disabled={regionModalVisible} />
      <DownloadWidget
        layerFamilyMap={layerFamilyMap}
        disabled={regionModalVisible}
      />
    </Header>
  );
};

export default InsightsHeader;
