import React from 'react';
import { DeepLayerFamily } from '../../../../types';
import LayerFamilyButton from './LayerFamilyButton';
import LayerTag from './LayerTag';
import LayerDomainsIcons from '../../../../components/LayerDomainIcons';
import { sortObjectAlphabetically } from '../../../../utils/utils';

interface DomainSectionProps {
  domain: string;
  layerFamilies: DeepLayerFamily[];
  onLayerFamily: () => void;
  last: boolean;
}

const DomainSection = (props: DomainSectionProps) => {
  const { domain, layerFamilies, onLayerFamily, last } = props;
  const DOMAIN_METADATA: any = LayerDomainsIcons;
  const {
    name: domainName,
    colorClass: domainColorClass,
    icon: domainIcon,
  } = DOMAIN_METADATA[domain];

  layerFamilies.sort(sortObjectAlphabetically.bind(this, 'name'));

  return (
    <div className={`${last ? '' : 'mb-8'}`}>
      <LayerTag
        text={domainName}
        colorClass={domainColorClass}
        icon={domainIcon}
      />

      {layerFamilies.map((layerFamily, idx, arr) => {
        return (
          <LayerFamilyButton
            key={layerFamily.product_family_id}
            layerFamily={layerFamily}
            onLayerFamily={onLayerFamily}
            isLast={idx === arr.length - 1}
          />
        );
      })}
    </div>
  );
};

export default DomainSection;
