import { RESOLUTION_MAP } from '../constants/resolutions';
import { currGeolevelIdVar } from '../services/cache';
import { DeepLayerFamily, Layer, LayerFamily } from '../types';

const getLayersByGeolevelId = (layers: Layer[]) => {
  const layersByGeolevelId = new Map<string, Layer[]>();

  layers.forEach((layer) => {
    const { resolution: geolevelId } = layer;

    if (!layersByGeolevelId.has(geolevelId)) {
      layersByGeolevelId.set(geolevelId, []);
    }

    layersByGeolevelId.set(geolevelId, [
      ...layersByGeolevelId.get(geolevelId)!,
      layer,
    ]);
  });

  return layersByGeolevelId;
};

const reduceToClosestGeolevelId = (
  baseGeolevelLod: number,
  prevGeolevelId: string,
  currGeolevelId: string
) => {
  const prevLod = RESOLUTION_MAP.get(prevGeolevelId)!.lod;
  const currLod = RESOLUTION_MAP.get(currGeolevelId)!.lod;

  if (baseGeolevelLod - prevLod < baseGeolevelLod - currLod) {
    return prevGeolevelId;
  }

  return currGeolevelId;
};

const getNextAvailableGeolevelId = (
  layersByGeolevelId: Map<string, Layer[]>,
  currGeolevelId: string
) => {
  const currGeolevelLod: number = RESOLUTION_MAP.get(currGeolevelId)!.lod;
  const availableGeolevelIds = Array.from(layersByGeolevelId.keys());
  return availableGeolevelIds.reduce(
    reduceToClosestGeolevelId.bind(this, currGeolevelLod)
  );
};

const getDefaultLayer = (layers: Layer[]) => {
  const currGeolevelId = currGeolevelIdVar();
  const layersByGeolevelId = getLayersByGeolevelId(layers);

  if (layersByGeolevelId.has(currGeolevelId)) {
    return getMostModernLayer(layersByGeolevelId.get(currGeolevelId)!);
  }

  const nextAvailableGeolevelId = getNextAvailableGeolevelId(
    layersByGeolevelId,
    currGeolevelId
  );
  currGeolevelIdVar(nextAvailableGeolevelId);
  return getMostModernLayer(layersByGeolevelId.get(nextAvailableGeolevelId)!);
};

const getMostModernLayer = (layers: Layer[]) => {
  return layers.sort((lyr1, lyr2) => {
    return lyr2.time_period.localeCompare(lyr1.time_period);
  })[0];
};

const sortObjectAlphabetically = (property: string, objA: any, objB: any) => {
  return sortAlphabetically(true, objA[property], objB[property]);
};

const sortAlphabetically = (forwards = true, strA: string, strB: string) => {
  if (strA < strB) {
    return forwards ? -1 : 1;
  }

  if (strA > strB) {
    return forwards ? 1 : -1;
  }

  return 0;
};

const getDeepLayerFamilyMap = (
  layers: Layer[],
  layerFamilies: LayerFamily[]
) => {
  const deepLayerFamilyMap = new Map<string, DeepLayerFamily>();

  layerFamilies.forEach((layerFamily) => {
    const { product_family_id } = layerFamily;

    deepLayerFamilyMap.set(product_family_id, {
      ...layerFamily,
      layers: [],
    });
  });

  layers.forEach((layer) => {
    const { product_family_id } = layer;
    const deepLayerFamily = deepLayerFamilyMap.get(product_family_id);

    if (deepLayerFamily) {
      deepLayerFamily.layers.push(layer);
    }
  });

  return deepLayerFamilyMap;
};

export {
  getDefaultLayer,
  sortAlphabetically,
  sortObjectAlphabetically,
  getDeepLayerFamilyMap,
};
