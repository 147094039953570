import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { createSelectionActiveVar } from '../../../../services/cache';
import { useReactiveVar } from '@apollo/client';
import { SelectOutlined } from '@ant-design/icons';
import {
  handleSelectionActivation,
  handleSelectionDeactivation,
  resetSelectionLayer,
} from '../Map/mapUtils';

enum CreateSelectionTooltipText {
  Activated = 'Stop selecting areas of interest.',
  Deactivated = 'Select area(s) of interest for data export.',
}

const CreateSelectionButton = () => {
  const createSelectionActive = useReactiveVar(createSelectionActiveVar);
  const [active, setActive] = useState(createSelectionActiveVar());
  const [tooltipText, setTooltipText] = useState(
    createSelectionActive
      ? CreateSelectionTooltipText.Activated
      : CreateSelectionTooltipText.Deactivated
  );

  const activate = () => {
    createSelectionActiveVar(true);
    handleSelectionActivation();
  };

  const deactivate = () => {
    handleSelectionDeactivation();
  };

  const handleClick = () => {
    if (createSelectionActiveVar()) {
      deactivate();
    } else {
      activate();
    }
  };

  useEffect(() => {
    setActive(createSelectionActive);
    setTooltipText(
      createSelectionActive
        ? CreateSelectionTooltipText.Activated
        : CreateSelectionTooltipText.Deactivated
    );
  }, [createSelectionActive]);

  return (
    <Tooltip title={tooltipText} overlayStyle={{ maxWidth: 600 }}>
      <Button
        className={`ml-3 focus:text-black ${active ? 'text-blue-500' : null}`}
        onClick={handleClick}
      >
        <SelectOutlined className={'relative'} style={{ top: '-2px' }} />
      </Button>
    </Tooltip>
  );
};

export default CreateSelectionButton;
