import { Button, Select, Spin } from 'antd';
import React, { useState } from 'react';
import SUBSCRIPTION_INFO_MAP from '../../../../constants/subscriptionPlanInfo';
import {
  Header1,
  InputDescription,
  Paragraph,
} from '../../../shared/Typography';
import SectionComponent from './Section';
import { navigate } from '@reach/router';

const { Option } = Select;

const MobileSubscriptionInfo = () => {
  const [currSubscriptionPlan, setCurrSubscriptionPlanInfo] = useState('free');

  const SECTIONS: any[] = [];
  const OPTIONS: any[] = [];

  const val = SUBSCRIPTION_INFO_MAP.get(currSubscriptionPlan);
  SUBSCRIPTION_INFO_MAP.forEach((info, key) => {
    OPTIONS.push(
      <Option value={key} key={key}>
        {info.name}
      </Option>
    );
  });

  const handleContactClick = () => {
    navigate('sales');
  };

  if (val) {
    const { name, sections, cost } = val;

    SECTIONS.push(
      ...sections.map(({ name, content }, idx) => (
        <SectionComponent name={name} content={content} key={idx} />
      ))
    );

    return (
      <div>
        {/* SELECT DROPDOWN */}
        <div className="border border-blue-400 py-4 px-2 flex justify-between rounded mb-10 bg-blue-100">
          <Paragraph>View details for:</Paragraph>
          <Select
            defaultValue="free"
            onSelect={(value) => {
              setCurrSubscriptionPlanInfo(value);
            }}
          >
            {OPTIONS}
          </Select>
        </div>

        {/* PLAN & CONTACT SALES BUTTON */}
        <div className="flex justify-between">
          <div>
            <Header1>{name}</Header1>
            <InputDescription className="text-gray-600">
              {cost}
            </InputDescription>
          </div>
          <Button type="primary" className="mt-1" onClick={handleContactClick}>
            <span className="font-semibold rounded text-small px-1">
              Contact Sales
            </span>
          </Button>
        </div>

        {/* CONTENT SECTIONS */}
        {SECTIONS}
      </div>
    );
  }

  return <Spin spinning />;
};

export default MobileSubscriptionInfo;
