import React from 'react';
import firebase from 'firebase';
import { Button, message } from 'antd';
import { Header1, InputDescription } from '../../shared/Typography';
import AtlasDivider from '../../shared/AtlasDivider';
import { Breakpoint } from 'react-socks';

const ResetPasswordSection = () => {
  const handleResetPassword = async () => {
    const auth = firebase.auth();
    const user = auth.currentUser;

    try {
      auth.sendPasswordResetEmail(user?.email);
      message.info('Password reset email sent.');
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div>
      <Header1>Reset Password</Header1>
      <AtlasDivider />
      <div>
        <Breakpoint medium down>
          <Button
            onClick={handleResetPassword}
            className="text-blue-500 rounded px-3 my-3 font-semibold"
          >
            Reset Password
          </Button>
        </Breakpoint>
        <Breakpoint large up>
          <Button
            type="link"
            size="large"
            style={{ padding: 0, margin: 0 }}
            onClick={handleResetPassword}
          >
            Reset Password
          </Button>
        </Breakpoint>
      </div>

      <InputDescription className="text-gray-400">
        For your safety and security, we’ll send you an email with next steps.
        Please follow the instructions to reset your password.
      </InputDescription>
    </div>
  );
};

export default ResetPasswordSection;
