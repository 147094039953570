import { gql } from '@apollo/client';

const GET_REGIONS = gql`
  query GetRegions {
    regions {
      id
      name
      flyTo {
        center
        zoom
      }
      defaultImgUrl
      hoverImgUrl
      selectedImgUrl
    }
  }
`;

const GET_USER_REGIONS = gql`
  query GetUserRegions {
    userRegions {
      id
      name
      flyTo {
        center
        zoom
      }
      defaultImgUrl
      hoverImgUrl
      selectedImgUrl
    }
  }
`;

const GET_LAYERS_AND_LAYER_FAMILIES_BY_REGION = gql`
  query GetLayersAndLayerFamiliesByRegion($regionId: String!) {
    region(regionId: $regionId) {
      layers {
        end_date
        filterDescription
        filterType
        id
        name
        product_family_id
        product_id
        resolution
        style
        time_period
        regionId
      }
      layerFamilies {
        category_labels
        coverage
        description
        domain
        human_id
        last_update
        name
        product_family_id
        resolutions
        update_frequency
        unit
        unitDescription
        roundTo
      }
    }
  }
`;

const GET_USER_FIRST_NAME = gql`
  query GetUserFirstName {
    me {
      first_name
    }
  }
`;

const GET_USER_PROFILE = gql`
  query GetUserProfile {
    me {
      id
      first_name
      last_name
      email
    }
  }
`;

const GET_API_ACCESS = gql`
  query GetAPIAccess {
    me {
      activeSubscription {
        subscriptionCatalog {
          api_access
        }
      }
    }
  }
`;

const GET_LAYERS_BY_FAMILY = gql`
  query GetLayersByFamily {
    layerFamilies {
      name
      human_id
      domain
      resolutions
    }
    me {
      activeSubscription {
        subscriptionCatalog {
          layersByFamily {
            layerFamily {
              name
              domain
              description
              coverage
              last_update
              update_frequency
              human_id
              product_family_id
              category_labels
              unit
              unitDescription
              roundTo
            }
            layers {
              id
              name
              time_period
              resolution
              style
              product_id
              product_family_id
              filterType
              filterDescription
              end_date
              regionId
            }
          }
        }
      }
    }
  }
`;

const GET_DOWNLOAD_INFO_BY_REGION = gql`
  query GetDownloadInfoByRegion($regionId: String!) {
    region(regionId: $regionId) {
      layerFamilies {
        name
        description
        product_family_id
      }
      layers {
        product_id
        product_family_id
        time_period
        resolution
      }
    }
  }
`;

export {
  GET_REGIONS,
  GET_USER_REGIONS,
  GET_USER_FIRST_NAME,
  GET_USER_PROFILE,
  GET_LAYERS_BY_FAMILY,
  GET_LAYERS_AND_LAYER_FAMILIES_BY_REGION,
  GET_DOWNLOAD_INFO_BY_REGION,
  GET_API_ACCESS,
};
