import React, { SetStateAction } from 'react';
import { Button, Form, FormInstance, Input } from 'antd';
import { Layer } from '../../../../types';
import ProductTreeSelect from './ProductTreeSelect';
import { Dispatch } from 'react';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useReactiveVar } from '@apollo/client';
import { currLayerVar } from '../../../../services/cache';

const { Item } = Form;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { span: 16, offset: 4 },
};

interface DownloadFormComponentProps {
  form: FormInstance<any> | undefined;
  handleFinish: ((values: any) => void) | undefined;
  validSelection: boolean;
  setProductInstanceIdsToDownload: Dispatch<SetStateAction<string[]>>;
  layersTree: Map<string, Map<string, Set<Layer>>>;
}

const DownloadForm = (props: DownloadFormComponentProps) => {
  const {
    form,
    handleFinish,
    validSelection,
    layersTree,
    setProductInstanceIdsToDownload,
  } = props;
  const currLayer = useReactiveVar(currLayerVar);
  const isFilterLayer = currLayer.product_id.includes('filter');

  return (
    <Form
      form={form}
      name={'download'}
      onFinish={handleFinish}
      requiredMark={false}
      validateMessages={{
        required: 'Field is required.',
      }}
      {...layout}
    >
      <Item
        label={<h2 className={'font-semibold'}>Name</h2>}
        name={'name'}
        required={true}
        rules={[{ required: true }]}
      >
        <Input placeholder={'Name your download.'} />
      </Item>

      <Item
        name={'productInstanceIds'}
        required={true}
        label={<h2 className={'font-semibold'}>Products</h2>}
      >
        <ProductTreeSelect
          layersTree={layersTree}
          setProductInstanceIdsToDownload={setProductInstanceIdsToDownload}
        />
      </Item>

      {isFilterLayer ? (
        <Item
          className={validSelection ? 'mb-0' : ''}
          name="filteredOnly"
          valuePropName="checked"
          {...tailLayout}
        >
          <Checkbox>Download filtered areas only</Checkbox>
        </Item>
      ) : null}

      {validSelection ? (
        <Item valuePropName="checked" name="selectedOnly" {...tailLayout}>
          <Checkbox>Download selected areas only</Checkbox>
        </Item>
      ) : null}

      <Item {...tailLayout}>
        <Button type={'primary'} htmlType={'submit'}>
          Download
        </Button>
      </Item>
    </Form>
  );
};

export default DownloadForm;
