import { Tag } from 'antd';
import React from 'react';

interface ProductFamilyCellComponentProps {
  name: string;
  description: string;
  available: boolean;
}

const ProductFamilyCell = (props: ProductFamilyCellComponentProps) => {
  const { name, description, available } = props;

  if (available) {
    return (
      <div className="flex">
        <div>
          <h1 className="font-semibold">{name}</h1>
          <p className="text-xs text-gray-600">{description}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex">
      <div>
        <div>
          <h1 className="font-semibold inline mr-2">{name}</h1>
          <Tag color="orange">Unavailable</Tag>
        </div>
        <p className="text-xs text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default ProductFamilyCell;
