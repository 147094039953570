import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { navigate } from '@reach/router';
import subscriptionPlanInfo, {
  SubscriptionInfo,
} from '../../../../constants/subscriptionPlanInfo';

const { Text } = Typography;

const DesktopSubscriptionInfo = () => {
  const handleContactClick = () => {
    navigate('sales');
  };

  const free = subscriptionPlanInfo.get('free');
  const macro = subscriptionPlanInfo.get('macro');
  const enterprise = subscriptionPlanInfo.get('enterprise');

  const renderSection = function (sectionName = 'Data Layers') {
    const productFeatures = subscriptionPlanInfo
      .get('enterprise')!
      .sections.filter((x) => x.name === sectionName)[0]
      .content.map((content) => content.name);

    const isFeatIncluded = (subscription: any, productFeatureName: string) => {
      if (
        subscription.sections
          .filter((x: any) => x.name === sectionName)[0]
          .content.filter(
            (content: any) => content.name === productFeatureName
          )[0].checked
      )
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;

      return '-';
    };

    return productFeatures?.map((feat) => {
      return (
        <Row>
          <Col className="my-4" span={6}>
            <Row align="middle">
              <Text type="secondary" className="mr-2">
                {feat}
              </Text>
            </Row>
          </Col>

          <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
            {isFeatIncluded(free!, feat)}
          </Col>

          <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
            {isFeatIncluded(macro!, feat)}
          </Col>

          <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
            {isFeatIncluded(enterprise!, feat)}
          </Col>
        </Row>
      );
    });
  };

  return (
    <>
      <Row gutter={48} className="mt-20">
        <Col span={6} offset={6}>
          <h2 className="font-bold text-lg">{free?.name}</h2>
          <Text type="secondary">{free?.cost}</Text>
          <Button style={{ marginTop: 20 }} block>
            Free
          </Button>
        </Col>

        <Col span={6}>
          <h2 className="font-bold text-lg">{macro?.name}</h2>
          <Text type="secondary">{macro?.cost}</Text>
          <Button
            type="primary"
            style={{ marginTop: 20 }}
            onClick={handleContactClick}
            block
          >
            Contact Sales
          </Button>
        </Col>

        <Col span={6}>
          <h2 className="font-bold text-lg">{enterprise?.name}</h2>
          <Text type="secondary">{enterprise?.cost}</Text>
          <Button
            type="primary"
            style={{ marginTop: 20 }}
            onClick={handleContactClick}
            block
          >
            Contact Sales
          </Button>
        </Col>
      </Row>

      <Row className="mt-16">
        <Col span={24}>
          <h2 className="font-bold text-lg">Data Layers</h2>
        </Col>
      </Row>

      {renderSection('Data Layers')}

      <Row className="mt-10">
        <Col span={24}>
          <h2 className="font-bold text-lg">Resolution</h2>
        </Col>
      </Row>

      {renderSection('Resolution')}

      <Row className="mt-10">
        <Col span={24}>
          <h2 className="font-bold text-lg">Time of Interest</h2>
        </Col>
      </Row>

      <Row>
        <Col className="my-4" span={6}>
          <Row align="middle">
            <Text type="secondary" className="mr-2">
              Time Range
            </Text>
          </Row>
        </Col>

        <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {free?.timeOfInterest}
          </Text>
        </Col>

        <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {macro?.timeOfInterest}
          </Text>
        </Col>

        <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {enterprise?.timeOfInterest}
          </Text>
        </Col>
      </Row>

      <Row className="mt-10">
        <Col span={24}>
          <h2 className="font-bold text-lg">Seats</h2>
        </Col>
      </Row>

      <Row>
        <Col className="my-4" span={6}>
          <Row align="middle">
            <Text type="secondary" className="mr-2">
              Seats Included
            </Text>
          </Row>
        </Col>

        <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {free?.seats}
          </Text>
        </Col>

        <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {macro?.seats}
          </Text>
        </Col>

        <Col className="my-4" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {enterprise?.seats}
          </Text>
        </Col>
      </Row>

      <Row className="mt-10">
        <Col span={24}>
          <h2 className="font-bold text-lg">License</h2>
        </Col>
      </Row>

      <Row>
        <Col className="my-4" span={6}>
          <Row align="middle">
            <Text type="secondary" className="mr-2">
              License Type
            </Text>
          </Row>
        </Col>

        <Col className="my-4 px-10" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {free?.license}
          </Text>
        </Col>

        <Col className="my-4 px-10" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {macro?.license}
          </Text>
        </Col>

        <Col className="my-4 px-10" span={6} style={{ textAlign: 'center' }}>
          <Text type="secondary" className="mr-2">
            {enterprise?.license}
          </Text>
        </Col>
      </Row>
    </>
  );
};

export default DesktopSubscriptionInfo;
