import {
  currLayerFamilyVar,
  currLayerVar,
  filterableLayerFamiliesVar,
  filtersVar,
  mapVar,
  mapDataLoadingVar,
  zoomVar,
  currRegionVar,
  createSelectionActiveVar,
} from '../../../../services/cache';
import mapboxgl, { MapMouseEvent } from 'mapbox-gl';
import { addCanvasListeners } from './canvasUtils';
import {
  DeepLayerFamily,
  Filter,
  Layer,
  PlaceType,
  StyleLayerType,
} from '../../../../types';
import { showPopup } from './popupUtils';
import { getStyleLayer } from './styleUtils';
import { RESOLUTION_MAP } from '../../../../constants/resolutions';

const getZoomLevelFromPlaceType = (
  placeType: PlaceType = PlaceType.Country
) => {
  switch (placeType) {
    case PlaceType.Address:
    case PlaceType.Neighborhood:
    case PlaceType.Locality:
    case PlaceType.Place:
      return 12;
    case PlaceType.District:
    case PlaceType.Postcode:
      return 7;
    case PlaceType.Region:
    case PlaceType.Country:
      return 5;
    default:
      return 3;
  }
};

const zoomToLngLat = (
  lngLat: mapboxgl.LngLatLike,
  placeType?: PlaceType,
  zoom?: number
) => {
  const map = mapVar();

  if (map) {
    map.flyTo({
      center: lngLat,
      zoom: zoom || getZoomLevelFromPlaceType(placeType),
    });
  }
};

const handleSelectionActivation = () => {
  const map = mapVar();

  if (map) {
    mapVar()!.getCanvas().style.cursor = 'crosshair';
  }
};

const handleSelectionDeactivation = () => {
  const map = mapVar();
  createSelectionActiveVar(false);
  resetSelectionLayer();

  if (map) {
    mapVar()!.getCanvas().style.cursor = 'grab';
  }
};

const downloadMapAsPng = () => {
  const map = mapVar();

  if (map) {
    const img = map.getCanvas().toDataURL('image/png');
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = img;
    a.download = 'aperture-map.png';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(img);
  }
};

const resetSelectionLayer = () => {
  safelySetFilter('selection', ['in', '$id', '']);
};

const cleanUp = () => {
  const map = mapVar();

  if (map) {
    map.remove();
    mapVar(null);
    zoomVar(null);
  }
};

const handleMove = () => {
  const map = mapVar();

  if (map) {
    zoomVar(parseFloat(map!.getZoom().toFixed(2)));
  }
};

const handleClick = (evt: MapMouseEvent) => {
  showPopup(evt);
};

const handleContextMenu = () => {};

const handleLoad = () => {
  addCanvasListeners();
  addMapListeners();
};

const handleRender = () => {
  const map = mapVar();
  const currLayer = currLayerVar();
  let productId: string;
  if (!map || !currLayer) {
    return;
  }

  productId = currLayer.product_id;

  if (map.getLayer('filter')) {
    handleMoveEnd();

    productId = 'filter';
  }

  if (
    (productId === 'filter' ||
      (map.getSource(productId) && map.isSourceLoaded(productId))) &&
    map.isStyleLoaded() &&
    map.areTilesLoaded()
  ) {
    mapDataLoadingVar(false);
  } else {
    mapDataLoadingVar(true);
  }
};

const intersect = (a: number[], b: number[]) => {
  const setA = new Set(a);
  const setB = new Set(b);
  const intersection = new Set(
    [...Array.from(setA)].filter((x) => setB.has(x))
  );
  return Array.from(intersection);
};

const getNonFilteredFeatureIds = (map: mapboxgl.Map) => {
  const filters = filtersVar();
  if (filters!.size === 0) {
    return null;
  }

  const layerIds = Array.from(filters.values())
    .map((filter) => {
      return filter.filterInfo.productInstanceId;
    })
    .filter((productInstanceId, idx, allIds) => {
      return allIds.indexOf(productInstanceId) === idx;
    });

  const featureIdsByLayer: number[][] = layerIds
    .filter((layerId) => {
      if (map.getLayer(layerId)) {
        return true;
      }
      return false;
    })
    .map((layerId) => {
      const renderedFeatures = map.queryRenderedFeatures(undefined, {
        layers: [layerId],
      });

      const idArr: number[] = renderedFeatures.map((feature) => {
        const idAny: any = feature.id;
        const idNum: number = idAny;
        return idNum;
      });
      return idArr;
    });

  return featureIdsByLayer.reduce(
    (prevLayerFeatureIds, currLayerFeatureIds) => {
      return intersect(prevLayerFeatureIds, currLayerFeatureIds);
    },
    featureIdsByLayer[0]
  );
};

const filterFeatures = () => {
  const map = mapVar()!;
  const nonFilteredFeaturesIds = getNonFilteredFeatureIds(map);

  if (map.getLayer('filter') && nonFilteredFeaturesIds) {
    const filter: any[] = ['in', '$id', ...nonFilteredFeaturesIds];
    safelySetFilter('filter', filter);
  }
};

const handleMoveEnd = () => {
  const map = mapVar();
  const currLayerFamily: DeepLayerFamily | null = currLayerFamilyVar();

  if (map && currLayerFamily && currLayerFamily.human_id.includes('filter')) {
    filterFeatures();
  }
};

const addMapListeners = () => {
  const map = mapVar();

  if (map) {
    map.on('move', handleMove);
    map.on('click', handleClick);
    map.on('contextmenu', handleContextMenu);
    map.on('moveend', handleMoveEnd);
    map.on('render', handleRender);
  }
};

const getDefaultMapOptions = (
  container: string | HTMLDivElement
): mapboxgl.MapboxOptions => {
  const currRegion = currRegionVar();
  let center: [number, number] = [18.26, 1.24];
  let zoom = 3.05;

  if (currRegion && currRegion.flyTo) {
    center = currRegion.flyTo.center;
    zoom = currRegion.flyTo.zoom;
  }

  return {
    center,
    zoom,
    minZoom: 2,
    preserveDrawingBuffer: true,
    style: 'mapbox://styles/mapbox/dark-v10',
    dragRotate: false,
    touchZoomRotate: false,
    container,
    attributionControl: false,
  };
};

const safelyAddSource = (
  sourceId: string,
  source: mapboxgl.AnySourceData
): Promise<boolean> => {
  const sourceAdded = new Promise<boolean>((resolve, reject) => {
    const map = mapVar();

    if (!map) {
      reject();
      return;
    }

    if (map && map.getSource(sourceId)) {
      if (map.getLayer(sourceId)) {
        map.removeLayer(sourceId);
      }

      if (map.getLayer('selection')) {
        map.removeLayer('selection');
      }

      map.removeSource(sourceId);
    }

    if (map && !map.getSource(sourceId) && map.isStyleLoaded()) {
      map.addSource(sourceId, source);
      resolve(true);
    }

    if (map && !map.getSource(sourceId) && !map.isStyleLoaded()) {
      setTimeout(() => {
        safelyAddSource(sourceId, source).then(() => {
          resolve(true);
        });
      }, 200);
    }
  });

  return sourceAdded;
};

const safelySetFilter = (layerId: string, filter: any[] | null) => {
  const map = mapVar();

  if (map && map.getLayer(layerId)) {
    map.setFilter(layerId, filter);
  }
};

const safelyRemoveLayer = (layerId: string) => {
  const map = mapVar();

  if (map && map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }
};

const safelyRemoveSource = (layerId: string) => {
  const map = mapVar();

  if (map && map.getSource(layerId)) {
    map.removeSource(layerId);
  }
};

const removeFilterLayersAndSources = () => {
  const filterableLayerFamilies = filterableLayerFamiliesVar();
  const map = mapVar();

  if (map) {
    safelyRemoveLayer('filter');
    safelyRemoveLayer('selection');

    filterableLayerFamilies.forEach((layerFam) => {
      const layer = layerFam.layers[0];

      if (layer.product_id) {
        safelyRemoveLayer(layer.product_id);
        safelyRemoveSource(layer.product_id);
      }
    });
  }
};

const removePrevLayer = (prevLayer: Layer | null, currLayer: Layer | null) => {
  const map = mapVar();

  if (
    map &&
    prevLayer &&
    currLayer &&
    prevLayer.product_id !== currLayer.product_id
  ) {
    if (prevLayer.product_family_id === 'filter') {
      removeFilterLayersAndSources();
      return;
    }

    if (prevLayer.product_id && map?.getLayer(prevLayer.product_id)) {
      map.removeLayer(prevLayer.product_id);
      safelyRemoveLayer('selection');
    }

    if (prevLayer.product_id && map?.getSource(prevLayer.product_id)) {
      map.removeSource(prevLayer.product_id);
    }
  }
};

const createSource = (layer: Layer): mapboxgl.VectorSource => {
  const { product_id, resolution } = layer;
  const geolevel = RESOLUTION_MAP.get(resolution);
  const { minzoom, maxzoom } = geolevel!;

  const source: mapboxgl.VectorSource = {
    type: 'vector',
    tiles: [
      `https://storage.googleapis.com/tiles-aperture/${product_id}/{z}/{x}/{y}.pbf?update=${Math.random().toString()}`,
    ],
    minzoom,
  };

  if (maxzoom) {
    source.maxzoom = maxzoom;
  }

  return source;
};

const createStyleLayer = async (
  layer: Layer,
  visible = true,
  layerType: StyleLayerType = StyleLayerType.Product,
  layerFamily: DeepLayerFamily | null = currLayerFamilyVar()
) => {
  const { product_id, resolution, filterType } = layer;
  const { human_id, category_labels } = layerFamily!;
  return await getStyleLayer(
    product_id,
    resolution,
    human_id,
    filterType,
    category_labels ? category_labels.length : 0,
    layerType,
    visible
  );
};

const addProductFilterLayers = async (
  families: Map<string, DeepLayerFamily>
) => {
  const map = mapVar()!;
  const productLayerPromises: Promise<
    mapboxgl.FillLayer | mapboxgl.CircleLayer
  >[] = [];

  const sourcesAdded: Promise<any>[] = [];

  families.forEach((family) => {
    const layer = family.layers[0];
    const source = createSource(layer);
    sourcesAdded.push(safelyAddSource(layer.product_id, source));
    productLayerPromises.push(
      createStyleLayer(layer, false, StyleLayerType.Product, family)
    );
  });

  await Promise.all(sourcesAdded);
  const styleLayers = await Promise.all(productLayerPromises);

  styleLayers.forEach((styleLayer) => {
    map.addLayer(styleLayer, 'road-label');
  });
};

const getFilterLayer = (families: DeepLayerFamily[]) => {
  let idx;

  for (idx = 0; idx < families.length; idx += 1) {
    if (families[idx].human_id === 'population') {
      return families[idx].layers[0];
    }
  }

  return null;
};

const handleFilterLayer = async (families: Map<string, DeepLayerFamily>) => {
  const map = mapVar()!;
  const layer = getFilterLayer(Array.from(families.values()))!;
  await addProductFilterLayers(families);

  const filterLayer = await createStyleLayer(
    layer,
    true,
    StyleLayerType.Filter
  );
  const selectionLayer = await createStyleLayer(
    layer,
    true,
    StyleLayerType.Selection
  );

  if (map.getLayer('filter')) {
    map.removeLayer('filter');
  }

  if (map.getLayer('selection')) {
    map.removeLayer('selection');
  }

  map.addLayer(filterLayer);
  map.addLayer(selectionLayer);

  // Set the default expression on the selection layer.
  safelySetFilter('selection', ['in', '$id', '']);

  handleFilterApplied();
};

const addCurrLayer = async (currLayer: Layer | null) => {
  const map = mapVar();
  const filterableLayerFamilies = filterableLayerFamiliesVar();

  if (map === null || currLayer === null) {
    return;
  }

  const { product_id, product_family_id } = currLayer!;
  const currLayerRegion = product_id.split('/')[0];

  // Don't do anything if the layer already is in the map.
  if (product_family_id === 'filter' && filterableLayerFamilies.size > 0) {
    const exampleFamily: DeepLayerFamily = filterableLayerFamilies
      .values()
      .next().value;
    const familyRegion = exampleFamily.layers[0]!.name.split('/')[0];

    if (currLayerRegion === familyRegion) {
      handleFilterLayer(filterableLayerFamilies);
    }

    return;
  }

  if (product_family_id === 'filter') {
    return;
  }

  const source = createSource(currLayer);

  const styleLayer = await createStyleLayer(
    currLayer,
    true,
    StyleLayerType.Product
  );

  const selectionLayer = await createStyleLayer(
    currLayer,
    true,
    StyleLayerType.Selection
  );

  try {
    await safelyAddSource(product_id, source);

    if (!map.getLayer(product_id)) {
      map.addLayer(styleLayer, 'road-label');
    }

    if (map.getLayer('selection')) {
      map.removeLayer('selection');
    }

    map.addLayer(selectionLayer);
    safelySetFilter('selection', ['in', '$id', '']);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const removeExistingFilters = (
  map: mapboxgl.Map,
  filterLayerFamilies: Map<string, DeepLayerFamily>
) => {
  const layerIds = Array.from(filterLayerFamilies.values()).map(
    ({ layers }) => layers[0].product_id
  );

  layerIds.forEach((id) => {
    safelySetFilter(id, null);
  });

  safelySetFilter('filter', null);
};

const getFilterExpressionsByProductInstanceId = (
  filters: Map<number, Filter>
): Map<string, any[]> => {
  const expressionsMap = new Map<string, any[]>();

  filters.forEach((filter) => {
    const {
      expressions,
      filterInfo: { productInstanceId },
    } = filter;

    if (expressionsMap.has(productInstanceId)) {
      expressionsMap.set(productInstanceId, [
        ...expressionsMap.get(productInstanceId)!,
        ...expressions,
      ]);
    } else {
      expressionsMap.set(productInstanceId, expressions);
    }
  });

  return expressionsMap;
};

const applyFilters = (map: mapboxgl.Map, filters: Map<number, Filter>) => {
  const filterExpressionsByProductInstanceId =
    getFilterExpressionsByProductInstanceId(filters);

  filterExpressionsByProductInstanceId.forEach(
    (expressionSet, productInstanceId) => {
      safelySetFilter(productInstanceId, ['all', ...expressionSet]);
    }
  );
};

const handleFilterApplied = () => {
  const map = mapVar();
  const filters = filtersVar();
  const filterableLayerFamilies = filterableLayerFamiliesVar();

  if (map) {
    removeExistingFilters(map, filterableLayerFamilies);
    applyFilters(map, filters);
  }
};

export {
  addCurrLayer,
  removePrevLayer,
  cleanUp,
  addMapListeners,
  getDefaultMapOptions,
  handleLoad,
  handleFilterApplied,
  resetSelectionLayer,
  downloadMapAsPng,
  handleSelectionActivation,
  handleSelectionDeactivation,
  zoomToLngLat,
  removeFilterLayersAndSources,
};
