import { useReactiveVar } from '@apollo/client';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  AFRICA_COUNTRY_CODES,
  SOUTH_ASIA_COUNTRY_CODES,
} from '../../../../../constants/countryCodes';
import { currRegionVar } from '../../../../../services/cache';

const columns = [{ title: 'Country', dataIndex: 'name' }];

interface CountryTableComponentProps {
  selectedCountries: number[];
  selectCountries: (countries: number[]) => void;
}

const CountryTable = (props: CountryTableComponentProps) => {
  const {
    selectCountries: setSelectedCountryIds,
    selectedCountries: selectedCountryIds,
  } = props;
  const [countryData, setCountryData] = useState([]);

  const currRegion = useReactiveVar(currRegionVar);

  useEffect(() => {
    if (currRegion && currRegion.name === 'Africa') {
      setCountryData(
        AFRICA_COUNTRY_CODES.map(({ id, name }) => ({ key: id, name }))
      );
    } else if (currRegion) {
      setCountryData(
        SOUTH_ASIA_COUNTRY_CODES.map(({ id, name }) => ({ key: id, name }))
      );
    }
  }, [currRegion]);

  return (
    <Table
      size="middle"
      className="border"
      columns={columns}
      dataSource={countryData}
      rowSelection={{
        selectedRowKeys: selectedCountryIds,
        onChange: (selectedRowKeys) => {
          setSelectedCountryIds(
            selectedRowKeys.map((key) => parseInt(key.toString()))
          );
        },
      }}
      pagination={false}
      scroll={{ y: 350 }}
    />
  );
};

export default CountryTable;
