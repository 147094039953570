import React from 'react';
import firebase from 'firebase';
import { Form, Input, Button, message } from 'antd';
import { navigate, Redirect, RouteComponentProps } from '@reach/router';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../services/cache';
import { EnvironmentTwoTone, MailOutlined } from '@ant-design/icons';
import AuthenticationPage from './AuthenticationPage';

const LogInPage = (props: RouteComponentProps) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const validateMessages = {
    required: 'This field is required.',
    types: {
      email: 'Please enter a valid email address.',
    },
  };

  const handleResetPassword = async (values: any) => {
    const { email } = values;
    const auth = firebase.auth();

    try {
      await auth.sendPasswordResetEmail(email);
      message.info('Password reset email sent!');
      navigate('/login');
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleResetPasswordFail = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  if (isLoggedIn) {
    return <Redirect from="" to="/platform/aperture" noThrow />;
  }

  return (
    <AuthenticationPage
      title="Forgot Password?"
      description="Enter your email below to reset your password."
      linkTo="/login"
      linkText="Back to Login"
    >
      <Form
        name="basic"
        initialValues={{}}
        onFinish={handleResetPassword}
        onFinishFailed={handleResetPasswordFail}
        layout="vertical"
        requiredMark={false}
        validateMessages={validateMessages}
      >
        <Form.Item
          className="mt-7"
          label={
            <p className="text-gray-700 tracking-normal">Email address*</p>
          }
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input placeholder="jane@doe.org" />
        </Form.Item>

        <Form.Item className="mt-8">
          <Button
            type="primary"
            className="rounded w-full pt-2 pb-8 font-semibold"
            htmlType="submit"
            size="middle"
          >
            Send Password Reset Email
          </Button>
        </Form.Item>
      </Form>
    </AuthenticationPage>
  );
};

export default LogInPage;
