import React from 'react';
import { Filter } from '../../../../types';

interface FilterListComponentProps {
  filters: Map<number, Filter>;
}

const FilterList = (props: FilterListComponentProps) => {
  const { filters } = props;
  const filterElementList: JSX.Element[] = [];

  filters.forEach((filter, id) => {
    const { label } = filter;

    filterElementList.push(
      <div key={id} className="mb-2 bg-gray-100 p-3 border border-gray-300">
        <p className="text-xs leading-5">{label}</p>
      </div>
    );
  });

  return (
    <div>
      <h4 className="font-bold uppercase text-xs mb-2 text-gray-400"></h4>
      {filterElementList}
    </div>
  );
};

export default FilterList;
