import React from 'react';

const EconomicSVG: any = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4165 8.33331H3.74984V14.1666H5.4165V8.33331ZM10.4165 8.33331H8.74984V14.1666H10.4165V8.33331ZM17.4998 15.8333H1.6665V17.5H17.4998V15.8333ZM15.4165 8.33331H13.7498V14.1666H15.4165V8.33331ZM9.58317 2.71665L13.9248 4.99998H5.2415L9.58317 2.71665ZM9.58317 0.833313L1.6665 4.99998V6.66665H17.4998V4.99998L9.58317 0.833313Z"
      fill="#24292E"
    />
  </svg>
);

export default EconomicSVG;
