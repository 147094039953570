import { FIREBASE_CONFIG } from './config/firebase';
import firebase from 'firebase';
import HekatonClient from './services/clients/HekatonClient';

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { isLoggedInVar, userVar, apertureTokenVar } from './services/cache';
import App from './pages/app/App';
import reportWebVitals from './reportWebVitals';

import { globalHistory } from '@reach/router';
import './index.less';
import userflow from 'userflow.js';

firebase.initializeApp(FIREBASE_CONFIG);
const analytics = firebase.analytics();

firebase.auth().onAuthStateChanged((user) => {
  // If the user is logged in.
  if (user) {
    userVar([user]);
    isLoggedInVar(true);

    user.getIdToken().then((token) => {
      apertureTokenVar(token);
    });

    setInterval(() => {
      firebase
        .auth()
        .currentUser?.getIdToken(true)
        .then((token) => {
          apertureTokenVar(token);
        });
    }, 3500000);
  }

  // If the user is not logged in.
  else {
    userVar([]);
    isLoggedInVar(false);
    apertureTokenVar('');
    userflow.reset();
  }
});

// Log screen views for Google Analytics.
globalHistory.listen(({ location }) => {
  const viewEvent: any = 'screen_view';
  analytics.setCurrentScreen(location.pathname);
  analytics.logEvent(viewEvent);
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={HekatonClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
