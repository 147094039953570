import Data from './GeoJSON.json';
import { GeoJsonData } from '../types';

const COUNTRY_GEOMETRY_MAP = new Map();

(Data as GeoJsonData).data.forEach(({ geometry, name }) => {
  COUNTRY_GEOMETRY_MAP.set(name, geometry);
});

export { COUNTRY_GEOMETRY_MAP };
