import React from 'react';
import firebase from 'firebase';
import { Form, Input, Button, message } from 'antd';
import { Redirect, RouteComponentProps, navigate } from '@reach/router';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar, lastVisitedPageVar } from '../../services/cache';
import { events, rudderIdentify, rudderTrack } from '../../utils/rudderUtils';
import AuthenticationPage from './AuthenticationPage';

const LogInPage = (props: RouteComponentProps) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const lastVisitedPage = useReactiveVar(lastVisitedPageVar);

  if (
    isLoggedIn &&
    (lastVisitedPage.includes('login') || lastVisitedPage.length === 0)
  ) {
    return <Redirect from="" to={'/platform/aperture'} noThrow />;
  } else if (isLoggedIn) {
    return <Redirect from="" to={lastVisitedPage} noThrow />;
  }

  const validateMessages = {
    required: 'This field is required.',
    types: {
      email: 'Please enter a valid email address.',
    },
  };

  const handleLogin = async (values: any) => {
    const { email, password } = values;

    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const uid = userCredential.user?.uid ? userCredential.user?.uid : '';

      rudderIdentify(uid);
      rudderTrack(events.signIn, { uid });
      navigate('/platform/aperture');
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleLoginFail = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const handleResetPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <AuthenticationPage
      title="Welcome back"
      description="Login to access your Aperture account"
      linkText="Don't have an account? Sign up."
      linkTo="/signup"
    >
      <Form
        name="basic"
        initialValues={{}}
        onFinish={handleLogin}
        onFinishFailed={handleLoginFail}
        layout="vertical"
        requiredMark={false}
        validateMessages={validateMessages}
      >
        <Form.Item
          label={<p className="text-gray-700 tracking-normal">Email</p>}
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input placeholder="jane@doe.org" />
        </Form.Item>

        <Form.Item
          label={<p className="text-gray-700 tracking-normal">Password</p>}
          name="password"
          rules={[{ required: true, min: 10 }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>

        <Form.Item className="mt-8">
          <Button
            htmlType="submit"
            type="primary"
            className="rounded w-full pt-2 pb-8 font-semibold"
            size="middle"
          >
            Sign In
          </Button>
          <Button
            className="mt-2 m-0 p-0"
            type="link"
            onClick={handleResetPassword}
          >
            I forgot my password.
          </Button>
        </Form.Item>
      </Form>
    </AuthenticationPage>
  );
};

export default LogInPage;
