import React from 'react';

interface AddFilterModalComponentProps {
  hide: () => void;
}

const AddFilterModal: React.FC<AddFilterModalComponentProps> = (props) => {
  const { children } = props;
  return (
    <div
      className="mt-6 border shadow border-gray-300 rounded px-4 pt-3 pb-5 fixed bg-white"
      style={{ width: 410, zIndex: 400 }}
    >
      {children}
    </div>
  );
};

export default AddFilterModal;
