import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Layout, PageHeader } from 'antd';
import SettingsMenu from './SettingsMenu';
import SettingsContent from './SettingsContent';
import { Breakpoint } from 'react-socks';

const { Sider, Content } = Layout;

const SettingsPage = (props: RouteComponentProps) => {
  const PATH = props.location?.pathname || '';
  document.title = 'Aperture | Settings';

  return (
    <Layout>
      <PageHeader title="Settings" className="bg-white shadow" />

      <Breakpoint medium down>
        <div className="p-3">
          <SettingsMenu path={PATH} />

          <div className="mt-3">
            <SettingsContent />
          </div>
        </div>
      </Breakpoint>

      <Breakpoint large up>
        <Layout className="mt-5">
          <Sider width={400} className="bg-grey px-6">
            <SettingsMenu path={PATH} />
          </Sider>

          <Content className="pr-6">
            <SettingsContent />
          </Content>
        </Layout>
      </Breakpoint>
    </Layout>
  );
};

export default SettingsPage;
