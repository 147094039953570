import { Region } from '../../../../types';

const getDefaultRegionId = (regions: Region[], currRegionId: string | null) => {
  const regionIds = new Set(regions.map(({ id }) => id));

  if (currRegionId && regionIds.has(currRegionId)) {
    return currRegionId;
  }

  return regions[0].id;
};

export { getDefaultRegionId };
