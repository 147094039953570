export const HOME = '/';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const FORGOT_PASSWORD = '/forgot-password';
export const USER_DASHBOARD = '/user';
export const USER_PROFILE = 'profile';
export const USER_SECURITY = 'security';

export const PLATFORM = 'platform';
export const DASHBOARD = 'dashboard';
