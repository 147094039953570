import React from 'react';
import { MapboxGeoJSONFeature, MapMouseEvent } from 'mapbox-gl';
import { events, rudderTrack } from './rudderUtils';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';
import { Table } from 'antd';
import { currLayerFamilyVar, modernFilterLayersVar } from '../services/cache';
import { DeepLayerFamily } from '../types';

interface PopupEnv {
  productId: string;
  productFamilyId: string;
  layerName: any;
  layerFamilyName: any;
  map: any;
  resolution: string;
}

/**
 * Returns a boolean whether or not the feature is from a filter layer.
 *
 * @param feature
 */
const isFilterFeature = (feature: MapboxGeoJSONFeature) => {
  const {
    layer: { id },
  } = feature;

  return id.includes('filter');
};

export const getProductPopupLabel = (val: number) => {
  const layerFamily: DeepLayerFamily | null = currLayerFamilyVar();
  const { category_labels, unit } = layerFamily!;

  if (category_labels && val < category_labels.length) {
    return `${category_labels[val]}`;
  }

  return `${val.toLocaleString()} ${unit}`;
};

interface ProductPopupContentComponentProps {
  name: string;
  dataSource: any[];
}

const ProductPopupContent = (props: ProductPopupContentComponentProps) => {
  const { name, dataSource } = props;
  const columns = [
    {
      title: 'Layer Family Name',
      dataIndex: 'layerFamilyName',
      key: 'layerFamilyName',
      render: (text: string) => (
        <span className="text-xs text-gray-800 font-semibold">{text}</span>
      ),
    },
    {
      title: 'label',
      dataIndex: 'label',
      key: 'label',
      render: (text: string) => (
        <span className="text-xs text-gray-800">{text}</span>
      ),
    },
  ];

  return (
    <div className="mt-1">
      {name ? (
        <h1 className="text-sm font-semibold text-black mb-2 tracking-wide">
          {name}
        </h1>
      ) : (
        <span className={'mt-3'} />
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        showHeader={false}
        size={'small'}
        pagination={false}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100'
        }
      />
    </div>
  );
};

const showFilterPopup = (
  env: PopupEnv,
  evt: MapMouseEvent,
  feature: mapboxgl.MapboxGeoJSONFeature
) => {
  const featureProps = feature.properties;

  if (!featureProps) {
    return;
  }
  const { map } = env;
  const name = featureProps && 'name' in featureProps ? featureProps.name : '';
  const dataSource: any[] = [];
  const modernLayers = modernFilterLayersVar();
  const modernLayersMap = new Map();

  modernLayers.forEach((layerAndFamily) => {
    return modernLayersMap.set(layerAndFamily.layer.product_id, layerAndFamily);
  });

  Object.keys(featureProps).forEach((key) => {
    if (modernLayersMap.has(key)) {
      const { layerFamily } = modernLayersMap.get(key);

      dataSource.push({
        key,
        layerFamilyName: layerFamily.name,
        label: getProductPopupLabel(featureProps[key]),
      });
    }
  });

  new mapboxgl.Popup()
    .setLngLat(evt.lngLat)
    .setHTML(
      ReactDOMServer.renderToString(
        <ProductPopupContent name={name} dataSource={dataSource} />
      )
    )
    .addTo(map);
};

const showProductPopup = (
  env: PopupEnv,
  evt: MapMouseEvent,
  feature: mapboxgl.MapboxGeoJSONFeature
) => {
  const featureProps = feature.properties;

  if (!featureProps) {
    return;
  }
  const { productId, layerFamilyName, map } = env;
  const name = featureProps && 'name' in featureProps ? featureProps.name : '';
  const val = productId in featureProps ? featureProps[productId] : -1;

  const label: string = getProductPopupLabel(val);
  const dataSource = [
    {
      key: '1',
      layerFamilyName,
      label,
    },
  ];

  new mapboxgl.Popup()
    .setLngLat(evt.lngLat)
    .setHTML(
      ReactDOMServer.renderToString(
        <ProductPopupContent name={name} dataSource={dataSource} />
      )
    )
    .addTo(map);
};

const showPopup = (
  env: PopupEnv,
  evt: MapMouseEvent,
  feature: mapboxgl.MapboxGeoJSONFeature
) => {
  const { productFamilyId } = env;

  const featureProps = feature.properties;
  const name = featureProps && 'name' in featureProps ? featureProps.name : '';

  rudderTrack(events.valueSelected, {
    name,
    productFamilyId,
  });

  if (productFamilyId.includes('filter')) {
    showFilterPopup(env, evt, feature);
  } else {
    showProductPopup(env, evt, feature);
  }
};

const getFeatureName = (feature: MapboxGeoJSONFeature) => {
  return feature!.properties!.name;
};

export { isFilterFeature, showPopup, getFeatureName };
