import React, { useState } from 'react';
import { Layout } from 'antd';
import MainMenu from '../MainMenu/MainMenu';
import LayerFamilyMenu from '../LayerFamilyMenu/LayerFamilyMenu';
import FilterMenuContainer from '../FilterMenu/FilterMenuContainer';
import { DeepLayerFamily } from '../../../../types';
import { currLayerFamilyVar, currRegionVar } from '../../../../services/cache';
import BackHeader from './BackHeader';
import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

const { Sider } = Layout;

interface InsightsSiderProps {
  accessibleFamilyMap: Map<string, DeepLayerFamily>;
  productFamilyMap: Map<string, DeepLayerFamily>;
  filterLayerFamily: DeepLayerFamily | null;
}

const InsightsSider = (props: InsightsSiderProps) => {
  const { productFamilyMap, filterLayerFamily, accessibleFamilyMap } = props;
  const currLayerFamily = useReactiveVar(currLayerFamilyVar);
  const currRegion = useReactiveVar(currRegionVar);

  const [page, setPage] = useState('main');
  const [backHeaderVisible, setBackHeaderVisible] = useState(false);
  const [mainMenuVisible, setMainMenuVisible] = useState(false);
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [layerFamilyMenuVisible, setLayerFamilyMenuVisible] = useState(false);

  const goToMainMenu = () => {
    setPage('main');
  };

  const goToFilterMenu = () => {
    setPage('filter');
  };

  const goToLayerFamilyMenu = () => {
    setPage('layer-family');
  };

  useEffect(() => {
    goToMainMenu();
  }, [currRegion]);

  useEffect(() => {
    if (page !== 'main') {
      setBackHeaderVisible(true);
    } else {
      setBackHeaderVisible(false);
    }
  }, [page]);

  useEffect(() => {
    if (
      filterLayerFamily !== null &&
      productFamilyMap.size > 0 &&
      currLayerFamily !== null &&
      currLayerFamily.human_id === 'filter' &&
      page === 'filter'
    ) {
      setMainMenuVisible(false);
      setLayerFamilyMenuVisible(false);
      setFilterMenuVisible(true);
    } else if (
      productFamilyMap.size > 0 &&
      currLayerFamily !== null &&
      page === 'layer-family'
    ) {
      setMainMenuVisible(false);
      setLayerFamilyMenuVisible(true);
      setFilterMenuVisible(false);
    } else {
      setMainMenuVisible(true);
      setLayerFamilyMenuVisible(false);
      setFilterMenuVisible(false);
    }
  }, [filterLayerFamily, productFamilyMap, page, currLayerFamily]);

  return (
    <Sider width={288} className="bg-grey max-h-screen overflow-auto">
      {backHeaderVisible ? <BackHeader onBack={goToMainMenu} /> : null}

      {mainMenuVisible ? (
        <MainMenu
          accessibleFamilyMap={accessibleFamilyMap}
          filterLayerFamily={filterLayerFamily}
          productFamilyMap={productFamilyMap}
          onFilter={goToFilterMenu}
          onLayerFamily={goToLayerFamilyMenu}
        />
      ) : null}

      {filterMenuVisible ? (
        <FilterMenuContainer
          filterLayerFamily={filterLayerFamily!}
          productFamilyMap={productFamilyMap}
        />
      ) : null}

      {layerFamilyMenuVisible ? (
        <LayerFamilyMenu selectedFamily={currLayerFamily!} />
      ) : null}
    </Sider>
  );
};

export default InsightsSider;
