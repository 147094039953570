import React from 'react';
import { Table } from 'antd';
import { formatDataSource } from './popupUtils';
import { LabelDatum } from '../../../../types';

interface ProductPopupContentComponentProps {
  name: string;
  dataSource: LabelDatum[];
}

const ProductPopupContent = (props: ProductPopupContentComponentProps) => {
  const { name, dataSource } = props;
  const formattedDataSource = formatDataSource(dataSource);

  const columns = [
    {
      title: 'Layer Family Name',
      dataIndex: 'layerFamilyName',
      key: 'layerFamilyName',
      render: (text: string) => (
        <span className="text-xs text-gray-800 font-semibold">{text}</span>
      ),
    },
    {
      title: 'label',
      dataIndex: 'label',
      key: 'label',
      render: (text: string) => (
        <span className="text-xs text-gray-800">{text}</span>
      ),
    },
  ];

  return (
    <div className="mt-1">
      {name ? (
        <h1 className="text-sm font-semibold text-black mb-2 tracking-wide">
          {name}
        </h1>
      ) : (
        <span className={'mt-3'} />
      )}
      <Table
        columns={columns}
        dataSource={formattedDataSource}
        scroll={{ y: 300 }}
        bordered
        showHeader={false}
        size={'small'}
        pagination={false}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100'
        }
      />
    </div>
  );
};

export default ProductPopupContent;
