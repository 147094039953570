import React, { useEffect, useState } from 'react';
import { Distribution, Filter, FilterAttribute } from '../../../../../types';
import { Button, Divider, Slider, Input, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import numeral from 'numeral';
import { sortBy, reduce, head, last } from 'lodash';
import { GET_DISTRIBUTIONS } from '../../../../../services/fetches/statsQueries';

interface NumberRangeFilterProps {
  applyFilter: any;
  attribute: FilterAttribute;
  handleBack: any;
  hide: any;
}

const numberWithCommas = (num: number) => {
  const numStr = (Math.round(num * 100) / 100).toString();
  const [pre, post] = numStr.split('.');
  const formattedPre = pre.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (post) {
    return `${formattedPre}.${post}`;
  }

  return formattedPre;
};

const sortDistributions = (
  firstDist: Distribution,
  secondDist: Distribution
) => {
  if (firstDist.bucketStart < secondDist.bucketStart) {
    return -1;
  }
  if (firstDist.bucketStart > secondDist.bucketStart) {
    return 1;
  }
  return 0;
};

const NumberRangeFilterPrompt = (props: NumberRangeFilterProps) => {
  const { applyFilter, attribute, handleBack, hide } = props;
  const { layerFamily } = attribute;
  const { product_id } = layerFamily.layers[0];

  const defaultFilterRange: any = {};
  const defaultMarks: any = {};

  const [filterRange, setFilterRange] = useState(defaultFilterRange);
  const [min, setMin] = useState(attribute.filterInfo.min);
  const [max, setMax] = useState(attribute.filterInfo.max);
  const [marks, setMarks] = useState(defaultMarks);

  const { data, loading, error } = useQuery(GET_DISTRIBUTIONS, {
    variables: { productInstanceId: product_id },
  });

  useEffect(() => {
    if (data) {
      const dist: Distribution[] = [...data.distributions].sort(
        sortDistributions
      );
      const range = [...dist.map(distRange), { value: dist[9].bucketEnd }];

      const configuredFilterRange = configureRange(range);

      setMin(configuredFilterRange.minValue);
      setMax(configuredFilterRange.maxValue);

      setFilterRange(configuredFilterRange);
      setMarks({
        [configuredFilterRange.getStepForValue(dist[0].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[1].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[2].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[3].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[4].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[5].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[6].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[7].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[8].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[9].bucketStart)]: '',
        [configuredFilterRange.getStepForValue(dist[9].bucketEnd)]: '',
      });
    }
  }, [data]);

  if (error) return <div>Error</div>;

  const distRange = (x: any, i: number) => {
    const step = (x.bucketEnd - x.bucketStart) / 1000;

    return {
      value: x.bucketStart,
      step,
    };
  };

  function configureRange(range: any) {
    // sort by ascending value.
    const sorted = sortBy(range, (b) => b.value);
    // calculates stepsSoFar on each value breakpoint
    // for easier value/step calulations later.

    const reducer = (result: any, curr: any, key: any) => {
      // to calculate stepsSoFar we must get the
      // range of values from the previous breakpoint
      // to our current breakpoint.
      const prev = sorted[key - 1];
      if (prev) {
        // calculate number of steps to reach the
        // breakpoint value.
        curr.stepsSoFar =
          (curr.value - prev.value) / prev.step + prev.stepsSoFar;
      } else {
        // the first breakpoint represents min value
        // so stepsSoFar will always be 0.
        curr.stepsSoFar = 0;
      }
      // final breakpoint represents max value
      // so user isn't forced to set a step
      // even though we need it here.
      if (typeof curr.step === typeof undefined) {
        curr.step = 1;
      }

      result.push(curr);
      return result;
    };

    const breakpoints: any[] = reduce(sorted, reducer, []);

    // min and max for easier calculations later
    const minStep = head(breakpoints).stepsSoFar;
    const maxStep = last(breakpoints).stepsSoFar;
    const minValue = head(breakpoints).value;
    const maxValue = last(breakpoints).value;

    // return value within min and max value range
    const ensureValue = (value: any) => {
      if (value > maxValue) {
        return maxValue;
      } else if (value < minValue) {
        return minValue;
      } else {
        return value;
      }
    };

    // calculates value for current steps
    const getValueForStep = (step: any) => {
      // find the nearest breakpoint behind current step
      const nearest = reduce(
        breakpoints,
        (prev, curr) =>
          curr.stepsSoFar < step && curr.stepsSoFar > prev.stepsSoFar
            ? curr
            : prev,
        head(breakpoints)
      );
      // determine value past nearest breakpoint value
      const additionalValue = (step - nearest.stepsSoFar) * nearest.step;
      return nearest.value + additionalValue;
    };

    // calculates number of steps for current value
    const getStepForValue = (value: any) => {
      // find the nearest breakpoint behind current value
      const nearest = reduce(
        breakpoints,
        (prev, curr) =>
          curr.value < value && curr.value > prev.value ? curr : prev,
        head(breakpoints)
      );
      // determine number of steps past nearest breakpoint steps so far
      const additionalSteps = (value - nearest.value) / nearest.step;
      return nearest.stepsSoFar + additionalSteps;
    };

    return {
      minStep,
      maxStep,
      minValue,
      maxValue,
      ensureValue,
      getValueForStep,
      getStepForValue,
    };
  }

  const handleReset = () => {
    setMin(filterRange.minValue);
    setMax(filterRange.maxValue);
  };

  const handleApplyFilter = () => {
    const newFilter: Filter = {
      id: 0,
      label: `${attribute.name} is between ${numberWithCommas(
        min
      )} and ${numberWithCommas(max)}`,
      filterType: '',
      filterInfo: {
        min,
        max,
        productInstanceId: product_id,
      },
      expressions: [
        ['>=', ['to-number', ['get', 'value']], min],
        ['<=', ['to-number', ['get', 'value']], max],
      ],
    };

    applyFilter(newFilter);
    hide();
  };

  return (
    <Spin spinning={loading}>
      <div className="flex items-center mb-2">
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
          className="mr-2"
        />
        <span className="font-bold text-lg pt-2">{attribute.name}</span>
      </div>

      <div className="mt-8">
        {filterRange.getValueForStep ? (
          <Slider
            range={true}
            min={filterRange.minStep}
            max={filterRange.maxStep}
            defaultValue={[filterRange.minStep, filterRange.maxStep]}
            marks={marks}
            tipFormatter={(value) => {
              const formattedPopulation = numeral(
                filterRange.getValueForStep(value)
              ).format('0.0a');
              return formattedPopulation;
            }}
            onChange={(values) => {
              const [currMin, currMax] = values;
              setMin(filterRange.getValueForStep(currMin));
              setMax(filterRange.getValueForStep(currMax));
            }}
            value={[
              filterRange.getStepForValue(min),
              filterRange.getStepForValue(max),
            ]}
          />
        ) : null}
      </div>

      <div className="flex justify-between mt-6">
        <Input disabled style={{ width: 150 }} value={numberWithCommas(min)} />
        <Input disabled style={{ width: 150 }} value={numberWithCommas(max)} />
      </div>

      <Divider />

      <div className="flex justify-between">
        <Button onClick={handleReset} type="link">
          Reset
        </Button>
        <Button onClick={handleApplyFilter} type="primary">
          Apply Filter
        </Button>
      </div>
    </Spin>
  );
};

export default NumberRangeFilterPrompt;
