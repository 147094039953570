import React from 'react';
import { formatTimePeriod } from '../../../Legend/LegendUtils';

interface DateCellComponentProps {
  dateString: string;
}

const DateCell = (props: DateCellComponentProps) => {
  const { dateString } = props;
  return <div>{formatTimePeriod(dateString)}</div>;
};

export default DateCell;
