import React from 'react';
import { KeyVal } from '../../../../types';
import { Select } from 'antd';

const { Option } = Select;

interface SelectGeolevelSectionComponentProps {
  geolevelOptions: KeyVal[];
  currGeolevelId: string;
  handleGeolevelSelect: (geolevelId: string) => void;
  disabled: boolean;
}

const SelectGeolevelSection = (props: SelectGeolevelSectionComponentProps) => {
  const { geolevelOptions, currGeolevelId, handleGeolevelSelect, disabled } =
    props;
  return (
    <div className="mb-4">
      <h3 className="text-xs mt-8 font-semibold text-gray-700 mb-2">
        FILTER RESOLUTION
      </h3>
      <Select
        disabled={geolevelOptions.length <= 0 || disabled}
        style={{ width: '100%' }}
        value={currGeolevelId}
        onSelect={handleGeolevelSelect}
      >
        {geolevelOptions.map(({ label, value }) => {
          return (
            <Option value={value} key={value}>
              {label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectGeolevelSection;
