import { RouteComponentProps } from '@reach/router';
import React from 'react';
import DesktopInsightsPage from './DesktopInsightsPage';
import { Breakpoint } from 'react-socks';
import MobileInsightsPage from './MobileInsightsPage';

const InsightsPage = (props: RouteComponentProps) => {
  return (
    <>
      <Breakpoint small down>
        <MobileInsightsPage />
      </Breakpoint>

      <Breakpoint medium up>
        <DesktopInsightsPage path={props.path} />
      </Breakpoint>
    </>
  );
};

export default InsightsPage;
