import { navigate, RouteComponentProps } from '@reach/router';
import { EnvironmentTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import AuthenticationPage from './AuthenticationPage';

const HomePage = (props: RouteComponentProps) => {
  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
    <AuthenticationPage title="" description="" linkTo="" linkText="">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Aperture™</h1>
        <h2 className="text-">
          Market & economic intelligence across Africa by Atlas AI
        </h2>
      </div>

      <div>
        <p className="mb-5 text-sm">
          📊 Evaluate economic, demographic and infrastructure statistics for
          locations of interest across the continent.
        </p>
        <p className="mb-5 text-sm">
          🔍 Access proprietary data on household-level wealth & consumption
          down to a settlement-level
        </p>
        <p className="mb-5 text-sm">
          🌐 Overlay multiple data layers to filter and prioritize local markets
        </p>
      </div>

      <div>
        <p className="mb-3">Sign up for a free account to get started:</p>
        <Button
          className="rounded border-blue-500 text-blue-500 mr-3 transition-all duration-300 hover:text-white hover:bg-blue-400"
          onClick={handleSignUpClick}
        >
          Create Account
        </Button>
        <Button className="rounded" type="primary" onClick={handleLoginClick}>
          Log In
        </Button>
      </div>
    </AuthenticationPage>
  );
};

export default HomePage;
