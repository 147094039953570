import React from 'react';
import { LayerFamily } from '../../../../types';
import { Tooltip } from 'antd';
import PremiumTooltipContent from '../../../shared/PremiumTooltipContent';
import { LockOutlined, RightOutlined } from '@ant-design/icons';

interface LockedFamilyButtonComponentProps {
  isLast: boolean;
  layerFamily: LayerFamily;
}

const LockedFamilyButton = (props: LockedFamilyButtonComponentProps) => {
  const { isLast, layerFamily } = props;

  return (
    <Tooltip
      title={<PremiumTooltipContent />}
      color={'#f6f8ff'}
      placement="rightBottom"
      overlayInnerStyle={{ width: 320 }}
    >
      <div
        className={`flex border justify-between rounded mt-3 hover:bg-blue-100 hover:text-blue-600 transition-all duration-300 cursor-pointer  ${
          isLast ? '' : 'mb-3'
        } `}
      >
        <div className={'p-3 flex-grow flex'}>
          <div className="p-1  pr-3 cursor-pointer transition-all duration-300 rounded">
            <LockOutlined style={{ top: '-3px' }} className="relative" />
          </div>
          <h3 className="mt-1">{layerFamily.name}</h3>
        </div>
        <div className="py-2 px-4">
          <div className="p-2 cursor-pointer transition-all duration-300 rounded">
            <RightOutlined className={'relative'} style={{ top: -2 }} />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default LockedFamilyButton;
