import React from 'react';
import { Filter } from '../../../../types';
import AppliedFilterListItem from './AppliedFilterListItem';

interface AppliedFiltersListProps {
  filters: Map<number, Filter>;
  deleteFilter: any;
}

const AppliedFiltersList = (props: AppliedFiltersListProps) => {
  const { filters, deleteFilter } = props;
  const filterElementList: JSX.Element[] = [];

  filters.forEach((filter, id) => {
    filterElementList.push(
      <AppliedFilterListItem
        key={id}
        filter={filter}
        deleteFilter={deleteFilter.bind(this, id)}
      />
    );
  });

  return (
    <>
      <h3 className="font-bold text-gray-700 uppercase text-xs">
        Applied Filters
      </h3>
      <div className="mt-2">{filterElementList}</div>
    </>
  );
};

export default AppliedFiltersList;
