import { FlagOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

const ReportBugButton = () => {
  const handleClick = () => {
    window.open(
      'https://form.asana.com?k=CxQB6br-NKTI7ZSoyu-w9Q&d=1110467265121862',
      '_blank'
    );
  };

  return (
    <Button
      className="mt-4 mb-6"
      icon={<FlagOutlined />}
      type="link"
      onClick={handleClick}
    >
      Report a bug/request a feature
    </Button>
  );
};

export default ReportBugButton;
