import React from 'react';
import CountryTable from './CountryTable';
import StepTitle from './StepTitle';

interface RegionStepContentComponentProps {
  selectedCountries: number[];
  selectCountries: (countries: number[]) => void;
}

const RegionStepContent = (props: RegionStepContentComponentProps) => {
  const { selectCountries, selectedCountries } = props;
  return (
    <div>
      <StepTitle title="What countries are you interested in?" />
      <CountryTable
        selectedCountries={selectedCountries}
        selectCountries={selectCountries}
      />
    </div>
  );
};

export default RegionStepContent;
