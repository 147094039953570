import { gql } from '@apollo/client';

const GET_JOBS = gql`
  query GetJobs {
    getJobs {
      jobId
      jobName
      dataUri
      timeRequested
      jobStatus
    }
  }
`;

export { GET_JOBS };
