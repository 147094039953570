import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import LandingPage from '../landing/LandingPage';
import PlatformPage from '../platform/PlatformPage';
import { PLATFORM } from '../../constants/routes';
import { initializeRudder } from '../../utils/rudderUtils';
import { BreakpointProvider } from 'react-socks';

const App = () => {
  useEffect(() => {
    initializeRudder();
  }, []);

  return (
    <BreakpointProvider>
      <Router>
        <LandingPage path="/*" />
        <PlatformPage path={`/${PLATFORM}/*`} />
      </Router>
    </BreakpointProvider>
  );
};

export default App;
