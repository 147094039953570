import {
  CircleLayer,
  CirclePaint,
  Expression,
  FillLayer,
  FillPaint,
} from 'mapbox-gl';
import { GET_LEGEND_BINS } from '../../../../services/fetches/statsQueries';
import { colorRamps } from '../../../../constants/colorRamps';
import { StyleLayerType } from '../../../../types';
import { RESOLUTION_MAP } from '../../../../constants/resolutions';
import HekatonClient from '../../../../services/clients/HekatonClient';

const FILL_OUTLINE_COLOR = '#242424';
const STROKE_COLOR = '#242424';
const CIRCLE_STROKE_WIDTH = {
  base: 0,
  stops: [
    [8, 0],
    [16, 0.1],
  ],
};

const CIRCLE_RADIUS_STOPS = (() => {
  const stops: [number, number][] = [];
  let multiplier = 1.5;
  for (let idx = 7; idx < 23; idx += 1) {
    multiplier = multiplier + (idx - 7) * 1.12375;
    stops.push([idx, 1.8 * multiplier]);
  }

  return stops;
})();

const CIRCLE_RADIUS = {
  base: 3.5,
  stops: CIRCLE_RADIUS_STOPS,
};

const OPACITY = 1;

const getCategoricalColorRampForProductInstance = (
  humanId: string,
  categoryCount: number
) => {
  const colorRamp = colorRamps.get(humanId)!;

  if (categoryCount === 2) return [colorRamp[2], colorRamp[7], '#000000'];

  if (categoryCount === 3) return [colorRamp[1], colorRamp[4], colorRamp[7]];

  if (categoryCount === 4)
    return [colorRamp[0], colorRamp[2], colorRamp[6], colorRamp[8]];

  if (categoryCount === 5)
    return [
      colorRamp[0],
      colorRamp[2],
      colorRamp[4],
      colorRamp[6],
      colorRamp[8],
    ];

  if (categoryCount === 6)
    return [
      colorRamp[1],
      colorRamp[2],
      colorRamp[3],
      colorRamp[5],
      colorRamp[6],
      colorRamp[7],
    ];

  if (categoryCount === 7)
    return [
      colorRamp[0],
      colorRamp[2],
      colorRamp[3],
      colorRamp[4],
      colorRamp[5],
      colorRamp[6],
      colorRamp[8],
    ];

  if (categoryCount === 8)
    return [
      colorRamp[0],
      colorRamp[2],
      colorRamp[3],
      colorRamp[4],
      colorRamp[5],
      colorRamp[6],
      colorRamp[7],
      colorRamp[8],
    ];

  return colorRamp;
};

const getColorRampForProductInstance = (
  humanId: string,
  filterType = '',
  categoryCount = 0
) => {
  const colorRamp = colorRamps.has(humanId)
    ? colorRamps.get(humanId)!
    : colorRamps.get('default')!;

  if (filterType === 'categorical') {
    return getCategoricalColorRampForProductInstance(humanId, categoryCount);
  }

  return [
    colorRamp[1],
    colorRamp[2],
    colorRamp[3],
    colorRamp[4],
    colorRamp[5],
    colorRamp[6],
    colorRamp[7],
  ];
};

const getCategoricalFilterExpression = (
  colorRamp: string[],
  productInstanceId: string,
  categoryCount: number
): Expression => {
  const colorSteps: (string | number)[] = [];

  for (let idx = 0; idx < categoryCount; idx += 1) {
    colorSteps.push(idx + 1);
    colorSteps.push(colorRamp[idx]);
  }

  return ['match', ['get', 'value'], ...colorSteps, '#000000'];
};

const getLegendBins = async (productInstanceId: string): Promise<number[]> => {
  try {
    const results = await HekatonClient.query({
      query: GET_LEGEND_BINS,
      variables: { productInstanceId },
    });

    return results.data.legendBins.map((bin: any): number => {
      return bin.max;
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getContinuousFilterExpression = async (
  colorRamp: string[],
  productInstanceId: string
): Promise<Expression> => {
  const legendBins = await getLegendBins(productInstanceId);
  const uniqueBins = legendBins.filter((binVal, idx, bins) => {
    return bins.indexOf(binVal) === idx;
  });

  const colorSteps: (string | number)[] = [];

  uniqueBins.forEach((binVal, idx, bins) => {
    colorSteps.push(colorRamp[idx]);

    if (bins.length !== idx + 1) {
      colorSteps.push(binVal);
    }
  });

  return ['step', ['to-number', ['get', 'value']], ...colorSteps];
};

const getColorExpression = async (
  productFamilyHumanId: string,
  variableType: string,
  productInstanceId: string,
  categoryCount: number
): Promise<Expression> => {
  let colorRamp: string[];

  if (variableType === 'continuous') {
    colorRamp = getColorRampForProductInstance(
      productFamilyHumanId,
      'continuous',
      0
    );
    return await getContinuousFilterExpression(colorRamp, productInstanceId);
  }

  colorRamp = getColorRampForProductInstance(
    productFamilyHumanId,
    'categorical',
    categoryCount
  );
  return await getCategoricalFilterExpression(
    colorRamp,
    productInstanceId,
    categoryCount
  );
};

const getFillPaint = async (
  productFamilyHumanId: string,
  variableType: string,
  productInstanceId: string,
  categoryCount: number,
  layerType: StyleLayerType = StyleLayerType.Product,
  visible = true
): Promise<FillPaint> => {
  if (
    layerType === StyleLayerType.Filter ||
    (layerType === StyleLayerType.Product && !visible)
  ) {
    return {
      'fill-color': 'hsl(221, 82%, 58%)',
      'fill-outline-color': 'hsl(231, 89%, 40%)',
      'fill-opacity': visible ? OPACITY : 0,
    };
  }

  if (layerType === StyleLayerType.Selection) {
    return {
      'fill-outline-color': '#826343',
      'fill-color': '#dbc87a',
      'fill-opacity': visible ? OPACITY : 0,
    };
  }

  return {
    'fill-antialias': true,
    'fill-color': await getColorExpression(
      productFamilyHumanId,
      variableType,
      productInstanceId,
      categoryCount
    ),
    'fill-opacity': OPACITY,
    'fill-outline-color': FILL_OUTLINE_COLOR,
  };
};

const getCirclePaint = async (
  productFamilyHumanId: string,
  variableType: string,
  productInstanceId: string,
  categoryCount: number,
  layerType: StyleLayerType
): Promise<CirclePaint> => {
  if (
    colorRamps.has(productFamilyHumanId) &&
    layerType === StyleLayerType.Product
  ) {
    //&& variableType !== 'continuous') {
    return {
      'circle-color': await getColorExpression(
        productFamilyHumanId,
        variableType,
        productInstanceId,
        categoryCount
      ),
      'circle-opacity': OPACITY,
      'circle-radius': CIRCLE_RADIUS,
      'circle-stroke-color': STROKE_COLOR,
      'circle-stroke-opacity': 1,
      'circle-stroke-width': CIRCLE_STROKE_WIDTH,
    };
  }
  return {
    'circle-color': '#dbc87a',
    'circle-opacity': OPACITY,
    'circle-radius': CIRCLE_RADIUS,
    'circle-stroke-color': STROKE_COLOR,
    'circle-stroke-opacity': 1,
    'circle-stroke-width': CIRCLE_STROKE_WIDTH,
  };
};

const getStyleLayerId = (
  layerType: StyleLayerType,
  productInstanceId: string
) => {
  if (layerType === StyleLayerType.Product) {
    return productInstanceId;
  } else if (layerType === StyleLayerType.Filter) {
    return 'filter';
  }
  return 'selection';
};

const getStyleLayer = async (
  productInstanceId: string,
  resolution: string,
  productFamilyHumanId: string,
  variableType: string,
  categoryCount: number,
  layerType: StyleLayerType = StyleLayerType.Product,
  visible: boolean
): Promise<FillLayer | CircleLayer> => {
  let circleLayer: CircleLayer;
  if (resolution && resolution.toLowerCase() === 'point') {
    circleLayer = {
      id: getStyleLayerId(layerType, productInstanceId),
      type: 'circle',
      source: productInstanceId,
      'source-layer': productInstanceId,
      layout: {
        visibility: 'visible',
      },
      paint: await getCirclePaint(
        productFamilyHumanId,
        variableType,
        productInstanceId,
        categoryCount,
        layerType
      ),
      minzoom: RESOLUTION_MAP.get(resolution)!.minzoom,
    };

    return circleLayer;
  }

  const fillLayer: any = {
    id: getStyleLayerId(layerType, productInstanceId),
    type: 'fill',
    source: productInstanceId,
    'source-layer': productInstanceId,
    layout: {
      visibility: 'visible',
    },
    paint: await getFillPaint(
      productFamilyHumanId,
      variableType,
      productInstanceId,
      categoryCount,
      layerType,
      visible
    ),
    metadata: null,
    minzoom: RESOLUTION_MAP.get(resolution)!.minzoom,
    filter: ['has', 'value'],
  };

  return fillLayer;
};

export { getStyleLayer, getColorRampForProductInstance };
