import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { Layout } from 'antd';

import LogInPage from './LogInPage';
import SignUpPage from './SignUp/SignUpPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import Home from './HomePage';

const { Content } = Layout;

const LandingPage = (props: RouteComponentProps) => {
  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Content>
        <Router>
          <Home path="/" />
          <LogInPage path="login" />
          <SignUpPage path="signup" />
          <ForgotPasswordPage path="forgot-password" />
        </Router>
      </Content>
    </Layout>
  );
};

export default LandingPage;
