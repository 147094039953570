import {
  AtlasOperator,
  FilterInput,
  MapboxOperator,
  OutputFields,
  OutputFormat,
  ProductInstanceFilterInput,
  StartJobInput,
  DownloadTypes,
  Layer,
  DeepLayerFamily,
} from '../../../../types';
import { filtersVar, selectionGeometryVar } from '../../../../services/cache';
import { convertGeojsonToWKT } from './wktUtils';
import { startDownloadJob } from '../../../../services/fetches/downloads';
import { formatTimePeriod } from '../Legend/LegendUtils';
import { DataNode } from 'antd/lib/tree';
import { sortObjectAlphabetically } from '../../../../utils/utils';

const convertMbToAtlasOperator = (mbOperator: string): AtlasOperator => {
  if (mbOperator === MapboxOperator.Any) {
    return AtlasOperator.In;
  }

  if (mbOperator === MapboxOperator.GreaterThanOrEqualTo) {
    return AtlasOperator.GreaterThanOrEqualTo;
  }

  if (mbOperator === MapboxOperator.LessThanOrEqualTo) {
    return AtlasOperator.LessThanOrEqualTo;
  }

  return AtlasOperator.Equals;
};

const getProductInstanceFilters = (): ProductInstanceFilterInput[] => {
  const filters = filtersVar();
  const filtersByProductInstanceId = new Map<
    string,
    Map<AtlasOperator, number[]>
  >();
  const productInstanceFilters: ProductInstanceFilterInput[] = [];

  // Loop through each filter.
  filters.forEach(({ expressions, filterInfo: { productInstanceId } }, key) => {
    let filterExpressions = expressions;
    const isAny =
      expressions[0] && expressions[0][0] && expressions[0][0] === 'any';

    // Handle special case for 'any';
    if (isAny) {
      filterExpressions = expressions[0].slice(1, expressions[0].length);
    }

    // Loop through the expressions for the filter.
    filterExpressions.forEach((expression) => {
      const operator: AtlasOperator = convertMbToAtlasOperator(
        isAny ? 'any' : expression[0]
      );
      const expressionVal: number = expression[2];
      const instanceExists = filtersByProductInstanceId.has(productInstanceId);

      let operatorExists = false;
      let instanceValuesByOperator: Map<AtlasOperator, number[]> | undefined;

      if (instanceExists) {
        // Get the existing instanceValuesByOperator.
        instanceValuesByOperator =
          filtersByProductInstanceId.get(productInstanceId);

        // Check to see if this current operator exists.
        operatorExists = instanceValuesByOperator!.has(operator);
      }

      // Check to see if the product instance and the operator have been tracked.
      if (instanceExists && operatorExists) {
        // Add the value to the array.
        instanceValuesByOperator!.get(operator)!.push(expressionVal);
      }

      // Check to see if the product instance but not the operator has been tracked.
      if (instanceExists && !operatorExists) {
        // Create a new key with the operator and set it to an array of the expressionVal.
        instanceValuesByOperator!.set(operator, [expressionVal]);
      }

      // Check to see if the product instance has not been tracked.
      if (!instanceExists) {
        // Create a new instanceValByOperator map.
        instanceValuesByOperator = new Map();

        // Create a new operator key and set it to the array of the expression val.
        instanceValuesByOperator!.set(operator, [expressionVal]);

        // Create a new productInstance key and set it to the map.
        filtersByProductInstanceId!.set(
          productInstanceId,
          instanceValuesByOperator
        );
      }
    });
  });

  // Now to populate the productInstanceFilters with our map of map of arrays.
  filtersByProductInstanceId.forEach((valuesByOperator, productInstanceId) => {
    const filters: FilterInput[] = [];

    valuesByOperator.forEach((values, operator) => {
      filters.push({
        operator,
        values,
      });
    });

    productInstanceFilters.push({
      productInstanceId,
      filters,
    });
  });

  return productInstanceFilters;
};

const getGeographicFilter = (): string[] => {
  const selectionGeometry: any = selectionGeometryVar();
  if (selectionGeometry === null) {
    return [''];
  }

  return [convertGeojsonToWKT(selectionGeometry)];
};

const getStartJobInput = (
  jobName?: string,
  downloadMode: DownloadTypes = DownloadTypes.AllCSV,
  productInstanceIdsToDownload: string[] = [],
  geographicFilters?: string[] | null
): StartJobInput => {
  const productInstanceIds = productInstanceIdsToDownload;
  const productInstanceFilters = getProductInstanceFilters();
  const outputFields = [
    OutputFields.FeatureGeometry,
    OutputFields.FeatureId,
    OutputFields.GeographicLevel,
    OutputFields.Name,
  ];
  const outputFormat = OutputFormat.Csv;
  const startJobInput: StartJobInput = {
    productInstanceIds,
    jobName,
    outputFields,
    outputFormat,
  };

  if (
    downloadMode === DownloadTypes.SelectedCSV ||
    downloadMode === DownloadTypes.FilteredCSV
  ) {
    startJobInput.productInstanceFilters = productInstanceFilters;
  }

  if (
    downloadMode === DownloadTypes.SelectedCSV ||
    downloadMode === DownloadTypes.CountryCSV
  ) {
    startJobInput.geographicFilter = geographicFilters
      ? geographicFilters
      : getGeographicFilter();
  }

  return startJobInput;
};

const handleDownload = async (jobName: string) => {
  const startJobInput = getStartJobInput(jobName);
  await startDownloadJob(startJobInput);
  const dataStr =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(startJobInput));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', jobName + '.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

const getLayersTree = (layerFamilies: DeepLayerFamily[]) => {
  const layersTree: Map<string, Map<string, Set<Layer>>> = new Map();
  const layersArr: Layer[] = [];

  layerFamilies.forEach((layerFamily) => {
    const { name, layers } = layerFamily;
    const clonedLayers: Layer[] = JSON.parse(JSON.stringify(layers));

    clonedLayers.forEach((layer, idx) => {
      const layerWithFamilyName: Layer = { ...layer, familyName: name };
      clonedLayers[idx] = layerWithFamilyName;
    });

    layersArr.push(...clonedLayers);
  });

  layersArr.forEach((layer) => {
    const { resolution, familyName } = layer;

    if (!layersTree.has(resolution)) {
      layersTree.set(resolution, new Map());
    }

    if (!layersTree.get(resolution)?.has(familyName!)) {
      layersTree.get(resolution)?.set(familyName!, new Set());
    }

    layersTree.get(resolution)?.get(familyName!)?.add(layer);
  });

  return layersTree;
};

const getYearlyLayerNodes = (
  productFamilyName: string,
  layerSet: Set<Layer>
): DataNode[] => {
  const layersArr: Layer[] = Array.from(layerSet.values());
  const layerYearsMap: Map<string, Set<Layer>> = new Map();

  layersArr.forEach((layer) => {
    const { time_period } = layer;
    const [year, month] = time_period.split('_');

    if (!layerYearsMap.has(year)) {
      layerYearsMap.set(year, new Set());
    }

    layerYearsMap.get(year)?.add({ ...layer, time_period: month });
  });

  return getDataNodesFromLayerMap(layerYearsMap, productFamilyName);
};

const getDefaultLayerNodes = (
  productFamilyName: string,
  layerSet: Set<Layer>
): DataNode[] => {
  const layersArr: Layer[] = Array.from(layerSet.values());
  const layerNodes = layersArr.map(({ product_id, time_period }) => {
    const formattedTimePeriod = formatTimePeriod(time_period);
    return {
      title: `${productFamilyName} - ${formattedTimePeriod}`,
      value: product_id,
      key: product_id,
    };
  });
  return layerNodes;
};

const getLayerNodes = (productFamilyName: string, layerSet: Set<Layer>) => {
  const sampleLayer: Layer = layerSet.values().next().value;
  const { time_period } = sampleLayer;

  if (time_period.includes('_') && !time_period.includes('-')) {
    return getYearlyLayerNodes(productFamilyName, layerSet);
  }

  return getDefaultLayerNodes(productFamilyName, layerSet);
};

const getDataNodesFromLayerMap = (
  map: Map<string, Set<Layer>>,
  prefix: string
) => {
  const dataNodes: DataNode[] = [];

  map?.forEach((layersSet: Set<Layer>, key: string) => {
    const sortedLayers = Array.from(layersSet.values());
    sortedLayers.sort(sortObjectAlphabetically.bind(this, 'time_period'));
    const sortedSet = new Set(sortedLayers);

    if (key.toLowerCase().includes('filter')) {
      return;
    }

    const label = `${prefix}${prefix.length > 0 ? ' - ' : ''}${key}`;
    const layerNodes = getLayerNodes(label, sortedSet);

    dataNodes.push({
      title: label,
      key: label,
      children: layerNodes,
    });
  });

  dataNodes.sort(sortObjectAlphabetically.bind(this, 'key'));

  return dataNodes;
};

const getTreeKeyProductInstanceIdMap = (
  tree: DataNode[],
  map: Map<string, string[]>,
  parentKeys: Set<string>
) => {
  tree.forEach((node) => {
    const key = node.key.toString();

    if (!node.children) {
      map.set(key, [key]);

      parentKeys.forEach((parentKey) => {
        map.set(parentKey, [...map.get(parentKey)!, key]);
      });

      return;
    }

    map.set(key, []);
    getTreeKeyProductInstanceIdMap(
      node.children,
      map,
      new Set([...Array.from(parentKeys.values()), key])
    );
  });
};

export {
  handleDownload,
  getStartJobInput,
  getLayersTree,
  getDataNodesFromLayerMap,
  getTreeKeyProductInstanceIdMap,
};
