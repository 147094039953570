import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Redirect, RouteComponentProps } from '@reach/router';
import { isLoggedInVar } from '../../../services/cache';

import AgreementsModal from './AgreementsModal';
import { useReactiveVar } from '@apollo/client';
import AuthenticationPage from '../AuthenticationPage';

const SignUpPage = (props: RouteComponentProps) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  // const captchaContainerRef = useRef(null);
  // const defaultCaptcha: any = null;
  // const captchaRef = useRef(defaultCaptcha);

  const defaultFormVals: any = null;

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVals, setFormVals] = useState(defaultFormVals);

  const [form] = Form.useForm();

  const validateMessages = {
    required: 'This field is required.',
    types: {
      email: 'Please enter a valid email address.',
    },
    string: {
      min: 'Password must be at least 6 characters.',
    },
  };

  const handleClick = async (response: any) => {
    let formValues;

    form.submit();

    try {
      formValues = await form.validateFields();
    } catch (err) {
      return;
    }

    setFormVals(formValues);
    setVisible(true);
  };

  // const handleClick = async () => {
  //   setLoading(true);
  //   setVisible(true);
  //
  //   try {
  //     await captchaRef.current.verify();
  //     setLoading(false);
  //   } catch (err) {
  //     message.error(err);
  //     setLoading(false);
  //     setVisible(false);
  //   }
  // };

  // useEffect(() => {
  //   captchaRef.current = new firebase.auth.RecaptchaVerifier(
  //     captchaContainerRef.current,
  //     {
  //       size: 'invisible',
  //       callback: submitForm,
  //     }
  //   );
  // }, []);

  if (isLoggedIn && !loading) {
    return <Redirect from="" to="/platform/aperture" noThrow />;
  }

  return (
    <>
      <AuthenticationPage
        title="Sign up for Aperture"
        description="Create an account to access continent wide data."
        linkText="Have an account? Login"
        linkTo="/login"
      >
        <Form
          name="basic"
          layout="vertical"
          requiredMark={false}
          validateMessages={validateMessages}
          form={form}
        >
          <Form.Item
            label={
              <p className=" text-gray-700 tracking-normal">First name*</p>
            }
            name="firstName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              size="middle"
              className="rounded bg-gray-000 "
              placeholder="Jane"
            />
          </Form.Item>

          <Form.Item
            label={<p className=" text-gray-700 tracking-normal">Last name*</p>}
            name="lastName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              size="middle"
              className="rounded bg-gray-000 "
              placeholder="Doe"
            />
          </Form.Item>

          <Form.Item
            label={<p className=" text-gray-700 tracking-normal">Email*</p>}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input
              size="middle"
              className="rounded bg-gray-000 "
              placeholder="jane@doe.org"
            />
          </Form.Item>

          <Form.Item
            label={
              <p className=" text-gray-700 tracking-normal">
                Organization name*
              </p>
            }
            name="organization"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              size="middle"
              className="rounded bg-gray-000 "
              placeholder="Company Name"
            />
          </Form.Item>

          <Form.Item
            label={<p className=" text-gray-700 tracking-normal">Password*</p>}
            name="password"
            rules={[{ required: true, min: 6 }]}
          >
            <Input.Password
              size="middle"
              className="rounded bg-gray-000 "
              placeholder="Enter your password"
            />
          </Form.Item>

          {/*<div id="recaptcha-container" ref={captchaContainerRef} />*/}

          <Form.Item className="mt-8">
            <Button
              type="primary"
              className="rounded w-full pt-2 pb-8 font-semibold"
              size="middle"
              onClick={handleClick}
            >
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </AuthenticationPage>

      <AgreementsModal
        visible={visible}
        formVals={formVals}
        setVisible={setVisible}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

export default SignUpPage;
