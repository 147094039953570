import React from 'react';
import DatasetTable from './DatasetTable';
import StepTitle from './StepTitle';

interface DatasetStepContentComponentProps {
  loading: boolean;
  products: any;
  selectedProducts: string[];
  setSelectedProducts: (productInstanceIds: string[]) => void;
}

const DatasetStepContent = (props: DatasetStepContentComponentProps) => {
  const { loading, products, selectedProducts, setSelectedProducts } = props;

  return (
    <div>
      <StepTitle title="Which datasets are of interest?" />
      <DatasetTable
        loading={loading}
        products={products}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
      />
    </div>
  );
};

export default DatasetStepContent;
