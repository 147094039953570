import React, { useEffect, useState } from 'react';
import CreateSelectionButton from './CreateSelectionButton';
import ClearSelectionButton from './ClearSelectionButton';
import { useReactiveVar } from '@apollo/client';
import {
  createSelectionActiveVar,
  selectedFeaturesCountVar,
} from '../../../../services/cache';

const SelectWidget = () => {
  const selectedFeaturesCount = useReactiveVar(selectedFeaturesCountVar);
  const createSelectionActive = useReactiveVar(createSelectionActiveVar);

  const [selectionIsActivated, setSelectionIsActivated] = useState(
    createSelectionActive
  );

  const [validSelection, setValidSelection] = useState(
    selectedFeaturesCount > 0
  );

  useEffect(() => {
    setValidSelection(selectedFeaturesCount > 0);
  }, [selectedFeaturesCount]);

  useEffect(() => {
    setSelectionIsActivated(createSelectionActive);
  }, [createSelectionActive]);

  return (
    <div className="fixed z-10" style={{ bottom: 200, right: 10 }}>
      {validSelection && selectionIsActivated ? <ClearSelectionButton /> : null}
      <CreateSelectionButton />
    </div>
  );
};

export default SelectWidget;
