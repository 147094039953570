import React, { useEffect, useState } from 'react';
import { Select, Spin, Table } from 'antd';
import { DataInsightRecord, Region } from '../../../../types';
import {
  SOUTH_ASIA_COUNTRY_CODES,
  AFRICA_COUNTRY_CODES,
} from '../../../../constants/countryCodes';
import { useQuery, useReactiveVar } from '@apollo/client';
import { currLayerVar, currRegionVar } from '../../../../services/cache';
import { GET_METRICS } from '../../../../services/fetches/statsQueries';
import { columns, convertMetricToRecord } from './dataInsightsUtils';

const { Option } = Select;

const DataInsights = () => {
  const currLayer = useReactiveVar(currLayerVar);
  const [currMetricType, setCurrMetricType] = useState<string>('LOWEST');
  const [currParentBoundaryId, setCurrParentBoundaryId] = useState<number>(0);
  const [regions, setRegions] = useState<Region[]>([]);

  let dataSource: DataInsightRecord[] = [];

  const { data, loading, refetch } = useQuery(GET_METRICS, {
    variables: {
      productInstanceId: currLayer ? currLayer.product_id : null,
      parentBoundaryId: currParentBoundaryId,
      metricType: currMetricType,
      resultCount: 10,
    },
    skip: currLayer === null,
  });

  const handleMetricTypeSelect = (metricType: string) => {
    setCurrMetricType(metricType);
  };

  const handleParentBoundaryIdSelect = (parentBoundaryId: number) => {
    setCurrParentBoundaryId(parentBoundaryId);
  };

  if (data) {
    dataSource = data.metrics.map(convertMetricToRecord);
  }

  useEffect(() => {
    if (currLayer) {
      const { resolution } = currLayer;

      if (resolution === 'admin_level_0') {
        setCurrParentBoundaryId(0);
        setRegions([{ id: 0, name: 'All Nations' }]);
      } else {
        const currRegion = currRegionVar();

        if (currRegion && currRegion.name === 'Africa') {
          setCurrParentBoundaryId(AFRICA_COUNTRY_CODES[0].id);
          setRegions(AFRICA_COUNTRY_CODES);
        } else if (currRegion) {
          setCurrParentBoundaryId(SOUTH_ASIA_COUNTRY_CODES[0].id);
          setRegions(SOUTH_ASIA_COUNTRY_CODES);
        }
      }

      setCurrMetricType('LOWEST');
      refetch();
    }
  }, [currLayer, refetch]);

  return (
    <div className="bg-white px-2 py-6">
      <h2 className="font-semibold text-lg" style={{ marginBottom: 2 }}>
        Data Insights
      </h2>

      <p className="text-gray-800 text-sm">
        Get insights into the data in different regions across the continent.
      </p>

      <Spin spinning={loading}>
        <div className="mt-6">
          <p className="text-gray-800 mb-1">Sort by:</p>
          <div className="mb-4">
            <Select
              className="mr-3"
              value={currMetricType}
              onSelect={handleMetricTypeSelect}
            >
              <Option value="LOWEST">Lowest</Option>
              <Option value="HIGHEST">Highest</Option>
            </Select>

            <Select
              value={currParentBoundaryId}
              style={{ minWidth: 120 }}
              onSelect={handleParentBoundaryIdSelect}
            >
              {regions.map((countryCode) => {
                const { id, name } = countryCode;

                return (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          size="small"
          pagination={false}
        />
      </Spin>
    </div>
  );
};

export default DataInsights;
