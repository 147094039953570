import { InMemoryCache, makeVar } from '@apollo/client';
import firebase from 'firebase';
import {
  DeepLayerFamily,
  Filter,
  Layer,
  LayerAndFamily,
  LayerFamily,
  Region,
  SelectionMode,
} from '../../types';
import mapboxgl, { MapboxGeoJSONFeature, Style, PointLike } from 'mapbox-gl';
import { MultiPolygon, Polygon } from 'geojson';

const defaultFirebaseUserVal: firebase.User[] = [];
const defaultIsLoggedInVal = false;
const defaultApertureTokenVal = '';
const defaultPrevStyle = 'mapbox://styles/mapbox/dark-v10';
const defaultCurrStyle = '';
const defaultCurrLayerSet: Layer[] = [];
const defaultCurrLayer: any = null;
const defaultStyleLayer: any = null;
const defaultLastSelectedFilterLayer: any = null;
const defaultFilters: Map<number, Filter> = new Map();
const defaultModernFilterLayers: LayerAndFamily[] = [];
const defaultLayerFamilies: LayerFamily[] = [];

const isLoggedInVar = makeVar(defaultIsLoggedInVal);
const userVar = makeVar(defaultFirebaseUserVal);
const apertureTokenVar = makeVar(defaultApertureTokenVal);
const currLayerSetVar = makeVar(defaultCurrLayerSet);
const currLayerVar = makeVar(defaultCurrLayer);
const styleLayerVar = makeVar(defaultStyleLayer);
const lastSelectedFilterLayerVar = makeVar(defaultLastSelectedFilterLayer);
const filtersVar = makeVar(defaultFilters);
const modernFilterLayersVar = makeVar(defaultModernFilterLayers);
const allLayerFamiliesVar = makeVar(defaultLayerFamilies);
const createSelectionActiveVar = makeVar(false);
const clearSelectionVar = makeVar(Date.now());
const generatePngVar = makeVar(false);
const selectionGeometryVar = makeVar<Polygon | MultiPolygon | null>(null);
const selectedFeaturesCountVar = makeVar<number>(0);
const filterableLayerFamiliesVar = makeVar<Map<string, DeepLayerFamily>>(
  new Map()
);
const selectionBoxVar = makeVar<HTMLDivElement | null>(null);
const selectionModeVar = makeVar<SelectionMode>(SelectionMode.NewSelection);
const selectedFeaturesVar = makeVar<MapboxGeoJSONFeature[]>([]);
const startMousePosVar = makeVar<PointLike | null>(null);
const mapDataLoadingVar = makeVar(false);
const currGeolevelIdVar = makeVar<string>('admin_level_1');
const popupVar = makeVar<mapboxgl.Popup>(new mapboxgl.Popup());
const currRegionVar = makeVar<Region | null>(null);
const lastVisitedPageVar = makeVar('');

const currLayerFamilyVar = makeVar<DeepLayerFamily | null>(null);

// Map Variables
const mapVar = makeVar<mapboxgl.Map | null>(null);
const zoomVar = makeVar<number | null>(null);
const currStyleVar = makeVar<Style | string | null>(defaultCurrStyle);
const prevStyleVar = makeVar<Style | string | null>(defaultPrevStyle);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currRegion: {
          read() {
            return currRegionVar();
          },
        },
        popup: {
          read() {
            return popupVar();
          },
        },
        currGeolevelId: {
          read() {
            return currGeolevelIdVar();
          },
        },
        lastVisitedPage: {
          read() {
            return lastVisitedPageVar();
          },
        },
        mapDataLoading: {
          read() {
            return mapDataLoadingVar();
          },
        },
        startMousePos: {
          read() {
            return startMousePosVar();
          },
        },

        selectedFeaturesVar: {
          read() {
            return selectedFeaturesVar();
          },
        },

        selectionMode: {
          read() {
            return selectionModeVar();
          },
        },
        selectionBox: {
          read() {
            return selectionBoxVar();
          },
        },
        filterableLayerFamilies: {
          read() {
            return filterableLayerFamiliesVar();
          },
        },
        zoom: {
          read() {
            return zoomVar();
          },
        },
        map: {
          read() {
            return mapVar();
          },
        },

        selectedFeaturesCount: {
          read() {
            return selectedFeaturesCountVar();
          },
        },
        selectionBoundingBoxes: {
          read() {
            return selectionGeometryVar();
          },
        },
        generatePng: {
          read() {
            return generatePngVar();
          },
        },
        clearSelection: {
          read() {
            return clearSelectionVar();
          },
        },
        createSelectionActive: {
          read() {
            return createSelectionActiveVar();
          },
        },
        allLayerFamilies: {
          read() {
            return allLayerFamiliesVar();
          },
        },
        modernFilterLayers: {
          read() {
            return modernFilterLayersVar();
          },
        },
        filters: {
          read() {
            return filtersVar();
          },
        },
        lastSelectedFilterLayerVar: {
          read() {
            return lastSelectedFilterLayerVar();
          },
        },
        styleLayer: {
          read() {
            return styleLayerVar();
          },
        },
        currLayerFamily: {
          read() {
            return currLayerFamilyVar();
          },
        },
        currLayerSet: {
          read() {
            return currLayerSetVar();
          },
        },
        currLayer: {
          read() {
            return currLayerVar();
          },
        },
        currStyle: {
          read() {
            return currStyleVar();
          },
        },
        prevStyle: {
          read() {
            return prevStyleVar();
          },
        },
        user: {
          read() {
            return userVar();
          },
        },
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        apertureTokenVar: {
          read() {
            return apertureTokenVar();
          },
        },
      },
    },
  },
});

export {
  filtersVar,
  lastSelectedFilterLayerVar,
  prevStyleVar,
  styleLayerVar,
  userVar,
  cache,
  isLoggedInVar,
  apertureTokenVar,
  currStyleVar,
  currLayerFamilyVar,
  currLayerSetVar,
  currLayerVar,
  modernFilterLayersVar,
  allLayerFamiliesVar,
  createSelectionActiveVar,
  clearSelectionVar,
  generatePngVar,
  selectionGeometryVar,
  selectedFeaturesCountVar,
  mapVar,
  zoomVar,
  filterableLayerFamiliesVar,
  selectionBoxVar,
  selectionModeVar,
  selectedFeaturesVar,
  startMousePosVar,
  mapDataLoadingVar,
  lastVisitedPageVar,
  currGeolevelIdVar,
  popupVar,
  currRegionVar,
};
