import React from 'react';

import { RouteComponentProps } from '@reach/router';
import AtlasCard from '../../../shared/AtlasCard';
import { Header1, InputDescription } from '../../../shared/Typography';
import AtlasDivider from '../../../shared/AtlasDivider';
import { Breakpoint } from 'react-socks';
import DesktopSubscriptionInfo from './DesktopSubscriptionInfo';
import MobileSubscriptionInfo from './MobileSubscriptionInfo';

const SubscriptionPage = (props: RouteComponentProps) => {
  document.title = 'Aperture | Settings: Subscription';

  return (
    <AtlasCard>
      <div>
        <Header1>Current Subscription</Header1>
        <AtlasDivider />

        <InputDescription className="text-gray-400">
          You are currently enrolled in the
          <span className="font-bold"> individual public plan</span>.
        </InputDescription>
      </div>

      <div className="mt-10">
        <Header1>Upgrade Your Plan</Header1>
        <AtlasDivider />

        <Breakpoint medium down>
          <MobileSubscriptionInfo />
        </Breakpoint>

        <Breakpoint large up>
          <DesktopSubscriptionInfo />
        </Breakpoint>
      </div>
    </AtlasCard>
  );
};

export default SubscriptionPage;
