import React from 'react';
import './contentContainer.less';

const ContentContainer: React.FunctionComponent = (props) => {
  const { children } = props;

  return <div className={'content-container'}>{children}</div>;
};

export default ContentContainer;
