import React from 'react';
import LayerFamilyDescription from './LayerFamilyDescription';
import DataInsights from './DataInsights';
import { LayerFamily } from '../../../../types';

interface LayerFamilyMenuProps {
  selectedFamily: LayerFamily;
}

const LayerFamilyMenu = (props: LayerFamilyMenuProps) => {
  const { selectedFamily } = props;

  return (
    <>
      <div className="mt-4">
        <LayerFamilyDescription
          name={selectedFamily.name ? selectedFamily.name : ''}
          shortDesc={
            selectedFamily.description ? selectedFamily.description : ''
          }
          regionDesc={selectedFamily.coverage ? selectedFamily.coverage : ''}
          updateFreqDesc={
            selectedFamily.update_frequency
              ? selectedFamily.update_frequency
              : ''
          }
          lastUpdateDesc={
            selectedFamily.last_update ? selectedFamily.last_update : ''
          }
        />
      </div>

      <div className="mt-6">
        <DataInsights />
      </div>
    </>
  );
};

export default LayerFamilyMenu;
