import React, { useState } from 'react';
import firebase from 'firebase';
import { RouteComponentProps } from '@reach/router';
import { Form, Input, Button, Spin, message, Modal } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../../../services/fetches/hekatonQueries';
import { UPDATE_USER_PROFILE } from '../../../services/fetches/hekatonMutations';
import { Header1, InputDescription, InputLabel } from '../../shared/Typography';
import Card from '../../shared/AtlasCard';
import AtlasDivider from '../../shared/AtlasDivider';

interface UpdateProfileFormValues {
  email: string;
  firstName: string;
  lastName: string;
}

const LoadingForm = () => {
  return (
    <Spin spinning={true}>
      <Form layout="vertical" style={{ maxWidth: 450 }}>
        <Form.Item label={<InputLabel>First Name</InputLabel>}>
          <Input />
        </Form.Item>
        <Form.Item label={<InputLabel>Last Name</InputLabel>}>
          <Input />
        </Form.Item>
        <Form.Item
          label={<InputLabel>Email Address</InputLabel>}
          extra="Invoices & subscription updates will be sent to this email address."
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary">Update Profile</Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

const ProfilePage = (props: RouteComponentProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { loading: queryLoading, data: profileData } =
    useQuery(GET_USER_PROFILE);

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    update: (cache, mutationResult) => {
      const {
        data: {
          updateUserProfile: { first_name, last_name, email },
        },
      } = mutationResult;

      const existingMe: any = cache.readQuery({
        query: GET_USER_PROFILE,
      });

      cache.modify({
        id: cache.identify(existingMe.me),
        fields: {
          first_name() {
            return first_name;
          },
          last_name() {
            return last_name;
          },
          email() {
            return email;
          },
        },
      });
    },
  });

  const [updateLoading, setUpdateLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleUpdateProfile = async (values: UpdateProfileFormValues) => {
    const { firstName, lastName, email } = values;
    const auth = firebase.auth();
    const user = auth.currentUser;

    setUpdateLoading(true);
    try {
      await user?.updateEmail(email);

      await updateUserProfile({
        variables: { email, first_name: firstName, last_name: lastName },
      });

      message.success('User profile updated.');
    } catch (error) {
      message.error(error.message);
    }
    setUpdateLoading(false);
  };

  const handleConfirmPassword = async (values: any) => {
    const { password } = values;
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user?.email,
      password
    );

    try {
      await user?.reauthenticateWithCredential(credential);
      setIsModalVisible(false);
      form.submit();
    } catch (error) {
      message.error(error.message);
    }
  };

  let email = '';
  let firstName = '';
  let lastName = '';

  // Check to see if the profile data has loaded.
  if (profileData) {
    const { me } = profileData;

    email = me.email;
    firstName = me.first_name;
    lastName = me.last_name;
  }

  document.title = 'Aperture | Settings: Profile';

  return (
    <Card>
      <Header1>Profile</Header1>
      <AtlasDivider />

      <Modal
        title="Confirm your password to update your profile."
        visible={isModalVisible}
        closable
        onCancel={hideModal}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={handleConfirmPassword}
          requiredMark={false}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm Password
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {queryLoading || updateLoading ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: 450 }}
          name="updateProfileForm"
          onFinish={handleUpdateProfile}
          initialValues={{
            email,
            firstName,
            lastName,
          }}
        >
          <Form.Item
            label={<InputLabel>First Name</InputLabel>}
            name="firstName"
          >
            <Input />
          </Form.Item>
          <Form.Item label={<InputLabel>Last Name</InputLabel>} name="lastName">
            <Input />
          </Form.Item>
          <Form.Item
            label={<InputLabel>Email Address</InputLabel>}
            name="email"
            extra={
              <InputDescription className="mt-2">
                Invoices & subscription updates will be sent to this email
                address.
              </InputDescription>
            }
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={showModal}>
              Update Profile
            </Button>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

export default ProfilePage;
