import Icon, { TeamOutlined } from '@ant-design/icons';
import { FunctionComponent } from 'react';
import EconomicSVG from '../assets/images/EconomicSVG';
import ElectrificationSVG from '../assets/images/ElectrificationSVG';
import AgricultureSVG from '../assets/images/AgricultureSVG';

const EconomicIcon: FunctionComponent = (props) => {
  return (
    <Icon component={EconomicSVG} {...props} className="text-xs inline-block" />
  );
};

const AgricultureIcon: FunctionComponent = (props) => {
  return (
    <Icon
      component={AgricultureSVG}
      {...props}
      className="text-xs inline-block"
    />
  );
};

const ElectrificationIcon: FunctionComponent = (props) => {
  return (
    <Icon
      component={ElectrificationSVG}
      {...props}
      className="text-xs inline-block"
    />
  );
};

const DOMAINS = {
  population: {
    name: 'Population',
    colorClass: 'blond',
    icon: <TeamOutlined className="text-xs inline-block" />,
  },
  economic: {
    name: 'Economic Indicators',
    colorClass: 'columbia-blue',
    icon: <EconomicIcon />,
  },
  electrification: {
    name: 'Electrification',
    colorClass: 'lavender-blue',
    icon: <ElectrificationIcon />,
  },
  agriculture: {
    name: 'Agriculture',
    colorClass: 'magic-mint',
    icon: <AgricultureIcon />,
  },
};

export default DOMAINS;
