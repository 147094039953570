import React from 'react';

interface MenuTitleComponentProps {
  title: string;
}

const DownloadMenuTitle = (props: MenuTitleComponentProps) => {
  return (
    <span className={'text-xs uppercase font-semibold my-0'}>
      {props.title}
    </span>
  );
};

export default DownloadMenuTitle;
