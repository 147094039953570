import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Button, Drawer, Steps } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { COUNTRY_CODE_MAP } from '../../../../../constants/countryCodes';
import { COUNTRY_GEOMETRY_MAP } from '../../../../../constants/countryGeometries';
import { RESOLUTION_MAP } from '../../../../../constants/resolutions';
import { currRegionVar } from '../../../../../services/cache';
import DownloadClient from '../../../../../services/clients/DownloadClient';
import { START_JOB_WITH_GEOGRAPHY } from '../../../../../services/fetches/downloadMutations';
import { GET_DOWNLOAD_INFO_BY_REGION } from '../../../../../services/fetches/hekatonQueries';
import {
  DownloadLayer,
  DownloadLayerFamily,
  DownloadTypes,
} from '../../../../../types';
import { getStartJobInput } from '../DownloadUtils';
import ConfirmStepContent from './ConfirmStepContent';
import DatasetStepContent from './DatasetStepContent';
import GeolevelStepContent from './GeolevelStepContent';
import ProcessedStepContent from './ProcessedStepContent';
import RegionStepContent from './RegionStepContent';

interface DownloadDrawerContainerProps {
  visible: boolean;
  hide: () => void;
}

const { Step } = Steps;

const formatProducts = (products: any | null, geolevel: string | null) => {
  if (products && geolevel) {
    const {
      region: { layerFamilies, layers },
    } = products;

    const layersMap = layers.reduce((currMap, layer) => {
      const { product_id, product_family_id, time_period, resolution } = layer;

      if (resolution === geolevel) {
        if (currMap.get(product_family_id)) {
          currMap.set(product_family_id, [
            ...currMap.get(product_family_id),
            {
              name: time_period,
              key: product_id,
              isChild: true,
              enabled: true,
            },
          ]);
        } else {
          currMap.set(product_family_id, [
            {
              name: time_period,
              key: product_id,
              isChild: true,
              enabled: true,
            },
          ]);
        }
      }

      return currMap;
    }, new Map());

    return layerFamilies
      .map(({ name, description, product_family_id }) => ({
        key: product_family_id,
        name,
        description,
        isChild: false,
        children: layersMap.get(product_family_id)?.sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          }
          if (a.name > b.name) {
            return -1;
          }
          return 0;
        }),
        enabled: layersMap.get(product_family_id),
      }))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .filter((record) => record.name.indexOf('Filter') === -1);
  }
  return [];
};

const DownloadDrawerContainer = (props: DownloadDrawerContainerProps) => {
  const { visible, hide } = props;
  const currRegion = useReactiveVar(currRegionVar);

  const [downloadName, setDownloadName] = useState<string | null>(null);
  const [currStepIdx, setCurrStepIdx] = useState(0);
  const [selectedCountryIds, setSelectedCountries] = useState<number[]>([]);
  const [selectedGeolevel, setSelectedGeolevel] = useState<string | null>(null);
  const [selectedProductInstanceIds, setSelectedProductInstanceIds] = useState<
    string[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<
    Map<string, DownloadLayerFamily>
  >(new Map());
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);

  const { loading: productsLoading, data: products } = useQuery(
    GET_DOWNLOAD_INFO_BY_REGION,
    {
      variables: {
        regionId: currRegion ? currRegion.id : '',
      },
      skip: currRegion === null,
    }
  );

  const steps = [
    {
      title: 'Region',
      content: (
        <RegionStepContent
          selectedCountries={selectedCountryIds}
          selectCountries={setSelectedCountries}
        />
      ),
    },
    {
      title: 'Geographic Level',
      content: (
        <GeolevelStepContent
          selectedGeolevel={selectedGeolevel}
          setSelectedGeolevel={setSelectedGeolevel}
        />
      ),
    },
    {
      title: 'Dataset',
      content: (
        <DatasetStepContent
          loading={productsLoading}
          products={formatProducts(products, selectedGeolevel)}
          selectedProducts={selectedProductInstanceIds}
          setSelectedProducts={setSelectedProductInstanceIds}
        />
      ),
    },
    {
      title: 'Confirm',
      content: (
        <ConfirmStepContent
          countryIds={selectedCountryIds}
          geolevel={selectedGeolevel}
          products={selectedProducts}
          downloadName={downloadName}
          setDownloadName={setDownloadName}
        />
      ),
    },
  ];

  const [startJob, { loading: startJobLoading }] = useMutation(
    START_JOB_WITH_GEOGRAPHY,
    { client: DownloadClient }
  );

  const reset = () => {
    setCurrStepIdx(0);
    setSelectedCountries([]);
    setSelectedGeolevel(null);
    setSelectedProductInstanceIds([]);
    setDownloadName(null);
  };

  useEffect(reset, [currRegion]);

  useEffect(() => {
    setSelectedProductInstanceIds([]);
    setDownloadName(null);
  }, [selectedGeolevel]);

  useEffect(() => {
    if (products && selectedProductInstanceIds.length > 0) {
      const { region } = products;
      const layerFamilies: DownloadLayerFamily[] = region.layerFamilies;
      const layers: DownloadLayer[] = region.layers;

      const layerFamiliesMap = layerFamilies.reduce<
        Map<string, DownloadLayerFamily>
      >((map, currLayerFamily) => {
        map.set(currLayerFamily.product_family_id, currLayerFamily);
        return map;
      }, new Map());

      const layersMap = layers.reduce<Map<string, DownloadLayer>>(
        (map, currLayer) => {
          map.set(currLayer.product_id, currLayer);
          return map;
        },
        new Map()
      );

      const selectedProducts: Map<string, DownloadLayerFamily> = new Map();
      const familyNames = [];

      selectedProductInstanceIds.forEach((productInstanceId) => {
        if (layersMap.has(productInstanceId)) {
          const layer = layersMap.get(productInstanceId);
          const productFamilyId = layer.product_family_id;
          const productFamily = layerFamiliesMap.get(productFamilyId);

          familyNames.push(productFamily.name);

          if (!selectedProducts.has(productFamilyId)) {
            selectedProducts.set(productFamilyId, {
              ...productFamily,
              layers: [layer],
            });
          } else {
            const currLayers = selectedProducts.get(productFamilyId).layers;

            selectedProducts.set(productFamilyId, {
              ...productFamily,
              layers: [...currLayers, layer],
            });
          }
        }
      });

      setSelectedProducts(selectedProducts);

      setDownloadName(
        `${selectedCountryIds
          .map((countryId) => COUNTRY_CODE_MAP.get(countryId))
          .join(', ')} - ${
          RESOLUTION_MAP.get(selectedGeolevel).label
        } - ${familyNames.join(', ')}`
      );
    }
  }, [
    selectedProductInstanceIds,
    products,
    selectedCountryIds,
    selectedGeolevel,
  ]);

  const next = () => {
    setCurrStepIdx(currStepIdx + 1);
  };

  const prev = () => {
    setCurrStepIdx(currStepIdx - 1);
  };

  useEffect(() => {
    if (currStepIdx === 0) {
      selectedCountryIds.length > 0
        ? setNextDisabled(false)
        : setNextDisabled(true);
    } else if (currStepIdx === 1) {
      selectedGeolevel !== null
        ? setNextDisabled(false)
        : setNextDisabled(true);
    }
  }, [currStepIdx, selectedCountryIds, selectedGeolevel]);

  const startDownload = async (
    name: string,
    productInstanceIds: string[],
    countryGeometries: string[]
  ) => {
    const variables = getStartJobInput(
      name,
      DownloadTypes.CountryCSV,
      productInstanceIds,
      countryGeometries
    );
    try {
      const response = await startJob({ variables });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownload = useCallback(() => {
    const { region } = products;
    const layers: DownloadLayer[] = region.layers;

    const layersMap = layers.reduce<Map<string, DownloadLayer>>(
      (map, currLayer) => {
        map.set(currLayer.product_id, currLayer);
        return map;
      },
      new Map()
    );

    const productInstanceIds = selectedProductInstanceIds.filter((id) => {
      layersMap.has(id);
    });

    const countryGeometries = selectedCountryIds
      .map((id) => COUNTRY_CODE_MAP.get(id))
      .map((name) => COUNTRY_GEOMETRY_MAP.get(name));

    startDownload(downloadName, productInstanceIds, countryGeometries).then(
      () => {
        setCurrStepIdx(currStepIdx + 1);
      }
    );
  }, [
    products,
    downloadName,
    selectedCountryIds,
    selectedProductInstanceIds,
    currStepIdx,
  ]);

  return (
    <Drawer
      placement="right"
      visible={visible}
      onClose={() => {
        hide();
      }}
      width={600}
    >
      {currStepIdx < steps.length ? (
        <>
          <div className="flex justify-between mt-10">
            <Steps current={currStepIdx} progressDot>
              {steps.map(({ title }) => (
                <Step key={title} title={title} />
              ))}
            </Steps>
          </div>

          <div className="py-8">{steps[currStepIdx].content}</div>

          <div
            className="absolute w-full px-6 py-4 flex flex-row-reverse justify-between border border-r-0 border-l-0 border-b-0"
            style={{ bottom: 0, left: 0 }}
          >
            {currStepIdx < 3 ? (
              <Button type="primary" onClick={next} disabled={nextDisabled}>
                Next
              </Button>
            ) : null}
            {currStepIdx === 3 ? (
              <Button type="primary" onClick={handleDownload}>
                Download
              </Button>
            ) : null}
            {currStepIdx > 0 ? <Button onClick={prev}>Previous</Button> : null}
          </div>
        </>
      ) : (
        <ProcessedStepContent
          dismiss={() => {
            hide();
            reset();
          }}
        />
      )}
    </Drawer>
  );
};

export default DownloadDrawerContainer;
