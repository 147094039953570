import { gql } from '@apollo/client';

const START_JOB_WITH_SELECTION = gql`
  mutation StartJob(
    $productInstanceIds: [ID]
    $productInstanceFilters: [ProductInstanceFilterInput]
    $geographicFilter: [String]
    $jobName: String
    $outputFields: [OutputFields!]
    $outputFormat: OutputFormat
  ) {
    startJob(
      input: {
        productInstanceIds: $productInstanceIds
        geographicFilter: $geographicFilter
        jobName: $jobName
        outputFields: $outputFields
        outputFormat: $outputFormat
        productInstanceFilters: $productInstanceFilters
      }
    ) {
      jobId
    }
  }
`;

const START_JOB_WITH_FILTER = gql`
  mutation StartJob(
    $productInstanceIds: [ID]
    $productInstanceFilters: [ProductInstanceFilterInput]
    $jobName: String
    $outputFields: [OutputFields!]
    $outputFormat: OutputFormat
  ) {
    startJob(
      input: {
        productInstanceIds: $productInstanceIds
        productInstanceFilters: $productInstanceFilters
        jobName: $jobName
        outputFields: $outputFields
        outputFormat: $outputFormat
      }
    ) {
      jobId
    }
  }
`;

const START_JOB_WITH_ALL = gql`
  mutation StartJob(
    $productInstanceIds: [ID]
    $jobName: String
    $geographicFilter: [String]
    $outputFields: [OutputFields!]
    $outputFormat: OutputFormat
  ) {
    startJob(
      input: {
        productInstanceIds: $productInstanceIds
        jobName: $jobName
        outputFields: $outputFields
        outputFormat: $outputFormat
      }
    ) {
      jobId
    }
  }
`;

const START_JOB_WITH_GEOGRAPHY = gql`
  mutation StartJob(
    $productInstanceIds: [ID]
    $jobName: String
    $geographicFilter: [String]
    $outputFields: [OutputFields!]
    $outputFormat: OutputFormat
  ) {
    startJob(
      input: {
        productInstanceIds: $productInstanceIds
        jobName: $jobName
        geographicFilter: $geographicFilter
        outputFields: $outputFields
        outputFormat: $outputFormat
      }
    ) {
      jobId
    }
  }
`;

export {
  START_JOB_WITH_SELECTION,
  START_JOB_WITH_FILTER,
  START_JOB_WITH_ALL,
  START_JOB_WITH_GEOGRAPHY,
};
