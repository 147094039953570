import React from 'react';

interface LayerFamilyAttributeProps {
  label: string;
  icon: any;
  last: boolean;
}

const LayerFamilyAttribute = (props: LayerFamilyAttributeProps) => {
  return (
    <div className={`${props.last ? '' : 'mb-3'} flex items-center`}>
      {props.icon} <span className="ml-2 text-md">{props.label}</span>
    </div>
  );
};

export default LayerFamilyAttribute;
