import React, { useEffect } from 'react';
import DownloadsTable from './DownloadsTable';
import { DownloadDatum, DownloadJob } from '../../../types';
import { GET_JOBS } from '../../../services/fetches/downloadQueries';
import DownloadClient from '../../../services/clients/DownloadClient';
import { useQuery } from '@apollo/client';
import { message } from 'antd';

const DownloadsTableContainer = () => {
  const { loading, error, data, startPolling, stopPolling } = useQuery(
    GET_JOBS,
    {
      client: DownloadClient,
    }
  );
  let downloadData: DownloadDatum[] = [];

  if (data) {
    downloadData = data.getJobs.map((downloadJob: DownloadJob) => {
      const { jobId, jobName, dataUri, timeRequested, jobStatus } = downloadJob;
      return {
        key: jobId,
        jobName,
        jobStatus,
        dataUri,
        timeRequested,
      };
    });
    downloadData.sort((a, b) => {
      const dateA = new Date(a.timeRequested);
      const dateB = new Date(b.timeRequested);
      return dateB.getTime() - dateA.getTime();
    });
  }

  useEffect(() => {
    startPolling(2000);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  if (error) {
    message.error(
      "Something went wrong. We can't get your downloads right now."
    );
  }

  return <DownloadsTable loading={loading} data={downloadData} />;
};

export default DownloadsTableContainer;
