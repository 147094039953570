import React from 'react';
import HubspotForm from 'react-hubspot-form';

const SalesPage = (props) => {
  const handleReady = (form) => {};

  document.title = 'Aperture | Settings: Contact Sales';
  return (
    <div className="px-10 py-12">
      <HubspotForm
        portalId="8089401"
        formId="34a9d174-968e-4e46-b679-4102f45bdd50"
        onReady={handleReady}
      />
    </div>
  );
};

export default SalesPage;
