import React from 'react';
import {
  CompassOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import LayerFamilyAttribute from './LayerFamilyAttribute';

interface LayerFamilyDescriptionProps {
  name: string;
  shortDesc: string;
  regionDesc: string;
  updateFreqDesc: string;
  lastUpdateDesc: string;
}

const formatLastUpdatedVal = (val: string) => {
  const [yearVal, monthVal] = val.split('_');
  let month = '';

  if (monthVal) {
    switch (monthVal) {
      case '1':
        month = 'January';
        break;
      case '2':
        month = 'February';
        break;
      case '3':
        month = 'March';
        break;
      case '4':
        month = 'April';
        break;
      case '5':
        month = 'May';
        break;
      case '6':
        month = 'June';
        break;
      case '7':
        month = 'July';
        break;
      case '8':
        month = 'August';
        break;
      case '9':
        month = 'September';
        break;
      case '10':
        month = 'October';
        break;
      case '11':
        month = 'November';
        break;
      case '12':
        month = 'December';
        break;
    }

    return `${month} ${yearVal}`;
  }

  return val;
};

const LayerFamilyDescription = (props: LayerFamilyDescriptionProps) => {
  const { name, shortDesc, regionDesc, updateFreqDesc, lastUpdateDesc } = props;

  return (
    <div className="bg-white px-2 py-6">
      <h2
        className="font-semibold text-lg leading-0"
        style={{ marginBottom: 2 }}
      >
        {name}
      </h2>
      <p className="text-gray-800 text-sm">{shortDesc}</p>

      <div className="pt-4">
        <LayerFamilyAttribute
          last={false}
          label={regionDesc}
          icon={<CompassOutlined />}
        />
        <LayerFamilyAttribute
          last={false}
          label={updateFreqDesc}
          icon={<CalendarOutlined />}
        />
        <LayerFamilyAttribute
          last={true}
          label={`Last Updated: ${formatLastUpdatedVal(lastUpdateDesc)}`}
          icon={<ClockCircleOutlined />}
        />
      </div>
    </div>
  );
};

export default LayerFamilyDescription;
