import userflow from 'userflow.js';

const userflowToken: string = process.env.REACT_APP_USERFLOW_TOKEN
  ? process.env.REACT_APP_USERFLOW_TOKEN
  : '';

const initializeUserflow = () => {
  userflow.init(userflowToken);
};

const userflowIdentify = (userId: string, name: string, email: string) => {
  userflow.identify(userId, {
    name,
    email,
  });
};

export { initializeUserflow, userflowIdentify };
