import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { FilterAttribute } from '../../../../../types';
import { events, rudderTrack } from '../../../../../utils/rudderUtils';

interface AttributeListItemProps {
  attribute: FilterAttribute;
  setActiveAttribute: any;
}

const AttributeListItem = (props: AttributeListItemProps) => {
  const { attribute, setActiveAttribute } = props;

  const handleClick = () => {
    setActiveAttribute(attribute);
    rudderTrack(events.filterTypeSelected);
  };

  return (
    <div
      onClick={handleClick}
      className="py-3 flex items-center justify-between hover:bg-gray-200 px-2 transition-all duration-300 cursor-pointer"
    >
      <div className="flex items-center">
        <span className="text-gray-500 mr-2">{props.attribute.name}</span>
        <Tooltip title={props.attribute.description} placement="bottom">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <div>
        <RightOutlined />
      </div>
    </div>
  );
};

export default AttributeListItem;
