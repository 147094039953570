import { useQuery, useReactiveVar } from '@apollo/client';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { currLayerFamilyVar, currLayerVar } from '../../../../services/cache';
import { GET_LEGEND_BINS } from '../../../../services/fetches/statsQueries';
import { DeepLayerFamily, Layer, LegendBin } from '../../../../types';
import { getColorRampForProductInstance } from '../Map/styleUtils';
import ColorDefinition from './ColorDefinition';

const getDefinitionFromBin = (
  bin: LegendBin,
  idx: number,
  binCount: number,
  unit: string,
  roundTo = 0
) => {
  const { min, max } = bin;
  const formattedMin = parseFloat(min.toFixed(roundTo)).toLocaleString();
  const formattedMax = parseFloat(max.toFixed(roundTo)).toLocaleString();

  if (idx === 0) {
    return `< ${formattedMax}`;
  } else if (idx === binCount - 1) {
    return `> ${formattedMin}`;
  }
  return `${formattedMin} - ${formattedMax}`;
};

const ColorDefinitionListContainer = () => {
  const currLayer: Layer = useReactiveVar(currLayerVar);
  const currLayerFamily: DeepLayerFamily | null =
    useReactiveVar(currLayerFamilyVar);

  const [colorDefinitions, setColorDefinitions] = useState<JSX.Element[]>([]);
  const [filterType, setFilterType] = useState('');
  let productId = '';

  let category_labels: string[] = [];
  let human_id = '';

  let colors: string[];

  useEffect(() => {
    if (currLayer) {
      setFilterType(currLayer.filterType);
    }
  }, [currLayer]);

  if (currLayer && currLayerFamily) {
    productId = currLayer.product_id;
    category_labels = currLayerFamily.category_labels
      ? currLayerFamily.category_labels
      : [];
    human_id = currLayerFamily.human_id;
  }

  const { data, loading } = useQuery(GET_LEGEND_BINS, {
    variables: { productInstanceId: productId },
    skip:
      currLayer === null ||
      productId.length === 0 ||
      filterType === 'categorical',
  });

  useEffect(() => {
    if (filterType === 'categorical') {
      colors = getColorRampForProductInstance(
        human_id,
        filterType,
        category_labels.length
      );

      setColorDefinitions([
        ...category_labels.map((label, idx) => {
          return (
            <ColorDefinition
              color={colors[idx]}
              definition={label}
              key={label}
            />
          );
        }),
        <ColorDefinition
          color={'#000'}
          definition={'No Value'}
          key={'no-val'}
        />,
      ]);
    }
    if (data && !loading) {
      const legendBins: LegendBin[] = data.legendBins;
      const filteredBins = legendBins.filter((bin) => {
        return bin.max !== bin.min;
      });

      colors = getColorRampForProductInstance(
        human_id,
        filterType,
        category_labels.length
      );

      setColorDefinitions([
        ...filteredBins.map((bin, idx, allBins) => {
          const definition = getDefinitionFromBin(
            bin,
            idx,
            allBins.length,
            currLayerFamily.unit,
            currLayerFamily.roundTo
          );
          return (
            <ColorDefinition
              color={colors[idx]}
              definition={definition}
              key={definition}
            />
          );
        }),
        <ColorDefinition
          color={'#000'}
          definition={'No Value'}
          key={'no-val'}
        />,
      ]);
    }
  }, [data, loading, filterType]);

  return (
    <Spin spinning={loading}>
      <div className="mb-4">{colorDefinitions}</div>
    </Spin>
  );
};

export default ColorDefinitionListContainer;
