import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { resetSelectionLayer } from '../Map/mapUtils';
import { selectedFeaturesCountVar } from '../../../../services/cache';

const ClearSelectionButton = () => {
  const handleClick = () => {
    resetSelectionLayer();
    selectedFeaturesCountVar(0);
  };

  return (
    <Button onClick={handleClick}>
      <DeleteOutlined className={'relative'} style={{ top: '-2px' }} />
    </Button>
  );
};

export default ClearSelectionButton;
