export enum ContentItemType {
  CHECKMARK,
  TEXT,
}

export interface ContentItem {
  type: ContentItemType;
  name: string;
}

export interface TextContentItem extends ContentItem {
  text: string;
}

export interface CheckmarkContentItem extends ContentItem {
  checked: boolean;
}

export interface Section {
  name: string;
  content: (TextContentItem | CheckmarkContentItem)[];
}

export interface SubscriptionInfo {
  sections: Section[];
  name: string;
  cost: string;
  seats: string;
  license: string;
  timeOfInterest: string;
}

/***************
 * DATA LAYERS *
 ***************/

const PUBLIC_DATA_LAYERS: Section = {
  name: 'Data Layers',
  content: [
    {
      type: ContentItemType.CHECKMARK,
      name: 'Total Population',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Population Density',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Sorghum Production',
      checked: true,
    },
    { type: ContentItemType.CHECKMARK, name: 'Sorghum Area', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Rice Production', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Rice Area', checked: true },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Maize Production',
      checked: true,
    },
    { type: ContentItemType.CHECKMARK, name: 'Maize Area', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Consumption', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Asset Wealth', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Asset Growth', checked: true },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Electrification',
      checked: false,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Without access to electricity, Population',
      checked: false,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Without access to electricity, Area',
      checked: false,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'With access to electricity, Population',
      checked: false,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'With access to electricity, Area',
      checked: false,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Time Since Electrification',
      checked: false,
    },
  ],
};

const MACRO_DATA_LAYERS: Section = {
  name: 'Data Layers',
  content: [
    {
      type: ContentItemType.CHECKMARK,
      name: 'Total Population',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Population Density',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Sorghum Production',
      checked: true,
    },
    { type: ContentItemType.CHECKMARK, name: 'Sorghum Area', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Rice Production', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Rice Area', checked: true },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Maize Production',
      checked: true,
    },
    { type: ContentItemType.CHECKMARK, name: 'Maize Area', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Consumption', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Asset Wealth', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Asset Growth', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Electrification', checked: true },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Without access to electricity, Population',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Without access to electricity, Area',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'With access to electricity, Population',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'With access to electricity, Area',
      checked: true,
    },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Time Since Electrification',
      checked: true,
    },
  ],
};

const ENTERPRISE_DATA_LAYERS = MACRO_DATA_LAYERS;

/**************
 * RESOLUTION *
 **************/

const PUBLIC_RESOLUTION: Section = {
  name: 'Resolution',
  content: [
    { type: ContentItemType.CHECKMARK, name: 'Admin 0 Summary', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Admin 1 Summary', checked: true },
    {
      type: ContentItemType.CHECKMARK,
      name: 'Admin 2 Summary',
      checked: false,
    },
    { type: ContentItemType.CHECKMARK, name: 'Point Data', checked: false },
    { type: ContentItemType.CHECKMARK, name: 'Raster Data', checked: false },
  ],
};

const MACRO_RESOLUTION: Section = {
  name: 'Resolution',
  content: [
    { type: ContentItemType.CHECKMARK, name: 'Admin 0 Summary', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Admin 1 Summary', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Admin 2 Summary', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Point Data', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Raster Data', checked: false },
  ],
};

const ENTERPRISE_RESOLUTION: Section = {
  name: 'Resolution',
  content: [
    { type: ContentItemType.CHECKMARK, name: 'Admin 0 Summary', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Admin 1 Summary', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Admin 2 Summary', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Point Data', checked: true },
    { type: ContentItemType.CHECKMARK, name: 'Raster Data', checked: true },
  ],
};

const PUBLIC_INFO: SubscriptionInfo = {
  name: 'Public',
  cost: 'Free',
  seats: '1',
  license: 'CC-by-NC',
  timeOfInterest: '2003 - 2018',
  sections: [PUBLIC_DATA_LAYERS, PUBLIC_RESOLUTION],
};

const MACRO_INFO: SubscriptionInfo = {
  name: 'Macro',
  cost: '$50,000 / year',
  seats: '15',
  license: 'Single Entity Internal Use Only',
  timeOfInterest: '2003 - Present',
  sections: [MACRO_DATA_LAYERS, MACRO_RESOLUTION],
};

const ENTERPRISE_INFO: SubscriptionInfo = {
  name: 'Enterprise',
  cost: '$150,000 / year',
  seats: 'Unlimited',
  license:
    'Multi Entity Internal Use Only (Contact Us for Derivative Use Licensing)',
  timeOfInterest: '2003 - Present',
  sections: [ENTERPRISE_DATA_LAYERS, ENTERPRISE_RESOLUTION],
};

const SUBSCRIPTION_INFO_MAP: Map<string, SubscriptionInfo> = new Map();

SUBSCRIPTION_INFO_MAP.set('free', PUBLIC_INFO);
SUBSCRIPTION_INFO_MAP.set('macro', MACRO_INFO);
SUBSCRIPTION_INFO_MAP.set('enterprise', ENTERPRISE_INFO);

export default SUBSCRIPTION_INFO_MAP;
