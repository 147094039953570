import React from 'react';
import { Paragraph } from '../../../shared/Typography';

interface TextContentItemComponentProps {
  name: string;
  text: string;
  isGray: boolean;
}

const TextContentItem = (props: TextContentItemComponentProps) => {
  const { name, text, isGray } = props;

  return (
    <div
      className={`flex justify-between py-2 px-1 ${
        isGray ? 'bg-gray-100' : ''
      }`}
    >
      <Paragraph className="text-gray-600">{name}</Paragraph>
      <Paragraph className="text-gray-600">{text}</Paragraph>
    </div>
  );
};

export default TextContentItem;
