import { useQuery } from '@apollo/client';
import { Button, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { currRegionVar } from '../../../../services/cache';
import { GET_USER_REGIONS } from '../../../../services/fetches/hekatonQueries';
import { Region } from '../../../../types';
import { zoomToLngLat } from '../Map/mapUtils';
import RegionRadioGroup from './RegionRadioGroup';
import { getDefaultRegionId } from './regionUtils';

interface RegionModalComponentProps {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
}

const RegionModal = (props: RegionModalComponentProps) => {
  const { isVisible, setVisible } = props;
  const { loading, data } = useQuery(GET_USER_REGIONS);

  const [spinning, setSpinning] = useState(true);
  const [selectedRegionId, setSelectedRegionId] = useState<
    string | number | null
  >(null);
  const [regions, setRegions] = useState<Region[]>([]);

  const handleOk = useCallback(() => {
    const region = regions.find((region) => region.id === selectedRegionId)!;
    const { flyTo } = region;
    const { center, zoom } = flyTo!;

    setVisible(false);
    currRegionVar(region);
    zoomToLngLat([center[0], center[1]], undefined, zoom);
  }, [selectedRegionId, setVisible, regions]);

  const handleRegionSelect = (regionId: string) => {
    setSelectedRegionId(regionId);
  };

  useEffect(() => {
    if (!loading && data && data.userRegions && data.userRegions.length > 0) {
      setRegions(data.userRegions);
      setSelectedRegionId(getDefaultRegionId(data.userRegions, null));
    }
  }, [data, loading]);

  useEffect(() => {
    setSpinning(loading);
  }, [loading]);

  return (
    <Spin spinning={spinning}>
      <Modal
        getContainer="#modalContainer"
        closable={false}
        visible={isVisible}
        onOk={handleOk}
        width={520}
        title={
          <>
            <h1 className="text-gray-900 font-semibold text-base">
              Select region of interest
            </h1>
            <p className="text-gray-400 text-sm">
              Only one region can be displayed at a time. You can always quickly
              change regions by using the region selector on the top left.
            </p>
          </>
        }
        footer={
          <Button onClick={handleOk} type="primary">
            Select
          </Button>
        }
      >
        <RegionRadioGroup
          regions={regions}
          selectedRegionId={selectedRegionId}
          handleRegionSelect={handleRegionSelect}
        />
      </Modal>
    </Spin>
  );
};

export default RegionModal;
