import React, { ReactNode, useState } from 'react';
import AttributesPrompt from './AttributesPrompt';
import AddFilterModal from './AddFilterModal';
import {
  DeepLayerFamily,
  Filter,
  FilterAttribute,
  PromptKeys,
} from '../../../../../types';
import NumberRangeFilterPrompt from './NumberRangeFilterPrompt';
import OptionFilterPrompt from './OptionFilterPrompt';
import { filtersVar } from '../../../../../services/cache';
import { handleFilterApplied } from '../../Map/mapUtils';

interface AddFilterModalContainerProps {
  hide: () => void;
  visible: boolean;
  filterableLayerFamilies: Map<string, DeepLayerFamily>;
}

const AddFilterModalContainer = (props: AddFilterModalContainerProps) => {
  const { hide, visible, filterableLayerFamilies } = props;
  const prompts = new Map<PromptKeys, ReactNode>();
  const [currPromptKey, setCurrPromptKey] = useState(PromptKeys.Attributes);
  const [activeAttribute, setActiveAttribute] =
    useState<FilterAttribute | null>(null);

  const handleSetActiveAttribute = (attribute: FilterAttribute) => {
    const { filterType } = attribute;
    setActiveAttribute(attribute);

    if (filterType === 'continuous') {
      setCurrPromptKey(PromptKeys.Continuous);
    } else if (filterType === 'categorical') {
      setCurrPromptKey(PromptKeys.Categorical);
    }
  };

  const handleBack = () => {
    setCurrPromptKey(PromptKeys.Attributes);
  };

  const handleApplyFilter = (newFilter: Filter) => {
    const modifiedNewFilter: Filter = Object.assign({}, newFilter);
    const newFilters = new Map(filtersVar());
    const newFilterId =
      newFilters.size > 0 ? Math.max(...Array.from(newFilters.keys())) + 1 : 0;

    modifiedNewFilter.id = newFilterId;
    newFilters.set(newFilterId, modifiedNewFilter);
    filtersVar(newFilters);
    handleFilterApplied();

    setCurrPromptKey(PromptKeys.Attributes);
  };

  prompts.set(
    PromptKeys.Attributes,
    <AttributesPrompt
      hide={hide}
      setActiveAttribute={handleSetActiveAttribute}
      layerFamilies={filterableLayerFamilies}
    />
  );

  prompts.set(
    PromptKeys.Continuous,
    <NumberRangeFilterPrompt
      attribute={activeAttribute!}
      applyFilter={handleApplyFilter}
      handleBack={handleBack}
      hide={hide}
    />
  );
  prompts.set(
    PromptKeys.Categorical,
    <OptionFilterPrompt
      applyFilter={handleApplyFilter}
      attribute={activeAttribute!}
      handleBack={handleBack}
      hide={hide}
    />
  );

  if (!visible) {
    return null;
  }

  return (
    <AddFilterModal hide={hide}>{prompts.get(currPromptKey)}</AddFilterModal>
  );
};

export default AddFilterModalContainer;
