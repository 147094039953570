import React from 'react';

interface TypographyComponentProps {
  className?: string;
}

const Header1: React.FC<TypographyComponentProps> = (props) => {
  const { className, children } = props;
  return (
    <h1 className={`font-bold text-lg md:text-2xl ${className}`}>{children}</h1>
  );
};

const Header2: React.FC<TypographyComponentProps> = (props) => {
  const { className, children } = props;
  return (
    <h1 className={`font-bold text-base md:text-lg ${className}`}>
      {children}
    </h1>
  );
};

const InputLabel: React.FC<TypographyComponentProps> = (props) => {
  const { className, children } = props;
  return (
    <p className={`font-semibold text-sm md:text-base ${className}`}>
      {children}
    </p>
  );
};

const InputDescription: React.FC<TypographyComponentProps> = (props) => {
  const { className, children } = props;
  return <p className={`text-sm md:text-base ${className}`}>{children}</p>;
};

const Paragraph: React.FC<TypographyComponentProps> = (props) => {
  const { className, children } = props;
  return <p className={`text-sm md:text-base ${className}`}>{children}</p>;
};

export { Header1, Header2, InputLabel, InputDescription, Paragraph };
