import React, { useEffect, useState } from 'react';
import { DeepLayerFamily } from '../../../../types';
import FilterMenu from './FilterMenu';
import {
  getGeolevelOptions,
  getLatestLayerFamiliesByGeolevel,
  getLayerByGeolevel,
} from '../../../../utils/filter/filterUtils';
import {
  currGeolevelIdVar,
  currLayerVar,
  currRegionVar,
  filterableLayerFamiliesVar,
  filtersVar,
} from '../../../../services/cache';
import { useReactiveVar } from '@apollo/client';
import { handleFilterApplied } from '../Map/mapUtils';

interface FilterMenuContainerProps {
  productFamilyMap: Map<string, DeepLayerFamily>;
  filterLayerFamily: DeepLayerFamily;
}

const FilterMenuContainer = (props: FilterMenuContainerProps) => {
  const { productFamilyMap, filterLayerFamily } = props;
  const appliedFilters = useReactiveVar(filtersVar);
  const currGeolevelId = useReactiveVar(currGeolevelIdVar);
  const currRegion = useReactiveVar(currRegionVar);

  const filterLayers = filterLayerFamily.layers;
  const filterLayerByGeolevel = getLayerByGeolevel(filterLayers);
  const geolevelOptions = getGeolevelOptions(filterLayers!);
  const [addFilterModalVisible, setAddFilterModalVisible] = useState(false);
  const [filterableLayerFamilies, setFilterableLayerFamilies] =
    useState<null | Map<string, DeepLayerFamily>>(null);

  const showAddFilterModal = () => {
    setAddFilterModalVisible(true);
  };

  const hideAddFilterModal = () => {
    setAddFilterModalVisible(false);
  };

  const handleGeolevelSelect = (geolevelId: string) => {
    currGeolevelIdVar(geolevelId);
    currLayerVar(filterLayerByGeolevel.get(geolevelId));
  };

  const handleResetFiltersButtonClick = () => {
    filtersVar(new Map());
    handleFilterApplied();
  };

  const handleDeleteFilter = (filterId: number) => {
    const filtersCopy = new Map(filtersVar());

    filtersCopy.delete(filterId);
    filtersVar(filtersCopy);
    handleFilterApplied();
  };

  useEffect(() => {
    const layerFamilies =
      getLatestLayerFamiliesByGeolevel(productFamilyMap).get(currGeolevelId);

    if (layerFamilies) {
      filterableLayerFamiliesVar(layerFamilies);
      setFilterableLayerFamilies(layerFamilies);
    }
  }, [currGeolevelId, productFamilyMap, currRegion]);

  return (
    <FilterMenu
      geolevelOptions={geolevelOptions}
      currGeolevelId={currGeolevelId}
      handleGeolevelSelect={handleGeolevelSelect}
      selectGeolevelsDisabled={appliedFilters.size > 0}
      handleAddFilterButtonClick={showAddFilterModal}
      addFilterModalVisible={addFilterModalVisible}
      hideAddFilterModal={hideAddFilterModal}
      filterableLayerFamilies={filterableLayerFamilies!}
      handleResetFiltersButtonClick={handleResetFiltersButtonClick}
      resetFiltersButtonDisabled={appliedFilters.size === 0}
      appliedFilters={appliedFilters}
      handleDeleteFilter={handleDeleteFilter}
    />
  );
};

export default FilterMenuContainer;
