import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { RightOutlined, PlusSquareFilled } from '@ant-design/icons';
import {
  currLayerFamilyVar,
  currLayerVar,
  filtersVar,
  lastSelectedFilterLayerVar,
  mapDataLoadingVar,
} from '../../../../services/cache';
import { DeepLayerFamily, Layer } from '../../../../types';
import { events, rudderTrack } from '../../../../utils/rudderUtils';
import { Spin } from 'antd';
import { getDefaultLayer } from '../../../../utils/utils';
import { useCallback } from 'react';

interface GlobalFilterSectionComponentProps {
  onFilter: () => void;
  filterLayerFamily: DeepLayerFamily | null;
  accessibleFamilyMap: Map<string, DeepLayerFamily>;
}

const GlobalFilterSection = (props: GlobalFilterSectionComponentProps) => {
  const { onFilter, filterLayerFamily } = props;
  const currLayerFamily = useReactiveVar(currLayerFamilyVar);
  const mapDataLoading = useReactiveVar(mapDataLoadingVar);
  const filters = useReactiveVar(filtersVar);
  const lastSelectedFilterLayer: Layer = useReactiveVar(
    lastSelectedFilterLayerVar
  );

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(false);

  const selectFilterLayer = useCallback(() => {
    if (filterLayerFamily) {
      let defaultLayer: Layer;
      setSelected(true);
      currLayerFamilyVar(filterLayerFamily);

      // If there was a filter previously set.
      if (lastSelectedFilterLayer !== null && filters.size > 0) {
        defaultLayer = getDefaultLayer([lastSelectedFilterLayer]);
        currLayerVar(lastSelectedFilterLayer);
      }

      // If this is the first time the filter menu is selected.
      else {
        defaultLayer = getDefaultLayer(filterLayerFamily!.layers);
        lastSelectedFilterLayerVar(defaultLayer);
      }

      currLayerVar(defaultLayer);
    }
  }, [lastSelectedFilterLayer, filterLayerFamily, filters]);

  const handleClick = () => {
    selectFilterLayer();
    onFilter();
    rudderTrack(events.globalFilterSelected);
  };

  useEffect(() => {
    if (currLayerFamily && currLayerFamily.human_id !== 'filter') {
      setSelected(false);
    }
  }, [currLayerFamily]);

  useEffect(() => {
    setLoading(mapDataLoading);
  }, [mapDataLoading]);

  return (
    <div className="mt-2">
      <Spin spinning={loading}>
        <div
          onClick={handleClick}
          className={`flex border rounded cursor-pointer transition-all duration-300 border-blue-500 hover:bg-blue-100 text-blue-500 ${
            selected ? 'bg-blue-100' : ''
          }`}
        >
          <div className="p-4 pr-0">
            <PlusSquareFilled className="text-blue-500 relative" />
          </div>

          <div
            style={{ width: '100%' }}
            className={`flex items-center justify-between px-5 py-4 ${
              selected ? 'hover:bg-blue-100' : ''
            }`}
          >
            <h3 className="font-semibold text-blue-500">Add Filter</h3>
            <RightOutlined className={`${selected ? '' : ''}`} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default GlobalFilterSection;
