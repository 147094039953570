import { Button } from 'antd';
import React from 'react';

interface ResetFiltersButtonComponentProps {
  handleClick: () => void;
  disabled: boolean;
}

const ResetFiltersButton = (props: ResetFiltersButtonComponentProps) => {
  const { handleClick, disabled } = props;
  return (
    <Button
      type="default"
      className="ml-2"
      onClick={handleClick}
      disabled={disabled}
    >
      Reset Filters
    </Button>
  );
};

export default ResetFiltersButton;
