import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import { Region } from '../../../../types';

interface RegionImageComponentProps {
  selected: boolean;
  region: Region;
  hover: boolean;
}

const RegionImage = (props: RegionImageComponentProps) => {
  const { region, selected, hover } = props;
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const { defaultImgUrl, selectedImgUrl, hoverImgUrl } = region;
    if (selected && selectedImgUrl) {
      setImageUrl(selectedImgUrl);
      return;
    }

    if (hover && hoverImgUrl) {
      setImageUrl(hoverImgUrl);
      return;
    }

    if (defaultImgUrl) {
      setImageUrl(defaultImgUrl);
    }
  }, [selected, hover, region]);

  return <Image width={160} preview={false} alt={region.name} src={imageUrl} />;
};

export default RegionImage;
