import { gql } from '@apollo/client';

const SUBSCRIBE = gql`
  mutation Subscribe($subscriptionCatalogHumanId: String!, $interval: String!) {
    subscribe(
      subscriptionCatalogHumanId: $subscriptionCatalogHumanId
      interval: $interval
    ) {
      id
    }
  }
`;

const SIGN_UP = gql`
  mutation SignUp(
    $email: String!
    $uid: String!
    $firstName: String!
    $lastName: String!
    $organization: String!
  ) {
    signUp(
      email: $email
      uid: $uid
      firstName: $firstName
      lastName: $lastName
      organization: $organization
    ) {
      id
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $email: String!
    $first_name: String!
    $last_name: String!
  ) {
    updateUserProfile(
      email: $email
      first_name: $first_name
      last_name: $last_name
    ) {
      success
      email
      first_name
      last_name
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($uid: String) {
    deleteUser(uid: $uid) {
      success
    }
  }
`;

export { SIGN_UP, UPDATE_USER_PROFILE, DELETE_USER, SUBSCRIBE };
