import React, { useState } from 'react';
import { DeepLayerFamily } from '../../../../types';
import { Radio, Spin } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import {
  currLayerFamilyVar,
  currLayerSetVar,
  currLayerVar,
  currStyleVar,
  mapDataLoadingVar,
} from '../../../../services/cache';
import { useReactiveVar } from '@apollo/client';
import { getDefaultLayer } from '../../../../utils/utils';
import { events, rudderTrack } from '../../../../utils/rudderUtils';
import LockedFamilyButton from './LockedFamilyButton';
import { useEffect } from 'react';

interface LayerFamilyButtonProps {
  layerFamily: DeepLayerFamily;
  onLayerFamily: () => void;
  isLast: boolean;
}

const LayerFamilyButton = (props: LayerFamilyButtonProps) => {
  const { layerFamily, onLayerFamily, isLast } = props;
  const currLayerFamily: DeepLayerFamily | null =
    useReactiveVar(currLayerFamilyVar);
  const mapDataLoading = useReactiveVar(mapDataLoadingVar);
  const [selected, setSelected] = useState(false);
  const { layers } = layerFamily;

  useEffect(() => {
    if (currLayerFamily) {
      setSelected(
        layerFamily.product_family_id === currLayerFamily!.product_family_id
      );
    }
  }, [currLayerFamily, layerFamily]);

  const updateLayerFam = () => {
    currLayerFamilyVar(layerFamily);
    currLayerSetVar(layers);
    currLayerVar(getDefaultLayer(layers));
    currStyleVar(getDefaultLayer(layers).style);
  };

  const handleClick = () => {
    onLayerFamily();
    rudderTrack(events.layerDetailsSelected, {
      layerFamily: layerFamily.product_family_id,
    });
    rudderTrack(events.layerSelected, {
      layerFamily: layerFamily.product_family_id,
    });
    updateLayerFam();
  };

  const handleRadioClick = () => {
    rudderTrack(events.layerSelected, {
      layerFamily: layerFamily.product_family_id,
    });
    updateLayerFam();
  };

  if (layers.length === 0) {
    return <LockedFamilyButton isLast={isLast} layerFamily={layerFamily} />;
  }

  return (
    <div className={'mt-2'}>
      <Spin spinning={mapDataLoading} delay={100}>
        <div
          className={`p-3 flex items-center border justify-between rounded  transition-all duration-300 cursor-pointer  ${
            selected
              ? 'border-black bg-white hover:bg-gray-100'
              : 'border-gray-200 bg-gray-100 hover:bg-blue-100 hover:text-blue-600'
          }`}
          onClick={handleRadioClick}
        >
          <div className="flex">
            <div className="cursor-pointer transition-all duration-300 rounded">
              <Radio className="mr-0" value={selected} checked={selected} />
            </div>

            <div className="ml-3 mb-px">
              <h3 className={`${selected ? 'font-semibold' : ''}`}>
                {layerFamily.name}
              </h3>
            </div>
          </div>

          <div
            onClick={handleClick}
            style={{
              height: 32,
              width: 32,
            }}
            className="hover:bg-gray-200 cursor-pointer transition-all duration-300 rounded center flex items-center justify-center"
          >
            <RightOutlined
              className={`relative ${selected ? 'text-gray-900' : ''}`}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default LayerFamilyButton;
