import { DownloadOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import { Button } from 'antd';
import React from 'react';

interface ProcessedStepContentComponentProps {
  dismiss: () => void;
}

const ProcessedStepContent = (props: ProcessedStepContentComponentProps) => {
  const { dismiss } = props;

  return (
    <div className="text-center mt-24">
      <DownloadOutlined style={{ fontSize: 40 }} />

      <p className="mt-6">Your request is being processed. </p>
      <p>
        View the progress in the{' '}
        <Link className="text-blue-400" to="/platform/downloads">
          Downloads
        </Link>{' '}
        page.
      </p>
      <p className="my-6">
        Also - we’ll notify you with an email when your data is ready!
      </p>

      <Button onClick={dismiss}>Dismiss</Button>
    </div>
  );
};

export default ProcessedStepContent;
