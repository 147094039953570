import { InfoCircleTwoTone } from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { RESOLUTION_MAP } from '../../../../constants/resolutions';
import { currLayerVar, zoomVar } from '../../../../services/cache';

interface ZoomMessageComponentProps {
  active: boolean;
}

const ZoomMessage = (props: ZoomMessageComponentProps) => {
  const zoom = useReactiveVar(zoomVar);
  const currLayer = useReactiveVar(currLayerVar);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (zoom && currLayer) {
      setActive(zoom < RESOLUTION_MAP.get(currLayer.resolution)!.minzoom!);
    }
  }, [zoom, currLayer]);

  if (!active) {
    return null;
  }

  return (
    <div
      className="fixed bg-white pt-4 pb-3 px-6 shadow z-1000 flex transition-all"
      style={{ minWidth: 150, zIndex: 1000, marginLeft: 20, marginTop: 20 }}
    >
      <InfoCircleTwoTone className="text-lg mr-2" />
      <p className="text-sm">
        You're outside the zoom range of the current layer. Zoom in to see the
        data.
      </p>
    </div>
  );
};

export default ZoomMessage;
