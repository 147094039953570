import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

interface CheckmarkBulletComponentProps {
  text: string;
}

const CheckmarkBullet = (props: CheckmarkBulletComponentProps) => {
  const { text } = props;
  return (
    <div className="text-gray-800 flex mb-4">
      <CheckCircleOutlined className={'mr-2 text-gray-800 text-base'} />
      <p className={'text-gray-800 text-base'}>{text}</p>
    </div>
  );
};

export default CheckmarkBullet;
