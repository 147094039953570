import { Alert } from 'antd';
import React from 'react';

interface SelectionAlertComponentProps {
  selectionIsActive: boolean;
}

const SelectionAlert = (props: SelectionAlertComponentProps) => {
  const { selectionIsActive } = props;

  if (!selectionIsActive) {
    return null;
  }

  return (
    <div className={'absolute w-full'} style={{ height: 0, bottom: '20px' }}>
      <Alert
        showIcon={true}
        className={'mx-auto max-w-2xl'}
        style={{ zIndex: 100, bottom: '40px' }}
        type={'warning'}
        message={
          <span>
            Press and hold <b>Shift</b> to add to selection. Press and hold{' '}
            <b>Control</b> to remove from selection.
          </span>
        }
      />
    </div>
  );
};
export default SelectionAlert;
