import { RESOLUTION_MAP } from '../../constants/resolutions';
import { currLayerFamilyVar } from '../../services/cache';
import {
  Geolevel,
  KeyVal,
  Layer,
  LayersByFamily,
  DeepLayerFamily,
  FilterAttribute,
  CategoricalOption,
} from '../../types';

const add = (a: number, b: number) => {
  return a + b;
};

const getLatestLayer = (prevLayer: Layer, currLayer: Layer) => {
  const prevDateMilliseconds = new Date(prevLayer.end_date).getTime();
  const currDateMilliseconds = new Date(currLayer.end_date).getTime();

  if (currDateMilliseconds > prevDateMilliseconds) {
    return currLayer;
  }

  return prevLayer;
};

const getModernLayerFamily = (
  layersByFamily: LayersByFamily
): DeepLayerFamily => {
  const { layerFamily, layers } = layersByFamily;

  return {
    ...layerFamily,
    layers: [layers.reduce(getLatestLayer)],
  };
};

const getFilterLbf = (layersByFamily: LayersByFamily) => {
  return layersByFamily.layerFamily.human_id === 'filter';
};

const getGeolevels = (geolevelMap: Map<string, Geolevel>, layer: Layer) => {
  const { resolution } = layer;
  if (!geolevelMap.has(resolution)) {
    geolevelMap.set(resolution, RESOLUTION_MAP.get(resolution)!);
  }
  return geolevelMap;
};

const sortGeolevels = (a: Geolevel, b: Geolevel) => {
  return a.lod - b.lod;
};

const getGeolevelOptions = (layers: Layer[] = []) => {
  const geolevels = layers.reduce<Map<string, Geolevel>>(
    getGeolevels,
    new Map<string, Geolevel>()
  );
  const options: KeyVal[] = Array.from(geolevels.values())
    .sort(sortGeolevels)
    .map(({ id, label }) => ({
      label,
      value: id,
    }));

  return options;
};

const getLayerByGeolevel = (layers: Layer[] = []) => {
  const layerByGeolevel = new Map<string, Layer>();
  layers.forEach((layer) => {
    layerByGeolevel.set(layer.resolution, layer);
  });
  return layerByGeolevel;
};

const reduceLatestLayerByGeolevel = (
  latestLayerByGeolevel: Map<string, Layer>,
  layer: Layer
) => {
  const { resolution } = layer;
  const currLatestLayer = latestLayerByGeolevel.get(resolution);

  if (currLatestLayer) {
    latestLayerByGeolevel.set(
      resolution,
      getLatestLayer(layer, currLatestLayer)
    );
  } else {
    latestLayerByGeolevel.set(resolution, layer);
  }

  return latestLayerByGeolevel;
};

const getLatestLayerFamiliesByGeolevel = (
  productFamilyMap: Map<string, DeepLayerFamily>
) => {
  const layerFamilyMap = new Map<string, Map<string, DeepLayerFamily>>();

  productFamilyMap.forEach((layerFamily) => {
    const { layers } = layerFamily;
    const latestLayerByGeolevel = new Map<string, Layer>();

    if (layerFamily.human_id === 'filter') {
      return;
    }

    layers.reduce<Map<string, Layer>>(
      reduceLatestLayerByGeolevel,
      latestLayerByGeolevel
    );

    latestLayerByGeolevel.forEach((layer, resolution) => {
      const latestLayerFam: DeepLayerFamily = Object.assign(
        { ...layerFamily },
        { layers: [layer] }
      );

      if (!layerFamilyMap.has(resolution)) {
        layerFamilyMap.set(resolution, new Map());
      }

      layerFamilyMap.get(resolution)?.set(layerFamily.human_id, latestLayerFam);
    });
  });

  return layerFamilyMap;
};

const getCategoricalFilterInfo = (
  layer: Layer,
  lyrFamily: DeepLayerFamily | null = null
) => {
  const { filterDescription } = layer;
  const options: CategoricalOption[] = [];
  const layerFamily: DeepLayerFamily | null = lyrFamily
    ? lyrFamily
    : currLayerFamilyVar();
  const { category_labels } = layerFamily!;

  if (!category_labels) {
    return { description: filterDescription, options: [] };
  }

  category_labels!.forEach((label, idx) => {
    options.push({
      label,
      value: idx.toString(),
    });
  });

  return { description: filterDescription, options };
};

const getFilterAttribute = (layerFamily: DeepLayerFamily) => {
  const layer = layerFamily.layers[0];
  const filterAttribute: FilterAttribute = {
    id: layer.id,
    name: layerFamily.name ? layerFamily.name : '',
    description: layerFamily.description ? layerFamily.description : '',
    filterType: layer.filterType,
    filterInfo: {},
    layerFamily,
  };

  if (filterAttribute.filterType === 'categorical') {
    filterAttribute.filterInfo = getCategoricalFilterInfo(layer, layerFamily);
  }

  if (filterAttribute.filterType === 'continuous') {
    filterAttribute.filterInfo = {
      min: 0,
      max: 100,
      marks: {
        0: '',
        10: '',
        20: '',
        30: '',
        40: '',
        50: '',
        60: '',
        70: '',
        80: '',
        90: '',
        100: '',
      },
    };
  }
  return filterAttribute;
};

const getFilterAttributes = (layerFamMap: Map<string, DeepLayerFamily>) => {
  const layerFamSet = Array.from(layerFamMap.values());

  return layerFamSet.map(getFilterAttribute);
};

export {
  add,
  getLatestLayer,
  getModernLayerFamily,
  getFilterLbf,
  getGeolevelOptions,
  getLayerByGeolevel,
  getLatestLayerFamiliesByGeolevel,
  getFilterAttributes,
};
