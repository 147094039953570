import { useQuery, useReactiveVar } from '@apollo/client';
import { Select } from 'antd';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import {
  currRegionVar,
  filtersVar,
  mapDataLoadingVar,
} from '../../../../services/cache';
import { GET_USER_REGIONS } from '../../../../services/fetches/hekatonQueries';
import { Region } from '../../../../types';
import { zoomToLngLat } from '../Map/mapUtils';
import { getDefaultRegionId } from './regionUtils';

const { Option } = Select;

interface RegionSelectorComponentProps {
  disabled: boolean;
}

const RegionSelector = (props: RegionSelectorComponentProps) => {
  const { disabled } = props;
  const { loading, data } = useQuery(GET_USER_REGIONS, {
    skip: disabled,
  });
  const mapDataLoading = useReactiveVar(mapDataLoadingVar);
  const currRegion = useReactiveVar(currRegionVar);
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedRegionId, setSelectedRegionId] = useState<
    string | undefined
  >();

  const handleSelect = useCallback(
    (regionId: string) => {
      const region = regions.find((region) => region.id === regionId)!;
      const { flyTo } = region;
      const { center, zoom } = flyTo!;
      setSelectedRegionId(regionId);
      currRegionVar(region);
      filtersVar(new Map());
      zoomToLngLat([center[0], center[1]], undefined, zoom);
    },
    [regions]
  );

  useEffect(() => {
    if (disabled) {
      setRegions([{ id: '-1', name: 'Region' }]);
      setSelectedRegionId('-1');
    }
  }, [disabled]);

  useEffect(() => {
    if (data && data.userRegions && data.userRegions.length > 0) {
      const defaultRegionId = getDefaultRegionId(
        data.userRegions,
        currRegion ? currRegion.id : null
      );
      const region = regions.find((region) => region.id === defaultRegionId)!;
      setRegions(data.userRegions);
      setSelectedRegionId(defaultRegionId);

      if (currRegion === null) {
        currRegionVar(region);
      }
    }
  }, [data, currRegion, regions]);

  return (
    <Select
      loading={loading}
      value={selectedRegionId}
      onSelect={handleSelect}
      disabled={disabled || mapDataLoading}
    >
      {regions.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default RegionSelector;
