import React from 'react';
import ContentContainer from './ContentContainer';
import ReactMarkdown from 'react-markdown';
import DefaultTermsAndConditions from '../../../constants/copy/DefaultTermsAndConditions';
import PrivacyPolicy from '../../../constants/copy/PrivacyPolicy';
import CookiePolicy from '../../../constants/copy/CookiePolicy';
import WorldBankTermsAndConditions from '../../../constants/copy/WorldBankTermsAndConditions';

const DefaultTermsAndConditionsAgreement = {
  title: 'Terms and Conditions',
  content: (
    <ContentContainer>
      <ReactMarkdown children={DefaultTermsAndConditions} />
    </ContentContainer>
  ),
};
const WorldBankTermsAndConditionsAgreement = {
  title: 'Terms of Public Domain Data in Aperture',
  content: (
    <ContentContainer>
      <ReactMarkdown children={WorldBankTermsAndConditions} />
    </ContentContainer>
  ),
};

const PrivacyPolicyAgreement = {
  title: 'Privacy Policy',
  content: (
    <ContentContainer>
      <ReactMarkdown children={PrivacyPolicy} />
    </ContentContainer>
  ),
};

const CookiePolicyAgreement = {
  title: 'Cookie Policy',
  content: (
    <ContentContainer>
      <ReactMarkdown children={CookiePolicy} />
    </ContentContainer>
  ),
};

export {
  CookiePolicyAgreement,
  DefaultTermsAndConditionsAgreement,
  PrivacyPolicyAgreement,
  WorldBankTermsAndConditionsAgreement,
};
