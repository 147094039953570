import ReactDOMServer from 'react-dom/server';
import {
  currLayerFamilyVar,
  currLayerVar,
  filterableLayerFamiliesVar,
  mapVar,
  popupVar,
} from '../../../../services/cache';
import mapboxgl, { MapMouseEvent, PointLike } from 'mapbox-gl';
import { DeepLayerFamily, LabelDatum } from '../../../../types';
import { events, rudderTrack } from '../../../../utils/rudderUtils';
import ProductPopupContent from './ProductPopupContent';

const getPopupLabel = (
  val: number,
  roundTo = 0,
  unit: string,
  categoryLabels: string[]
) => {
  let num: number;

  if (categoryLabels && categoryLabels[val - 1]) {
    return `${categoryLabels[val - 1]}`;
  }

  if (typeof val === 'string') {
    num = parseFloat(val);
  } else {
    num = val;
  }

  if (roundTo < 0) {
    return `${(Math.round(num * 100) / 100).toLocaleString()} ${unit}`;
  }

  return `${num.toFixed(roundTo).toLocaleString()} ${unit}`;
};

const getLayerPropsById = (layerFamilies: Map<string, DeepLayerFamily>) => {
  const layerIdsAndNames: Map<string, Map<string, string>> = new Map();

  layerFamilies.forEach((family) => {
    const layer = family.layers[0];
    const layerProps = new Map();
    layerProps.set('name', family.name!);
    layerProps.set('unit', family.unit!);
    layerProps.set('roundTo', '0');
    layerProps.set(
      'categoryLabels',
      family.category_labels ? family.category_labels.join(',') : ''
    );
    layerIdsAndNames.set(layer.product_id, layerProps);
  });

  return layerIdsAndNames;
};

const showFilterPopup = (map: mapboxgl.Map, evt: MapMouseEvent) => {
  const {
    point: { x, y },
  } = evt;
  const point: PointLike = [x, y];
  const filterableLayerFamilies = filterableLayerFamiliesVar();
  const layerPropsById = getLayerPropsById(filterableLayerFamilies);
  const layerIds: string[] = Array.from(layerPropsById.keys());

  const features: mapboxgl.MapboxGeoJSONFeature[] = map.queryRenderedFeatures(
    point,
    {
      layers: [...layerIds],
    }
  );

  if (features.length > 0) {
    const featureName = features[0]!.properties!.name;

    const dataSource: LabelDatum[] = features
      .map((feature) => {
        const {
          layer: { id: productInstanceId },
        } = feature;

        const key = productInstanceId;
        const layerProps = layerPropsById.get(productInstanceId)!;
        const layerFamilyName: string = layerProps.get('name')!;
        const roundTo: number = parseInt(layerProps.get('roundTo')!);
        const unit: string = layerProps.get('unit')!;
        const categoryLabels: string[] = layerProps
          .get('categoryLabels')!
          .split(',');
        const label = getPopupLabel(
          feature!.properties!.value,
          roundTo,
          unit,
          categoryLabels
        );
        return {
          key,
          layerFamilyName,
          label,
        };
      })
      .sort((firstLabelDatum: LabelDatum, secondLabelDatum: LabelDatum) => {
        if (firstLabelDatum.layerFamilyName < secondLabelDatum.layerFamilyName)
          return -1;
        if (firstLabelDatum.layerFamilyName > secondLabelDatum.layerFamilyName)
          return 1;
        return 0;
      });

    const popup = new mapboxgl.Popup()
      .setLngLat(evt.lngLat)
      .setHTML(
        ReactDOMServer.renderToString(
          <ProductPopupContent name={featureName} dataSource={dataSource} />
        )
      )
      .addTo(map);
    popupVar(popup);
  }
};

const showProductPopup = (map: mapboxgl.Map, evt: MapMouseEvent) => {
  const {
    point: { x, y },
  } = evt;
  const point: PointLike = [x, y];
  const currLayer = currLayerVar();
  const currLayerFamily: DeepLayerFamily | null = currLayerFamilyVar()!;
  const feature: mapboxgl.MapboxGeoJSONFeature = map.queryRenderedFeatures(
    point,
    {
      layers: [currLayer.product_id],
    }
  )[0];

  if (feature) {
    const name = feature!.properties!.name;
    const label = getPopupLabel(
      feature!.properties!.value,
      currLayer.roundTo,
      currLayerFamily.unit,
      currLayerFamily.category_labels
    );

    const dataSource: LabelDatum[] = [
      {
        key: '1',
        layerFamilyName: currLayerFamily.name!,
        label,
      },
    ];

    rudderTrack(events.valueSelected, {
      name,
      productFamilyId: currLayerFamily.product_family_id,
    });

    const popup = new mapboxgl.Popup()
      .setLngLat(evt.lngLat)
      .setHTML(
        ReactDOMServer.renderToString(
          <ProductPopupContent name={name} dataSource={dataSource} />
        )
      )
      .addTo(map);
    popupVar(popup);
  }
};

const showPopup = (evt: MapMouseEvent) => {
  const map: mapboxgl.Map = mapVar()!;
  const layerFam: DeepLayerFamily | null = currLayerFamilyVar();

  if (layerFam!.human_id !== 'filter') {
    showProductPopup(map, evt);
  }

  if (layerFam!.human_id.includes('filter')) {
    showFilterPopup(map, evt);
  }
};

const formatDataSource = (dataSource: LabelDatum[]) => {
  const familyNames = new Set<string>();
  const formattedDataSource: LabelDatum[] = [];

  dataSource.forEach((datum) => {
    const { layerFamilyName } = datum;

    if (!familyNames.has(layerFamilyName)) {
      familyNames.add(layerFamilyName);
      formattedDataSource.push(datum);
    }
  });

  return formattedDataSource;
};

export { showPopup, formatDataSource };
