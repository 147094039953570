import React from 'react';
import {
  CheckmarkContentItem,
  TextContentItem,
} from '../../../../constants/subscriptionPlanInfo';
import { Header2 } from '../../../shared/Typography';
import ContentItem from './ContentItem';

interface SectionComponentProps {
  name: string;
  content: (TextContentItem | CheckmarkContentItem)[];
}

const Section = (props: SectionComponentProps) => {
  const { name, content } = props;

  return (
    <div className="my-10">
      <Header2>{name}</Header2>
      {content.map((contentItem, idx) => {
        return (
          <ContentItem
            contentItem={contentItem}
            key={idx}
            isGray={idx % 2 !== 0}
          />
        );
      })}
    </div>
  );
};

export default Section;
