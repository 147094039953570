import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  currLayerFamilyVar,
  currLayerVar,
  currStyleVar,
  filtersVar,
  currGeolevelIdVar,
} from '../../../../services/cache';
import {
  DeepLayerFamily,
  DescriptiveKeyVal,
  Filter,
  KeyVal,
  Layer,
} from '../../../../types';
import { Select, Collapse } from 'antd';
import FilterList from './FilterList';
import { events, rudderTrack } from '../../../../utils/rudderUtils';
import {
  getResolutionsKeyVals,
  getTimePeriodKeyVals,
  getTimePeriodsByResolution,
  createDescriptionOptions,
  createOptions,
} from './LegendUtils';
import ColorDefinitionListContainer from './ColorDefinitionListContainer';
import { useCallback } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import './legend.less';

const { Panel } = Collapse;

interface LegendComponentProps {
  allFamilyMap: Map<string, DeepLayerFamily>;
}

const Legend = (props: LegendComponentProps) => {
  const currLayer: Layer = useReactiveVar(currLayerVar);
  const currLayerFamily: DeepLayerFamily | null =
    useReactiveVar(currLayerFamilyVar);
  const filters: Map<number, Filter> = useReactiveVar(filtersVar);

  const [resolutionKey, setResolutionKey] = useState('');
  const [timePeriodKey, setTimePeriodKey] = useState('');
  const [resolutions, setResolutions] = useState<DescriptiveKeyVal[]>([]);
  const [isFilter, setIsFilter] = useState(false);
  const [timePeriodsByResolution, setTimePeriodsByResolution] = useState<
    Map<any, any>
  >(new Map());
  const [timePeriodKeyVals, setTimePeriodKeyVals] = useState<KeyVal[]>([]);
  const [panelName, setPanelName] = useState('');

  useEffect(() => {
    if (currLayerFamily) {
      const resolutionKeyVals = getResolutionsKeyVals(
        currLayerFamily.layers,
        currLayerFamily.resolutions!
      );
      const isFilter = currLayerFamily.human_id === 'filter';

      setPanelName(isFilter ? 'Global Filters Legend' : currLayerFamily.name!);
      setResolutions(resolutionKeyVals);
      setIsFilter(isFilter);
      setTimePeriodsByResolution(
        getTimePeriodsByResolution(currLayerFamily.layers)
      );
    }
  }, [currLayerFamily]);

  useEffect(() => {
    if (currLayer) {
      setResolutionKey(currLayer.resolution);
      setTimePeriodKey(currLayer.time_period);
    }
  }, [currLayer]);

  useEffect(() => {
    setTimePeriodKeyVals(
      getTimePeriodKeyVals(timePeriodsByResolution, resolutionKey)
    );
  }, [timePeriodsByResolution, resolutionKey]);

  const handleResolutionSelect = useCallback(
    (selectedResolution: string) => {
      let newLayer: Layer;
      let timePeriod: string = timePeriodKey;

      rudderTrack(events.geolevelSelected, { resolution: selectedResolution });

      if (resolutionKey !== selectedResolution) {
        setResolutionKey(selectedResolution);

        if (
          !timePeriodsByResolution.get(selectedResolution).has(timePeriodKey)
        ) {
          timePeriod = timePeriodsByResolution
            .get(selectedResolution)
            .keys()
            .next().value;
          setTimePeriodKey(timePeriod);
        }

        newLayer = timePeriodsByResolution
          .get(selectedResolution)
          .get(timePeriod);

        currLayerVar(newLayer);
        currStyleVar(newLayer.style);
        currGeolevelIdVar(selectedResolution);
      }
    },
    [timePeriodKey, resolutionKey, timePeriodsByResolution]
  );

  const handleTimePeriodSelect = useCallback(
    (selectedTimePeriod: string) => {
      let newLayer: Layer;

      rudderTrack(events.toiSelected, { toi: selectedTimePeriod });

      if (timePeriodKey !== selectedTimePeriod) {
        setTimePeriodKey(selectedTimePeriod);

        newLayer = timePeriodsByResolution
          .get(resolutionKey)
          .get(selectedTimePeriod);
        currLayerVar(newLayer);
      }
    },
    [timePeriodKey, timePeriodsByResolution, resolutionKey]
  );

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 270 : 90} />
      )}
      bordered={false}
      style={{
        width: 256,
        position: 'absolute',
        right: 16,
        marginTop: 24,
        zIndex: 100,
      }}
      className="bg-white"
      expandIconPosition="right"
      defaultActiveKey={[1]}
    >
      <Panel
        header={
          <div style={{ width: 208 }}>
            <h1 className="font-bold inline text-sm">{panelName}</h1>
            <p className="text-xs">
              {currLayerFamily ? currLayerFamily.unit : ''}
            </p>
          </div>
        }
        key="1"
      >
        <div style={{ maxWidth: 256 }}>
          {isFilter ? (
            <>
              <p className="text-sm text-gray-500 mb-4">
                The map displays all areas that match all filter parameters.
              </p>

              {filters.size > 0 ? <FilterList filters={filters} /> : null}
            </>
          ) : (
            <>
              <ColorDefinitionListContainer />

              <Select
                style={{ minWidth: 160 }}
                value={resolutionKey}
                className="mb-2 mr-3"
                onSelect={handleResolutionSelect}
              >
                {resolutions.map(createDescriptionOptions)}
              </Select>

              <Select
                value={timePeriodKey}
                onSelect={handleTimePeriodSelect}
                disabled={timePeriodKeyVals.length < 2}
              >
                {timePeriodKeyVals.map(createOptions)}
              </Select>
            </>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default Legend;
