import React, { FunctionComponent } from 'react';

interface LayerTagComponentProps {
  colorClass: string;
  text: string;
  icon: any;
}

const LayerTag: FunctionComponent<LayerTagComponentProps> = ({
  colorClass,
  children,
  text,
  icon,
}) => {
  return (
    <div className={`bg-${colorClass} inline-block py-1 rounded px-2`}>
      <span className="relative" style={{ top: '-2px' }}>
        {icon}
      </span>
      <h2 className="ml-2 uppercase text-xs font-semibold tracking-wide inline-block">
        {text}
      </h2>
    </div>
  );
};

export default LayerTag;
