import mapboxgl, { LngLat, MapboxGeoJSONFeature, PointLike } from 'mapbox-gl';
import {
  createSelectionActiveVar,
  selectedFeaturesCountVar,
  selectionGeometryVar,
  mapVar,
  selectionModeVar,
  selectedFeaturesVar,
  startMousePosVar,
  currLayerVar,
} from '../../../../services/cache';
import { SelectionMode } from '../../../../types';
import { getFeatureName } from '../../../../utils/mapUtils';
import { MultiPolygon, Polygon } from 'geojson';
import union from '@turf/union';
import difference from '@turf/difference';
import { selectionBoxVar } from '../../../../services/cache';

const getSelectionFilter = (features: MapboxGeoJSONFeature[]) => {
  return ['in', '$id', ...features.map((feature) => feature.id)];
};

const finishSelection = (boundingBox: [PointLike, PointLike] | null = null) => {
  let totalSelectionFeatures: MapboxGeoJSONFeature[] = [];
  let newlySelectedFeatures: MapboxGeoJSONFeature[];
  let existingFeatureNames: Set<string>;
  let newFeatureNames: Set<string>;
  let bottomLeftPoint: PointLike;
  let topRightPoint: PointLike;
  let bottomLeftLatLng: LngLat;
  let topRightLatLng: LngLat;
  let maxX: number, minX: number, maxY: number, minY: number;
  let newSelectionGeometry: Polygon;
  let updatedSelectionGeometry: Polygon | MultiPolygon | undefined;

  const currSelectionGeometry = selectionGeometryVar();
  const selectionBox = selectionBoxVar()!;
  const map = mapVar();
  const selectionMode = selectionModeVar();
  const selectedFeatures = selectedFeaturesVar();
  const currLayer = currLayerVar();

  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('keydown', onKeyDown);
  document.removeEventListener('mouseup', onMouseUp);

  if (selectionBox !== null) {
    const someBox: any = selectionBox;
    someBox.parentNode.removeChild(selectionBox);
    selectionBoxVar(null);
  }

  if (boundingBox) {
    [bottomLeftPoint, topRightPoint] = boundingBox;
    bottomLeftLatLng = map!.unproject(bottomLeftPoint);
    topRightLatLng = map!.unproject(topRightPoint);

    if (bottomLeftLatLng.lng < topRightLatLng.lng) {
      maxX = topRightLatLng.lng;
      minX = bottomLeftLatLng.lng;
    } else {
      maxX = bottomLeftLatLng.lng;
      minX = topRightLatLng.lng;
    }

    if (bottomLeftLatLng.lat < topRightLatLng.lat) {
      maxY = topRightLatLng.lat;
      minY = bottomLeftLatLng.lat;
    } else {
      maxY = bottomLeftLatLng.lat;
      minY = topRightLatLng.lat;
    }

    // Get the geometry of the bounding box.
    newSelectionGeometry = {
      type: 'Polygon',
      coordinates: [
        [
          [minX, minY],
          [maxX, minY],
          [maxX, maxY],
          [minX, maxY],
          [minX, minY],
        ],
      ],
    };

    const productInstanceId: string = currLayer.product_id;

    // Get the newly selected features.
    newlySelectedFeatures = map!.queryRenderedFeatures(boundingBox, {
      layers: [
        productInstanceId.includes('filter') ? 'filter' : productInstanceId,
      ],
    });

    if (
      selectionMode === SelectionMode.NewSelection ||
      currSelectionGeometry === null
    ) {
      /**
       * NEW_SELECTION
       * If the past selection geometry is null or we're creating a new
       * selection, then set the updated selection to be the bounding box.
       */
      totalSelectionFeatures = newlySelectedFeatures;
      updatedSelectionGeometry = newSelectionGeometry;
    } else if (selectionMode === SelectionMode.AddToSelection) {
      /**
       * ADD_TO_SELECTION
       * If we are adding to the selection and there is an existing geometry,
       * then we concatenate the features and union the geometries.
       */
      existingFeatureNames = new Set(selectedFeatures.map(getFeatureName));

      totalSelectionFeatures = [
        ...selectedFeatures,
        ...newlySelectedFeatures.filter((feature) => {
          const idAny: any = feature.id;
          return !existingFeatureNames.has(idAny);
        }),
      ];

      updatedSelectionGeometry =
        union(currSelectionGeometry, newSelectionGeometry)?.geometry ||
        newSelectionGeometry;
    } else if (selectionMode === SelectionMode.Deselection) {
      /**
       * DESELECTION
       * If we are deselecting, we have to filter out the features from our
       * existing selections and get the difference from the geometry.
       */

      // Filter out the features.
      newFeatureNames = new Set(newlySelectedFeatures.map(getFeatureName));

      totalSelectionFeatures = selectedFeatures.filter((feature) => {
        const idAny: any = feature.id;
        return !newFeatureNames.has(idAny);
      });

      updatedSelectionGeometry = difference(
        currSelectionGeometry,
        newSelectionGeometry
      )?.geometry;
    }

    selectionGeometryVar(updatedSelectionGeometry);

    map!.setFilter('selection', getSelectionFilter(totalSelectionFeatures));

    selectedFeaturesVar(totalSelectionFeatures);
    selectedFeaturesCountVar(totalSelectionFeatures.length);
  }

  map!.dragPan.enable();
  map!.boxZoom.enable();
};

const onKeyDown = (evt: KeyboardEvent) => {
  if (evt.code === '27') {
    finishSelection();
  }
};

const onMouseUp = (evt: MouseEvent) => {
  const startMousePos = startMousePosVar();
  finishSelection([startMousePos!, mousePos(evt)]);
};

const mousePos = (evt: MouseEvent) => {
  const canvas = mapVar()!.getCanvasContainer();
  const rect = canvas.getBoundingClientRect();
  return new mapboxgl.Point(
    evt.clientX - rect.left - canvas.clientLeft,
    evt.clientY - rect.top - canvas.clientTop
  );
};

const onMouseMove = (evt: MouseEvent) => {
  const currentMousePos = mousePos(evt);
  const startMousePos: any = startMousePosVar();
  const selectionBox = selectionBoxVar();
  let tempBox = selectionBox!;
  const canvas = mapVar()!.getCanvasContainer();

  if (selectionBox === null) {
    tempBox = document.createElement('div');
    tempBox.classList.add('boxdraw');
    canvas.appendChild(tempBox);
  }

  const minX = Math.min(startMousePos!.x, currentMousePos.x);
  const maxX = Math.max(startMousePos!.x, currentMousePos.x);
  const minY = Math.min(startMousePos!.y, currentMousePos.y);
  const maxY = Math.max(startMousePos!.y, currentMousePos.y);

  // Adjust width and xy position of the box element ongoing
  const pos = 'translate(' + minX + 'px,' + minY + 'px)';

  tempBox.style.transform = pos;
  tempBox.style.width = maxX - minX + 'px';
  tempBox.style.height = maxY - minY + 'px';

  selectionBoxVar(tempBox);
};

const handleMouseDown = (evt: any) => {
  const createSelectionActive = createSelectionActiveVar();

  if (!createSelectionActive) return;

  const map = mapVar();

  // Disable default drag zooming when the shift key is held down.
  map!.dragPan.disable();
  map!.boxZoom.disable();

  if (evt.shiftKey && evt.button === 0) {
    selectionModeVar(SelectionMode.AddToSelection);
  } else if (evt.ctrlKey && evt.button === 0) {
    evt.preventDefault();
    selectionModeVar(SelectionMode.Deselection);
  } else {
    selectionModeVar(SelectionMode.NewSelection);
  }

  // Call functions for the following events
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
  document.addEventListener('keydown', onKeyDown);

  // Capture the first xy coordinates
  startMousePosVar(mousePos(evt));
};

const handleMouseOver = (evt: MouseEvent) => {
  const map = mapVar();
  const tooltip = document.getElementById('selectionTooltip');
  let tooltipStartX: number;
  let tooltipEndX: number;
  let containerEndX: number;
  let breakpointX: number;
  let mapContainer: HTMLElement;

  if (map && tooltip) {
    mapContainer = map.getCanvasContainer();
    tooltipStartX = evt.clientX + 10;
    tooltipEndX = 2 * tooltip.clientWidth + 300 + tooltipStartX;
    containerEndX = mapContainer.clientLeft + mapContainer.clientWidth;
    breakpointX = 2 * containerEndX - tooltip.clientWidth;

    tooltip!.style.top = `${evt.clientY + 10}px`;
    if (tooltipEndX > breakpointX) {
      tooltip!.style.left = `${evt.clientX - 10 - tooltip.clientWidth}px`;
    } else {
      tooltip!.style.left = `${evt.clientX + 10}px`;
    }
  }
};

const handleMouseLeave = () => {};

const handleMouseEnter = () => {};

const addCanvasListeners = () => {
  const canvasContainer = mapVar()!.getCanvasContainer();

  canvasContainer.addEventListener('mousedown', handleMouseDown, true);
  canvasContainer.addEventListener('mousemove', handleMouseOver, true);
  canvasContainer.addEventListener('mouseleave', handleMouseLeave, true);
  canvasContainer.addEventListener('mouseenter', handleMouseEnter, true);
};

export { addCanvasListeners, handleMouseOver };
