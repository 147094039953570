import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ResetPasswordSection from './ResetPasswordSection';
import DeleteAccountSection from './DeleteAccountSection';
import Card from '../../shared/AtlasCard';

const SecurityPage = (props: RouteComponentProps) => {
  document.title = 'Aperture | Settings: Security';
  return (
    <Card>
      <ResetPasswordSection />
      <DeleteAccountSection />
    </Card>
  );
};

export default SecurityPage;
