import React, { useCallback } from 'react';
import { Form, message, Modal, Spin } from 'antd';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  currGeolevelIdVar,
  currRegionVar,
  selectedFeaturesCountVar,
} from '../../../../services/cache';
import { getStartJobInput } from './DownloadUtils';
import { DownloadTypes, Layer } from '../../../../types';
import {
  START_JOB_WITH_FILTER,
  START_JOB_WITH_SELECTION,
  START_JOB_WITH_ALL,
} from '../../../../services/fetches/downloadMutations';
import DownloadClient from '../../../../services/clients/DownloadClient';
import { Link } from '@reach/router';
import DownloadForm from './DownloadForm';
import { useState } from 'react';
import { RESOLUTION_MAP } from '../../../../constants/resolutions';

interface DownloadFormValues {
  name: string;
  filteredOnly: boolean | undefined;
  selectedOnly: boolean | undefined;
}

interface DownloadModalComponentProps {
  visible: boolean;
  hideModal: () => void;
  layersTree: Map<string, Map<string, Set<Layer>>>;
}

const getDownloadMode = (filteredOnly = false, selectedOnly = false) => {
  if (selectedOnly) {
    return DownloadTypes.SelectedCSV;
  }

  if (filteredOnly) {
    return DownloadTypes.FilteredCSV;
  }

  return DownloadTypes.AllCSV;
};

const DownloadModal = (props: DownloadModalComponentProps) => {
  const { visible, hideModal, layersTree } = props;
  const currRegion = useReactiveVar(currRegionVar);
  const currGeolevelId = useReactiveVar(currGeolevelIdVar);
  const selectedFeaturesCount = useReactiveVar(selectedFeaturesCountVar);
  const [productInstanceIdsToDownload, setProductInstanceIdsToDownload] =
    useState<string[]>([]);

  const [startJobWithSelection, { loading: selectionLoading }] = useMutation(
    START_JOB_WITH_SELECTION,
    { client: DownloadClient }
  );

  const [startJobWithFilter, { loading: filterLoading }] = useMutation(
    START_JOB_WITH_FILTER,
    { client: DownloadClient }
  );

  const [startJobWithAll, { loading: allLoading }] = useMutation(
    START_JOB_WITH_ALL,
    { client: DownloadClient }
  );

  const [form] = Form.useForm();

  const handleClose = () => {
    hideModal();
    form.resetFields();
  };

  const handleFinish = useCallback(
    (formValues: DownloadFormValues) => {
      const initiateDownload = async () => {
        const { name, filteredOnly, selectedOnly } = formValues;
        const downloadMode: DownloadTypes = getDownloadMode(
          filteredOnly,
          selectedOnly
        );
        const startJobInput = getStartJobInput(
          name,
          downloadMode,
          productInstanceIdsToDownload
        );
        let response;

        try {
          if (downloadMode === DownloadTypes.FilteredCSV) {
            response = await startJobWithFilter({ variables: startJobInput });
          } else if (downloadMode === DownloadTypes.SelectedCSV) {
            response = await startJobWithSelection({
              variables: startJobInput,
            });
          } else if (downloadMode === DownloadTypes.AllCSV) {
            response = await startJobWithAll({ variables: startJobInput });
          }
        } catch (err) {
          console.error(err);
        }

        if (!response || response.errors) {
          message.error('Something went wrong. Unable to download.');
        } else {
          message.success(
            <>
              Your download has started. View its progress in the{' '}
              <Link className="text-blue-500" to={'/platform/downloads'}>
                Downloads
              </Link>{' '}
              page.
            </>
          );
          form.resetFields();
          hideModal();
        }
      };

      initiateDownload();
    },
    [
      productInstanceIdsToDownload,
      startJobWithFilter,
      startJobWithSelection,
      startJobWithAll,
      form,
      hideModal,
    ]
  );

  return (
    <Modal
      visible={visible}
      title={`Download: ${currRegion ? currRegion.name : ''} - ${
        RESOLUTION_MAP.get(currGeolevelId).label
      }`}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
    >
      <Spin spinning={filterLoading || selectionLoading || allLoading}>
        <DownloadForm
          validSelection={
            selectedFeaturesCount > 0 && productInstanceIdsToDownload.length > 0
          }
          handleFinish={handleFinish}
          form={form}
          layersTree={layersTree}
          setProductInstanceIdsToDownload={setProductInstanceIdsToDownload}
        />
      </Spin>
    </Modal>
  );
};

export default DownloadModal;
