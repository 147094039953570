import React from 'react';
import { Divider, Layout } from 'antd';
import { RouteComponentProps } from '@reach/router';
import DownloadsTableContainer from './DownloadsTableContainer';

const DownloadsPage = (props: RouteComponentProps) => {
  return (
    <Layout className={'bg-white min-h-screen p-12'}>
      <div className={'container max-w-screen-lg '}>
        <h1 className={'font-bold text-3xl'}>Data Downloads</h1>
        <Divider className={'my-4'} />
        <p className={'mb-8 text-sm'}>
          Your data downloads from the last 30 days. Quickly access previous
          data requests to re-download them.
        </p>
        <DownloadsTableContainer />
      </div>
    </Layout>
  );
};

export default DownloadsPage;
