const rudderanalytics = require('rudder-sdk-js');
import axios from 'axios';

const rudderKey: string = process.env.REACT_APP_RUDDER_KEY
  ? process.env.REACT_APP_RUDDER_KEY
  : '';

const abstractKey: string = process.env.REACT_APP_ABSTRACT_KEY
  ? process.env.REACT_APP_ABSTRACT_KEY
  : '';

const events = {
  signUp: 'sign-up',
  signIn: 'sign-in',
  globalFilterSelected: 'global-filter-selected',
  filterGeolevelSelected: 'filter-geolevel-selected',
  filterTypeSelected: 'filter-type-selected',
  filterDeleted: 'filter-deleted',
  filterReset: 'filter-reset',
  layerSelected: 'layer-selected',
  layerDetailsSelected: 'layer-details-selected',
  toiSelected: 'toi-selected',
  geolevelSelected: 'geolevel-selected',
  valueSelected: 'value-inspected',
};

let ipData: any = {};

const initializeRudder = async () => {
  const ipResponse = await axios.get(
    `https://ipgeolocation.abstractapi.com/v1/?api_key=${abstractKey}`
  );

  ipData = ipResponse.data;

  rudderanalytics.ready(() => {});
  rudderanalytics.load(rudderKey, 'https://hosted.rudderlabs.com', {
    integrations: { All: true },
  });
};

const rudderIdentify = (userid: string, traits: any = {}) => {
  rudderanalytics.identify(userid, traits);
};

const rudderTrack = (evt: string, props: any = {}) => {
  if (evt === events.signUp) {
    const enrichedProps = Object.assign(props, ipData);
    rudderanalytics.track(evt, enrichedProps);
  } else {
    rudderanalytics.track(evt, props);
  }
};

export { initializeRudder, rudderIdentify, rudderTrack, events };
