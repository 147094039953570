const WORLD_BANK_TERMS_AND_CONDITIONS = `
## Welcome to ApertureTM by Atlas AI!

Atlas AI is committed to monitoring the drivers of economic development across the emerging
markets so that financial capital can advance societal well-being. As part of advancing this
mission and subject to these Terms, Atlas AI offers free, publicly-available datasets that inform
and serve many sectors of the global development community (the “Public Plan”). If you have
any questions, comments, or concerns regarding these terms or the Public Plan, please contact
us at:

Email: [legal@atlasai.co](mailto:legal@atlasai.co)
Address: 855 El Camino Real, Suite 13A #387, Palo Alto, CA 94301

These Terms of Use (the “Terms”) are a binding contract between you and ATLAS AI P.B.C
(“Atlas AI,” “we” and “us”). Your use of the Public Plan in any way means that you agree to all of
these Terms, and these Terms will remain in effect while you use the Public Plan. These Terms
include the provisions in this document as well as those in the Privacy Policy. Your use of the
Public Plan may also be subject to additional policies, rules and/or conditions (“Additional
Terms”), which are incorporated herein by reference, and you understand and agree that by
using the Public Plan, you agree to also comply with these Additional Terms.

Please read these Terms carefully. They cover important information about the Public Plan
provided to you. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR PUBLIC PLAN
IS SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE
FOLLOWING, YOU MAY NOT USE OR ACCESS THE DATASETS UNDER THE PUBLIC
PLAN IN ANY MANNER.

## Will these terms ever change?

We may update or change the Public Plan at our discretion, so these Terms may need to
change along with the Public Plan. We reserve the right to change the Terms at any time, but if
we do, we will place a notice on our site located at www.atlasai.co, send you an email, and/or
notify you by some other means.

If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means
you will no longer be able to access or use the Public Plan. If you use the Public Plan in any
way after a change to the Terms is effective, that means you agree to all of the changes.

Except for changes by us as described here, no other amendment or modification of these
Terms will be effective unless in writing and signed by both you and us.

## What about my privacy?

Atlas AI takes the privacy of its users very seriously. For the current Atlas AI Privacy Policy,
please [click here](https://www.atlasai.co/legal/privacy-policy).

## What are the basics of using the Atlas AI Public Plan?

You will be required to sign up for an account, select a password and user name (“Atlas AI User
ID”), and provide us with certain information or data, such as your contact information. You
promise to provide us with accurate, complete, and updated registration information about
yourself. You may not select as your Atlas AI User ID a name that you do not have the right to
use, or another person’s name with the intent to impersonate that person. You may not transfer
your account to anyone else without our prior written permission.

You represent and warrant that you are an individual of legal age to form a binding contract (or if
not, you’ve received your parent’s or guardian’s permission to use the Public Plan and have
gotten your parent or guardian to agree to these Terms on your behalf). If you’re agreeing to
these Terms on behalf of an organization or entity, you represent and warrant that you are
authorized to agree to these Terms on that organization’s or entity’s behalf and bind them to
these Terms (in which case, the references to “you” and “your” in these Terms, except for in this
sentence, refer to that organization or entity).

You will only use the Public Plan for your own internal, personal, non-commercial use, and not
on behalf of or for the benefit of any third party, and only in a manner that complies with all laws
that apply to you. If your use of the Public Plan is prohibited by applicable laws, then you aren’t
authorized to use the Public Plan. We can’t and won’t be responsible for your using the Public
Plan in a way that breaks the law.

You will not share your Atlas AI User ID, account or password with anyone, and you must
protect the security of your Atlas AI User ID, account, password and any other access tools or
credentials. You’re responsible for any activity associated with your Atlas AI User ID and
account.

## Does it cost anything to use the Public Plan?

No. Your use and access of the Public Plan is free, as long as you use the Public Plan
according to these Terms and the terms and conditions of the Public License (defined below). If you would like to use the Public Plan under different terms, please contact us at:
[sales@atlasai.co](sales@atlasai.co).

## Are there restrictions in how I can use the Public Plan?

Atlas AI hereby grants you a worldwide, royalty-free, revocable, non-sublicenseable, non-
exclusive license to share and reproduce the Public Plan, subject to these Terms and by the

Creative Commons Attribution-Non-Commercial-NoDerivatives 4.0 International Public License
(“Public License”). By accessing and using the Public Plan, you accept and agree to be bound
by the terms and conditions of the Public License (available in full at
[https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode](https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode)).

Your use of the Public Plan is expressly made subject to the following conditions:

- Attribution. If you share the datasets under the Public Plan, you must identify the
  following information:
  - Atlas AI as the creator;
  - A copyright notice;
  - A notice that refers to this public license; and
  - A notice that refers to the disclaimer of warranties

by publishing the following disclaimer: “This dataset and all intellectual property rights thereto are owned by Atlas AI PBC, and is distributed under the Creative Commons Attribution-Non-
Commercial-NoDerivatives 4.0 International Public License. It is offered as-is, without any warranties of any kind.”
- Non-Commercial Use Only. You may only use the Public Plan for purposes not primarily
  intended for or directed towards commercial advantage or monetary compensation.
- No Derivatives. If you remix, transform, buildup, or modify the Public Plan, you may not
  distribute the modified material.
- Sharing with No Downstream Restrictions. You may copy and redistribute the material in
  any medium or format. You may not apply legal terms or technological measures that
  legally restrict others from doing anything the Public License permits.

You further represent, warrant, and agree that you will not use the Public Plan in a manner that:

1. infringes or violates the intellectual property rights or any other rights of anyone else
   (including Atlas AI);
2. violates any applicable law or regulation, including, without limitation, any applicable
   export control laws, privacy laws or any other purpose not reasonably intended by Atlas
   AI;
3. is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory,
   obscene, or otherwise objectionable;
4. violates the security of any computer network, or cracks any passwords or security
   encryption codes;
5. “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Public
   Plan or Content (through use of manual or automated means);
6. copies or stores any significant portion of the datasets under the Public Plan; or
7. decompiles, reverse engineers, or otherwise attempts to obtain the source code or
   underlying ideas or information of or relating to the Public Plan.
   A violation of any of the foregoing is grounds for termination of your right to use or access the
   Public Plan.

## Will Atlas AI ever change the Public Plan?

We may modify or update the Public Plan over time. We may suspend or discontinue any part of
the Public Plan, or we may introduce new features or impose limits on certain features or restrict
access to parts or all of the Public Plan. We’ll try to give you notice when we make a material
change to the Public Plan that would adversely affect you, but this isn’t always practical. We
reserve the right to remove any data from the Public Plan at any time, for any reason, in our
sole discretion, and without notice.

## What if I want to stop using the Public Plan?

You’re free to do that at any time by deleting your account; please refer to our Privacy Policy, as
well as the licenses above, to understand how we treat information you provide to us after you
have stopped using our Public Plan.

Atlas AI is also free to terminate (or suspend access to) your use of the Public Plan or your
account for any reason in our discretion, including your breach of these Terms or the Public
License. Atlas AI has the sole right to decide whether you are in violation of any of the
restrictions set forth in these Terms.

## What else do I need to know?

### Warranty Disclaimer.

Atlas AI and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each
of their respective officers, directors, members, employees, consultants, contract employees,
representatives and agents, and each of their respective successors and assigns (Atlas AI and
all such parties together, the “Atlas AI Parties”) make no representations or warranties
concerning the Public Plan, including without limitation regarding any Content contained in or
accessed through the Public Plan, and the Atlas AI Parties will not be responsible or liable for
the accuracy, copyright compliance, legality, or decency of material contained in or accessed
through the Public Plan or any claims, actions, suits procedures, costs, expenses, damages or
liabilities arising out of use of, or in any way related to your participation in, the Public Plan.
Apart from the official user documentation for the Public Plan (the “Documentation”), the Atlas
AI Parties do not offer any guaranteed customer support, dataset updates, or additional documentation for the Public Plan. The Atlas AI Parties make no representations or warranties
regarding suggestions or recommendations of Public Plan or products offered or purchased
through or in connection with the Public Plan. THE PUBLIC PLAN AND CONTENT ARE
PROVIDED BY ATLAS AI (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS,
WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE PUBLIC PLAN
WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
LIMITATIONS MAY NOT APPLY TO YOU.

### Limitation of Liability.

TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION,
TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE ATLAS AI
PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT,
SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND,
INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA,
LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, DATASETS OR
TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER
OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE
BY YOU TO ATLAS AI IN CONNECTION WITH THE DATASETS IN THE TWELVE (12)
MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND
OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO
THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.

### Assignment.

You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or
your Public Plan account, in any way (by operation of law or otherwise) without Atlas AI’ prior
written consent. We may transfer, assign, or delegate these Terms and our rights and
obligations without consent.

### Miscellaneous.

You will be responsible for paying, withholding, filing, and reporting all applicable taxes, duties,
and other governmental assessments associated with your activity in connection with the Public
Plan, provided that the Atlas AI may, in its sole discretion, do any of the foregoing on your
behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these
Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the
minimum extent necessary, so that these Terms shall otherwise remain in full force and effect
and enforceable. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Atlas AI, and you do not have any authority of any kind to bind Atlas
AI in any respect whatsoever.

Except as expressly set forth in the section above regarding the arbitration agreement, you and
Atlas AI agree there are no third-party beneficiaries intended under these Terms.

`;

export default WORLD_BANK_TERMS_AND_CONDITIONS;
