import React, { useCallback, useEffect, useState } from 'react';
import { LegalAgreement, SignUpFormVals } from '../../../types';
import { useMutation } from '@apollo/client';
import { SIGN_UP, SUBSCRIBE } from '../../../services/fetches/hekatonMutations';
import { message, Modal, Spin } from 'antd';
import {
  CookiePolicyAgreement,
  DefaultTermsAndConditionsAgreement,
  PrivacyPolicyAgreement,
  WorldBankTermsAndConditionsAgreement,
} from './LegalAgreements';
import firebase from 'firebase';
import {
  events,
  rudderIdentify,
  rudderTrack,
} from '../../../utils/rudderUtils';
import { navigate } from '@reach/router';
import SUBSCRIPTION_CATALOG_IDS from '../../../constants/subscriptionCatalogIds';
import * as Sentry from '@sentry/react';

const { free } = SUBSCRIPTION_CATALOG_IDS;

interface AgreementModalComponentProps {
  visible: boolean;
  setVisible: any;
  formVals: SignUpFormVals;
  loading: boolean;
  setLoading: any;
}

const AgreementModal = (props: AgreementModalComponentProps) => {
  const { visible, setVisible, formVals, loading, setLoading } = props;

  const [modalTitle, setModalTitle] = useState('Agreements');
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [currAgreementIdx, setCurrAgreementIdx] = useState(0);
  const [agreementsToShow, setAgreementsToShow] = useState<LegalAgreement[]>(
    []
  );

  const [signUp] = useMutation(SIGN_UP);
  const [subscribe] = useMutation(SUBSCRIBE);

  const createAccount = async (values: SignUpFormVals) => {
    const { email, password, firstName, lastName, organization } = values;
    const auth = firebase.auth();
    let userCredential: firebase.auth.UserCredential;
    setLoading(true);

    try {
      userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
    } catch (error) {
      message.error(error.message);
      setLoading(false);
      Sentry.captureException(error);
      return;
    }

    const uid: string = userCredential.user?.uid
      ? userCredential.user?.uid
      : '';

    try {
      await signUp({
        variables: { email, uid, firstName, lastName, organization },
      });
    } catch (error) {
      message.error(
        "Oops we weren't able to complete your signup, please try again. If problem persists please let us know"
      );
      setLoading(false);
      Sentry.captureException(error);
      userCredential.user
        .delete()
        .then(() => {
          Sentry.captureException('User records purged from Firebase');
        })
        .catch((err) => {
          message.error(err.message);
          Sentry.captureException(err);
        });
      return;
    }

    try {
      rudderIdentify(uid, {
        email,
        firstName,
        lastName,
        organization,
        ApertureSignUp: 'true',
      });
      rudderTrack(events.signUp, {
        uid,
        email,
        firstName,
        lastName,
        organization,
        ApertureSignUp: 'true',
      });
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
      setLoading(false);
      return;
    }

    try {
      await subscribe({
        variables: {
          subscriptionCatalogHumanId: free,
          interval: 'unlimited',
        },
      });

      await navigate('/platform/aperture');
    } catch (error) {
      message.error(error.message);
      setLoading(false);
      Sentry.captureException(error);
      return;
    }
  };

  const handleOk = useCallback(() => {
    if (currAgreementIdx < agreementsToShow.length - 1) {
      setCurrAgreementIdx(currAgreementIdx + 1);
    } else if (agreementsToShow.length > 0) {
      createAccount(formVals);
    }
  }, [agreementsToShow, currAgreementIdx, formVals]);

  const handleCancel = () => {
    setVisible(false);
    setCurrAgreementIdx(0);
  };

  useEffect(() => {
    if (formVals && formVals.email.endsWith('@worldbank.org')) {
      setAgreementsToShow([
        WorldBankTermsAndConditionsAgreement,
        PrivacyPolicyAgreement,
        CookiePolicyAgreement,
      ]);
    } else if (formVals) {
      setAgreementsToShow([
        DefaultTermsAndConditionsAgreement,
        PrivacyPolicyAgreement,
        CookiePolicyAgreement,
      ]);
    }

    setCurrAgreementIdx(0);
  }, [formVals]);

  useEffect(() => {
    if (agreementsToShow.length > 0) {
      const { title, content } = agreementsToShow[currAgreementIdx];
      setModalContent(content);
      setModalTitle(title);
    }
  }, [currAgreementIdx, agreementsToShow]);

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      title={modalTitle}
      visible={visible}
      okText={'I Agree'}
      className="rounded"
    >
      <Spin spinning={loading}>{modalContent}</Spin>
    </Modal>
  );
};

export default AgreementModal;
