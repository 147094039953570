import React from 'react';

interface StepTitleComponentProps {
  title: string;
}

const StepTitle = (props: StepTitleComponentProps) => {
  const { title } = props;
  return <h1 className="text-lg font-semibold mb-3">{title}</h1>;
};

export default StepTitle;
