import axios from 'axios';
import { GeocodeFeature } from '../../types';

const geocode = async (searchText: string): Promise<GeocodeFeature[]> => {
  if (searchText.length === 0) {
    return [];
  }

  const language = 'en';
  const types =
    'country,region,postcode,district,place,locality,neighborhood,address';
  const access_token = process.env.REACT_APP_MAPBOX_TOKEN;

  const response = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json`,
    {
      params: {
        language,
        types,
        access_token,
      },
    }
  );

  return response.data.features;
};

export { geocode };
