import React from 'react';
import { DeepLayerFamily, Filter, KeyVal } from '../../../../types';
import AddFilterButton from './AddFilterButton';
import SelectGeolevelSection from './SelectGeolevelSection';
import AddFilterModalContainer from './AddFilterModal/AddFilterModalContainer';
import ResetFiltersButton from './ResetFiltersButton';
import AppliedFiltersList from './AppliedFiltersList';

interface FilterMenuComponentProps {
  geolevelOptions: KeyVal[];
  currGeolevelId: string;
  handleGeolevelSelect: (geolevelId: string) => void;
  selectGeolevelsDisabled: boolean;
  handleAddFilterButtonClick: () => void;
  hideAddFilterModal: () => void;
  addFilterModalVisible: boolean;
  filterableLayerFamilies: Map<string, DeepLayerFamily>;
  handleResetFiltersButtonClick: () => void;
  resetFiltersButtonDisabled: boolean;
  appliedFilters: Map<number, Filter>;
  handleDeleteFilter: (filterId: number) => void;
}

const FilterMenu = (props: FilterMenuComponentProps) => {
  const {
    geolevelOptions,
    currGeolevelId,
    handleGeolevelSelect,
    selectGeolevelsDisabled,
    handleAddFilterButtonClick,
    addFilterModalVisible,
    hideAddFilterModal,
    filterableLayerFamilies,
    handleResetFiltersButtonClick,
    resetFiltersButtonDisabled,
    appliedFilters,
    handleDeleteFilter,
  } = props;

  return (
    <>
      <div className="mt-4 px-2 pt-6 pb-10 bg-white">
        <h2 className="font-semibold text-base mb-1">Filters</h2>
        <p className="text-sm text-gray-500 mb-6">
          Compare attributes across different data layers to explore
          intersecting areas. Add multiple filters to narrow down areas of
          interest across different attributes.
        </p>

        <SelectGeolevelSection
          geolevelOptions={geolevelOptions}
          currGeolevelId={currGeolevelId}
          handleGeolevelSelect={handleGeolevelSelect}
          disabled={selectGeolevelsDisabled}
        />

        <div className="mb-10">
          <AddFilterButton handleClick={handleAddFilterButtonClick} />
          <ResetFiltersButton
            handleClick={handleResetFiltersButtonClick}
            disabled={resetFiltersButtonDisabled}
          />
        </div>

        <AddFilterModalContainer
          hide={hideAddFilterModal}
          visible={addFilterModalVisible}
          filterableLayerFamilies={filterableLayerFamilies}
        />

        {appliedFilters.size > 0 ? (
          <AppliedFiltersList
            filters={appliedFilters}
            deleteFilter={handleDeleteFilter}
          />
        ) : null}
      </div>
    </>
  );
};

export default FilterMenu;
