const SUBSCRIPTION_CATALOG_IDS = {
  free: 'free',
  macro: 'macro',
  enterprise: 'enterprise',
  plus: 'plus',
  departmental: 'departmental',
  campus: 'campus',
};

export default SUBSCRIPTION_CATALOG_IDS;
