import React, { useState } from 'react';
import { Button, Dropdown } from 'antd';
import DownloadMenu from './DownloadMenu';
import DownloadModal from './DownloadModal';
import { downloadMapAsPng } from '../Map/mapUtils';
import { DeepLayerFamily } from '../../../../types';
import { getLayersTree } from './DownloadUtils';
import { DownOutlined } from '@ant-design/icons';
import DownloadDrawerContainer from './DownloadDrawer/DownloadDrawerContainer';

interface DownloadWidgetComponentProps {
  disabled: boolean;
  layerFamilyMap: Map<string, DeepLayerFamily>;
}

const DownloadWidget = (props: DownloadWidgetComponentProps) => {
  const { layerFamilyMap, disabled } = props;

  const layerFamilies = Array.from(layerFamilyMap.values());
  const layersTree = getLayersTree(layerFamilies);

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [dropdownMenuIsVisible, setDropdownMenuIsVisible] = useState(false);

  const handleClick: any = (param: any) => {
    const { key } = param;

    if (key === 'png') {
      downloadMapAsPng();
      return;
    }

    if (key === 'csv') {
      setModalIsVisible(true);
    }

    if (key === 'custom') {
      setDrawerVisible(true);
    }

    setDropdownMenuIsVisible(false);
  };

  const hideModal = () => {
    setModalIsVisible(false);
  };

  const handleVisibleChange = (flag: boolean) => {
    setDropdownMenuIsVisible(flag);
  };

  return (
    <div className="flex items-center">
      <Dropdown
        overlay={<DownloadMenu handleClick={handleClick} />}
        disabled={disabled}
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={dropdownMenuIsVisible}
      >
        <Button
          onClick={() => {
            setDropdownMenuIsVisible(true);
          }}
        >
          Download <DownOutlined />
        </Button>
      </Dropdown>
      <DownloadModal
        visible={modalIsVisible}
        hideModal={hideModal}
        layersTree={layersTree}
      />
      <DownloadDrawerContainer
        visible={drawerVisible}
        hide={() => {
          setDrawerVisible(false);
        }}
      />
    </div>
  );
};

export default DownloadWidget;
