import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Layout, Spin } from 'antd';
import InsightsSider from './InsightsSider/InsightsSider';
import { GET_LAYERS_AND_LAYER_FAMILIES_BY_REGION } from '../../../services/fetches/hekatonQueries';
import { useQuery, useReactiveVar } from '@apollo/client';
import Legend from './Legend/Legend';
import {
  currGeolevelIdVar,
  currLayerFamilyVar,
  currLayerVar,
  currRegionVar,
} from '../../../services/cache';
import { DeepLayerFamily, Region } from '../../../types';
import SelectWidget from './SelectWidget/SelectWidget';
import InsightsMapContainer from './Map/InsightsMapContainer';
import InsightsHeader from './InsightsHeader';
import { getDeepLayerFamilyMap, getDefaultLayer } from '../../../utils/utils';
import RegionModal from './Regions/RegionModal';

const { Content } = Layout;

const getDefaultFamily = (layerFamilyMap: Map<string, DeepLayerFamily>) => {
  const families = Array.from(layerFamilyMap.values());
  const populationLayerFamilyArr = families.filter(
    ({ human_id }) => human_id === 'population'
  );

  // Check for total population layer family.
  if (populationLayerFamilyArr.length > 0) {
    return populationLayerFamilyArr[0];
  }

  return families[0];
};

const DesktopInsightsPage = (props: RouteComponentProps) => {
  document.title = 'Aperture | Insights';

  const currRegion = useReactiveVar(currRegionVar);
  const currLayerFamily = useReactiveVar(currLayerFamilyVar);

  const [allFamilyMap, setAllFamilyMap] = useState<
    Map<string, DeepLayerFamily>
  >(new Map());
  const [productFamilyMap, setProductFamilyMap] = useState<
    Map<string, DeepLayerFamily>
  >(new Map());
  const [accessibleFamilyMap, setAccessibleFamilyMap] = useState<
    Map<string, DeepLayerFamily>
  >(new Map());
  const [filterLayerFamily, setFilterLayerFamily] =
    useState<DeepLayerFamily | null>(null);
  const [regionQueryLoading, setRegionQueryLoading] = useState(false);
  const [regionData, setRegionData] = useState<Region | null>(null);

  const [regionModalVisible, setRegionModalVisible] = useState(true);
  const { loading, data } = useQuery(GET_LAYERS_AND_LAYER_FAMILIES_BY_REGION, {
    skip: regionModalVisible,
    variables: {
      regionId: currRegion?.id.toString(),
    },
  });

  useEffect(() => {
    if (currRegion !== null && regionModalVisible) {
      setRegionModalVisible(false);
    }
  }, [currRegion, regionModalVisible]);

  useEffect(() => {
    setRegionQueryLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setRegionData(data.region);
    }
  }, [data]);

  useEffect(() => {
    if (regionData) {
      const { layers, layerFamilies } = regionData;

      const tempAllFamilyMap = getDeepLayerFamilyMap(
        layers ? layers : [],
        layerFamilies ? layerFamilies : []
      );
      const tempProductFamilyMap = new Map<string, DeepLayerFamily>();
      const tempAccessibleFamilyMap = new Map<string, DeepLayerFamily>();
      let tempFilterLayerFamily: DeepLayerFamily | null = null;

      tempAllFamilyMap.forEach((layerFamily) => {
        const { human_id, product_family_id, layers } = layerFamily;

        if (human_id.includes('filter')) {
          tempFilterLayerFamily = layerFamily;
          return;
        }

        if (layers.length > 0) {
          tempAccessibleFamilyMap.set(product_family_id, layerFamily);
        }

        tempProductFamilyMap.set(product_family_id, layerFamily);
      });

      setAllFamilyMap(tempAllFamilyMap);
      setAccessibleFamilyMap(tempAccessibleFamilyMap);
      setProductFamilyMap(tempProductFamilyMap);
      setFilterLayerFamily(tempFilterLayerFamily);
    }
  }, [regionData]);

  useEffect(() => {
    if (!currLayerFamily && accessibleFamilyMap.size > 0) {
      const defaultFamily = getDefaultFamily(accessibleFamilyMap);
      const defaultLayer = getDefaultLayer(defaultFamily.layers);

      currLayerFamilyVar(defaultFamily);
      currLayerVar(defaultLayer);
      currGeolevelIdVar(defaultLayer.resolution);
    }
  }, [currRegion, accessibleFamilyMap, currLayerFamily]);

  useEffect(() => {
    if (accessibleFamilyMap.size > 0) {
      const defaultFamily = getDefaultFamily(accessibleFamilyMap);
      const defaultLayer = getDefaultLayer(defaultFamily.layers);

      if (defaultLayer.regionId === currRegion.id) {
        currLayerFamilyVar(defaultFamily);
        currLayerVar(defaultLayer);
        currGeolevelIdVar(defaultLayer.resolution);
      }
    }
  }, [currRegion, accessibleFamilyMap]);

  return (
    <Spin spinning={regionQueryLoading}>
      <Layout
        style={{
          height: '100vh',
        }}
      >
        <InsightsHeader
          layerFamilyMap={accessibleFamilyMap}
          regionModalVisible={regionModalVisible}
        />

        <Layout id="modalContainer" className="modal-mount">
          <RegionModal
            isVisible={regionModalVisible}
            setVisible={setRegionModalVisible}
          />
          <InsightsSider
            accessibleFamilyMap={accessibleFamilyMap}
            filterLayerFamily={filterLayerFamily}
            productFamilyMap={productFamilyMap}
          />
          <Content
            style={{
              margin: 0,
            }}
          >
            <SelectWidget />

            <Legend allFamilyMap={allFamilyMap} />
            <div className="h-full">
              <InsightsMapContainer />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default DesktopInsightsPage;
