import React, { useState } from 'react';
import firebase from 'firebase';
import { Button, message, Modal, Form, Input } from 'antd';
import { DELETE_USER } from '../../../services/fetches/hekatonMutations';
import { useMutation } from '@apollo/client';
import { Header1, InputDescription } from '../../shared/Typography';
import AtlasDivider from '../../shared/AtlasDivider';
import { Breakpoint } from 'react-socks';

const DeleteAccountSection = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteUser] = useMutation(DELETE_USER);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleConfirmPassword = async (values: any) => {
    const { password } = values;
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user?.email,
      password
    );

    try {
      await user?.reauthenticateWithCredential(credential);
      await deleteUser();
      await user?.delete();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <div className="mt-12 md:md-24">
        <Header1>Delete Account</Header1>
        <AtlasDivider />

        <Breakpoint medium down>
          <Button
            className="text-red-500 rounded px-3 my-3 font-semibold"
            onClick={showModal}
          >
            Delete Account
          </Button>
        </Breakpoint>

        <Breakpoint large up>
          <Button
            size="large"
            danger
            type="text"
            style={{ padding: 0, margin: 0 }}
            onClick={showModal}
          >
            Delete Account
          </Button>
        </Breakpoint>

        <InputDescription className="text-gray-400">
          On deleting your account, you’ll lose access to your subscriptions,
          data, and any saved workflows{' '}
        </InputDescription>
      </div>

      <Modal
        title="Enter your password to delete your account"
        visible={isModalVisible}
        closable
        onCancel={hideModal}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={handleConfirmPassword}
          requiredMark={false}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Confirm Password
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteAccountSection;
