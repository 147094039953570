import React from 'react';

const ElectrificationSVG: any = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 11.6666C17.5 11.2083 17.125 10.8333 16.6667 10.8333H15V12.5H16.6667C17.125 12.5 17.5 12.125 17.5 11.6666Z"
      fill="#24292E"
    />
    <path
      d="M16.6667 14.1666H15V15.8333H16.6667C17.125 15.8333 17.5 15.4583 17.5 15C17.5 14.5416 17.125 14.1666 16.6667 14.1666Z"
      fill="#24292E"
    />
    <path
      d="M9.99992 11.6667H8.33325V15H9.99992C9.99992 15.9167 10.7499 16.6667 11.6666 16.6667H14.1666V10H11.6666C10.7499 10 9.99992 10.75 9.99992 11.6667Z"
      fill="#24292E"
    />
    <path
      d="M4.16667 10.8333C4.16667 9.91665 4.91667 9.16665 5.83333 9.16665H7.08333C8.69167 9.16665 10 7.85831 10 6.24998C10 4.64165 8.69167 3.33331 7.08333 3.33331H4.16667C3.70833 3.33331 3.33333 3.70831 3.33333 4.16665C3.33333 4.62498 3.70833 4.99998 4.16667 4.99998H7.08333C7.775 4.99998 8.33333 5.55831 8.33333 6.24998C8.33333 6.94165 7.775 7.49998 7.08333 7.49998H5.83333C3.99167 7.49998 2.5 8.99165 2.5 10.8333C2.5 12.675 3.99167 14.1666 5.83333 14.1666H7.5V12.5H5.83333C4.91667 12.5 4.16667 11.75 4.16667 10.8333Z"
      fill="#24292E"
    />
  </svg>
);

export default ElectrificationSVG;
