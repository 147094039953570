import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { apertureTokenVar, cache } from '../cache';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_DOWNLOAD_ENDPOINT,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = apertureTokenVar();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token.length > 0 ? `Bearer ${token}` : '',
    },
  };
});

const DownloadClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default DownloadClient;
