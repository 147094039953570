import { Table } from 'antd';
import React from 'react';

const columns = [
  {
    title: 'Geographic Level',
    dataIndex: 'name',
    render: (text, row) => (
      <div>
        <h1 className="font-semibold">{text}</h1>
        <p className="text-gray-600 text-xs">{row.description}</p>
      </div>
    ),
  },
];

const data = [
  {
    key: 'admin_level_0',
    name: 'National',
    description:
      'This is equivalent to Admin Level 0 region. Represents the national aggregate. ',
  },
  {
    key: 'admin_level_1',
    name: 'Sub-national',
    description:
      'This is equivalent to Admin Level 1 region. Countries have different names -  States, Counties, etc.',
  },
  {
    key: 'admin_level_2',
    name: 'Local',
    description:
      'This is equivalent to an Admin Level 2 region. Countries have different names - Sub-counties, Districts, etc.',
  },
  {
    key: 'point',
    name: 'Settlement',
    description: 'Represents an area of 4km²',
  },
];

interface GeolevelTableComponentProps {
  selectedGeolevel: string | null;
  setSelectedGeolevel: (geolevel: string) => void;
}

const GeolevelTable = (props: GeolevelTableComponentProps) => {
  const { selectedGeolevel, setSelectedGeolevel } = props;

  return (
    <Table
      size="middle"
      className="border"
      columns={columns}
      dataSource={data}
      rowSelection={{
        type: 'radio',
        columnWidth: 50,
        selectedRowKeys: [selectedGeolevel],
        onChange: (selectedRowKeys) =>
          setSelectedGeolevel(selectedRowKeys[0].toString() || null),
      }}
      pagination={false}
    />
  );
};

export default GeolevelTable;
