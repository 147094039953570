import { currLayerFamilyVar } from '../../../../services/cache';
import { DataInsightRecord, DeepLayerFamily, Metric } from '../../../../types';

const formatLayerVal = (val: number): string => {
  const layerFamily: DeepLayerFamily | null = currLayerFamilyVar();
  const { category_labels } = layerFamily!;
  let formattedVal;

  if (category_labels && val < category_labels.length) {
    formattedVal = `${category_labels[val]}`;
  } else {
    formattedVal = (Math.round(val * 1000) / 1000).toLocaleString();
  }
  return formattedVal;
};

const columns: any = [
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    align: 'right',
  },
];

const convertMetricToRecord = (
  metric: Metric,
  idx: number
): DataInsightRecord => {
  return {
    key: idx,
    region: metric.boundaryName,
    value: formatLayerVal(metric.value),
  };
};

export { columns, convertMetricToRecord };
