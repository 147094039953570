import React from 'react';
import CheckmarkBullet from '../platform/insights/MainMenu/CheckmarkBullet';
import { Button } from 'antd';

const PremiumTooltipContent = () => {
  const textClass = 'text-base text-gray-800 mb-4 mt-1';
  const bulletText = [
    'Data at all levels: settlement, local, subnational & national',
    'Download data as image or csv',
    'Most recent data up to 2020',
    'Raster and vector data',
    'Available for all African countries',
  ];

  const handleClick = () => {
    const url =
      'https://meetings.hubspot.com/abe-tarapani/meeting-with-atlas-ai';
    window.open(url);
  };

  return (
    <div className="p-4">
      <h3 className="font-semibold text-lg">Get access to more data</h3>
      <p className={textClass}>
        Unlock access to all Population, Economic & Electrification data with
        premium plans.
      </p>

      <p className={textClass}>Includes:</p>

      {bulletText.map((value, idx) => (
        <CheckmarkBullet text={value} key={idx} />
      ))}

      <Button
        type={'primary'}
        className="w-full text-base font-semibold pb-8"
        onClick={handleClick}
      >
        Book Demo
      </Button>
    </div>
  );
};

export default PremiumTooltipContent;
