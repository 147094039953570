import {
  DescriptiveKeyVal,
  KeyVal,
  Layer,
  LayerFamily,
} from '../../../../types';
import { RESOLUTION_MAP } from '../../../../constants/resolutions';
import { Select, Tooltip } from 'antd';
import { InfoCircleFilled, LockOutlined } from '@ant-design/icons';
import React from 'react';
import PremiumTooltipContent from '../../../shared/PremiumTooltipContent';

const { Option } = Select;

const sortKeyVals = (a: KeyVal, b: KeyVal) => {
  return a.value.localeCompare(b.value);
};

const getResolutionsKeyVals = (
  layerSet: Layer[],
  allResolutions: string[] = []
) => {
  const resolutionKeyVals: DescriptiveKeyVal[] = [];
  const resolutions: Set<string> = new Set();
  const allResolutionsSet = new Set(allResolutions);

  layerSet.forEach((layer: Layer) => {
    const { resolution } = layer;
    const { label, description } = RESOLUTION_MAP.get(resolution)!;

    if (!resolutions.has(resolution)) {
      resolutions.add(resolution);
      allResolutionsSet.delete(resolution);
      resolutionKeyVals.push({
        label,
        description,
        value: resolution,
        disabled: false,
      });
    }
  });

  allResolutionsSet.forEach((value) => {
    const { label, description } = RESOLUTION_MAP.get(value)!;
    resolutionKeyVals.push({
      label,
      description,
      value,
      disabled: true,
    });
  });

  return resolutionKeyVals.sort(sortKeyVals);
};

const formatMonthYearTimePeriod = (timePeriod: string) => {
  const [year, month] = timePeriod.split('_');
  return `${formatMonthTimePeriod(month)} ${year}`;
};

const formatMonthTimePeriod = (timePeriod: string) => {
  const months = new Map([
    ['01', 'Jan'],
    ['02', 'Feb'],
    ['03', 'Mar'],
    ['04', 'Apr'],
    ['05', 'May'],
    ['06', 'Jun'],
    ['07', 'Jul'],
    ['08', 'Aug'],
    ['09', 'Sep'],
    ['10', 'Oct'],
    ['11', 'Nov'],
    ['12', 'Dec'],
  ]);

  return months.get(timePeriod);
};

const formatTimePeriod = (timePeriod: string): string => {
  if (timePeriod.includes('-')) {
    const [period1, period2] = timePeriod.split('-');
    return `${formatTimePeriod(period1)} - ${formatTimePeriod(period2)}`;
  } else if (timePeriod.includes('_')) {
    return formatMonthYearTimePeriod(timePeriod);
  } else if (parseInt(timePeriod) < 100) {
    return formatMonthTimePeriod(timePeriod)!;
  }
  return timePeriod;
};

const getTimePeriodKeyVals = (layerMap: any, resolutionKey: string) => {
  const timePeriodKeyVals: KeyVal[] = [];

  if (layerMap.has(resolutionKey)) {
    layerMap.get(resolutionKey).forEach((value: Layer, time_period: string) => {
      timePeriodKeyVals.push({
        label: formatTimePeriod(time_period),
        value: time_period,
      });
    });
  }
  return timePeriodKeyVals.sort(sortKeyVals);
};

const createOptions = (keyVal: KeyVal) => {
  const { label, value } = keyVal;
  return (
    <Option key={label} value={value}>
      {label}
    </Option>
  );
};

const createDescriptionOptions = (keyVal: DescriptiveKeyVal) => {
  const { label, value, description, disabled } = keyVal;
  return (
    <Option key={label} value={value} disabled={disabled}>
      <div className="flex justify-between">
        <div>
          {label}
          <Tooltip title={description}>
            <InfoCircleFilled className="text-gray-400 ml-2 relative" />
          </Tooltip>
        </div>

        {disabled ? (
          <Tooltip
            title={<PremiumTooltipContent />}
            color={'#f6f8ff'}
            placement="rightTop"
            overlayInnerStyle={{ width: 320 }}
          >
            <LockOutlined
              className="text-gray-400 ml-2 relative"
              style={{ marginTop: 5 }}
            />
          </Tooltip>
        ) : null}
      </div>
    </Option>
  );
};

const getTimePeriodsByResolution = (layerSet: Layer[]) => {
  const formattedLayers = new Map();

  layerSet.forEach((layer) => {
    const { resolution, time_period } = layer;
    if (!formattedLayers.has(resolution)) {
      formattedLayers.set(resolution, new Map());
    }

    const timePeriodLayerMap = formattedLayers.get(resolution);
    timePeriodLayerMap.set(time_period, layer);
  });

  return formattedLayers;
};

export {
  getTimePeriodKeyVals,
  createDescriptionOptions,
  getResolutionsKeyVals,
  getTimePeriodsByResolution,
  createOptions,
  formatTimePeriod,
};
