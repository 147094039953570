import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Filter, FilterAttribute } from '../../../../../types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const CheckboxGroup = Checkbox.Group;

interface FilterOptionVal {
  label: string;
  value: string;
}

interface OptionFilterPromptProps {
  applyFilter: any;
  attribute: FilterAttribute;
  handleBack: any;
  hide: any;
}

const OptionFilterPrompt = (props: OptionFilterPromptProps) => {
  const defaultSelectedOptions: CheckboxValueType[] = [];
  const [selectedOptions, setSelectedOptions] = useState(
    defaultSelectedOptions
  );
  const {
    attribute: {
      name,
      filterInfo: { options, description },
      layerFamily,
    },
    applyFilter,
    handleBack,
    hide,
  } = props;

  const { product_id } = layerFamily.layers[0];

  const formattedOptions: any = [];
  const optionsByVal = new Map();

  options.forEach((option: FilterOptionVal) => {
    const { label, value } = option;

    formattedOptions.push({ label, value });
    optionsByVal.set(value, label);
  });

  const handleCheckboxChange = (optionVals: CheckboxValueType[]) => {
    setSelectedOptions(optionVals);
  };

  const handleApplyFilter = () => {
    const expressions: any = [];

    let label = `${name} is `;

    selectedOptions.forEach((val, idx) => {
      const valStr = optionsByVal.get(val);
      expressions.push(['==', 'value', parseInt(val.toString()) + 1]);

      if (idx === 0) {
        label = label.concat(valStr);
      } else if (idx === selectedOptions.length - 1) {
        label = label.concat(`, or ${valStr}`);
      } else {
        label = label.concat(`, ${valStr}`);
      }
    });

    const newFilter: Filter = {
      id: 0,
      label,
      filterType: 'option',
      filterInfo: {
        option: expressions,
        productInstanceId: product_id,
      },
      expressions: [['any', ...expressions]],
    };

    applyFilter(newFilter);
    hide();
  };

  return (
    <>
      <div className="flex items-center mb-2">
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
          className="mr-2"
        />
        <span className="font-bold text-lg pt-2">{name}</span>
      </div>

      {description ? (
        <div>
          <p>{description}</p>
        </div>
      ) : null}

      <CheckboxGroup
        options={formattedOptions}
        value={selectedOptions}
        onChange={handleCheckboxChange}
        className="flex flex-col"
      />

      <div className="flex justify-end">
        <Button onClick={handleApplyFilter} type="primary">
          Apply Filter
        </Button>
      </div>
    </>
  );
};

export default OptionFilterPrompt;
