import React from 'react';
import { Paragraph } from '../../../shared/Typography';
import { CheckCircleTwoTone } from '@ant-design/icons';

interface CheckmarkContentItemComponentProps {
  name: string;
  checked: boolean;
  isGray: boolean;
}

const CheckmarkContentItem = (props: CheckmarkContentItemComponentProps) => {
  const { name, checked, isGray } = props;

  return (
    <div
      className={`flex justify-between py-3 px-1 ${
        isGray ? 'bg-gray-100' : ''
      }`}
    >
      <Paragraph className="text-gray-600">{name}</Paragraph>
      {checked ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" className="mr-1 mt-1" />
      ) : (
        <span className="mr-2">-</span>
      )}
    </div>
  );
};

export default CheckmarkContentItem;
