import React from 'react';
import {
  CheckmarkContentItem,
  TextContentItem,
} from '../../../../constants/subscriptionPlanInfo';
import TextContentItemComponent from './TextContentItem';
import CheckmarkContentItemComponent from './CheckmarkContentItem';

interface ContentItemComponentProps {
  contentItem: TextContentItem | CheckmarkContentItem;
  isGray: boolean;
}

const ContentItem = (props: ContentItemComponentProps) => {
  const { contentItem, isGray } = props;

  if ('text' in contentItem) {
    return (
      <TextContentItemComponent
        name={contentItem.name}
        text={contentItem.text}
        isGray={isGray}
      />
    );
  }

  if ('checked' in contentItem) {
    return (
      <CheckmarkContentItemComponent
        name={contentItem.name}
        checked={contentItem.checked}
        isGray={isGray}
      />
    );
  }

  return null;
};

export default ContentItem;
