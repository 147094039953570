import React, { useRef } from 'react';
import { handleMouseOver } from './canvasUtils';
interface SelectionCursorComponentProps {
  selectionIsActive: boolean;
}
const SelectionCursor = (props: SelectionCursorComponentProps) => {
  const { selectionIsActive } = props;
  const mouseTooltipRef = useRef<HTMLDivElement | null>(null);

  if (!selectionIsActive) {
    return null;
  }

  return (
    <div
      id="selectionTooltip"
      className={'bg-white rounded fixed px-2 py-2 shadow'}
      ref={mouseTooltipRef}
      style={{ width: 220 }}
      onMouseEnter={(evt) => {
        const genericEvt: any = evt;
        handleMouseOver(genericEvt);
      }}
    >
      <p className={'text-xs text-center'}>
        Click and drag to make a selection.
      </p>
    </div>
  );
};

export default SelectionCursor;
