const PRIVACY_POLICY = `
At Atlas AI, we take your privacy seriously. Please read the following to learn how we treat your personal information. **By using or accessing the Atlas AI Publicly Available Datasets (the “Datasets”) in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.**

Remember that your use of the Datasets is at all times subject to the Atlas AI Publicly Available Datasets Terms of Use (the “Terms of Use”), which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.

## What this Privacy Policy Covers

This Privacy Policy covers how we treat Personal Data that we gather when you access or use the Datasets. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules, or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.

## Sources of Personal Data

We collect Personal Data about you from:

### You

- when you provide such information directly to us, and
- when Personal Data about you is automatically collected in connection with your use of the Datasets.

**Third parties, when they provide us with Personal Data about you (“Third Parties”). Third Parties that share your Personal Data with us include:**

- **Service providers.** For example, we may use analytics service providers to analyze how you interact and engage with the Datasets, or third parties may help us provide you with customer support.

## Information Collected Automatically

The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs, and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser and tell us how and when you visit and use our Services, to analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone, or similar device when you use that device to visit our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser at this time.

We use the following types of Cookies:

- **Essential Cookies.** Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.
- **Functional Cookies.** Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).
- **Performance/Analytical Cookies.** Performance/Analytical Cookies allow us to understand how visitors use our Services such as by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those who engage with our advertising. For example, Google, Inc. (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google’s use of cookies by visiting the Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at [https://tools.google.com/dlpage/gaoptout/](https://tools.google.com/dlpage/gaoptout/).

You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your computer. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some of the Services and functionalities may not work.

To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please visit [https://ico.org.uk/for-the-public/online/cookies/](https://ico.org.uk/for-the-public/online/cookies/) or [http://www.allaboutcookies.org/](http://www.allaboutcookies.org/).

## How We Use Your Personal Data

We process Personal Data to operate, improve, understand and personalize the services we provide to you, including your use of the Datasets (the “Services”). We use Personal Data for the following purposes:

- To meet or fulfill the reason you provided the information to us.
- To communicate with you about the Services, including Service announcements, updates or offers.
- To provide support and assistance for the Services.
- To create and manage your Account or other user profiles
- To personalize website content and communications based on your preferences.
- To process orders or other transactions.
- To respond to user inquiries and fulfill user requests.
- To improve and develop the Services, including testing, research, analysis and product development.
- To protect against or deter fraudulent, illegal or harmful actions and maintain the safety, security and integrity of our Services.
- To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Use.
- To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
- For any other business purpose stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).

We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated, or incompatible purposes without providing you notice.

As noted in the list above, we may communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers or email you about your use of the Services. Also, we may receive a confirmation when you open an email from us, which helps us improve our Services. If you do not want to receive communications from us, please indicate your preference by emailing us at [MyData@atlasai.co](mailto:MyData@atlasai.co).

## How We Share Your Personal Data

### Disclosures of Personal Data for a Business Purpose

We have not disclosed your Personal Data to service providers or other parties for business purposes over the last 12 months. However, your Personal Data may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part).

### Sales of Personal Data

We have not sold your Personal Data over the last 12 months.

### Data Security and Retention

We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. For example, the Services use industry standard Secure Sockets Layer (SSL) technology to allow for the encryption of Personal Data you provide to us. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account.

We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.

### Personal Data of Children

We do not knowingly collect or solicit Personal Data from children under 16; if you are a child under 16, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 16, we will delete that information as quickly as possible. If you believe that a child under 16 may have provided us Personal Data, please contact us at [legal@atlasai.co](mailto:legal@atlasai.co).

## Other State Law Privacy Rights

### California Resident Rights

Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at [MyData@atlasai.co](MyData@atlasai.co).

### Nevada Resident Rights

If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at [MyData@atlasai.co](MyData@atlasai.co) with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.
`;

export default PRIVACY_POLICY;
