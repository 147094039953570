import React, { useEffect, useState } from 'react';
import { Router, Link } from '@reach/router';
import { Layout, Menu, message } from 'antd';
import firebase from 'firebase';
import { RouteComponentProps, Redirect } from '@reach/router';
import {
  EnvironmentOutlined,
  ReadOutlined,
  SettingOutlined,
  DownloadOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import {
  initializeUserflow,
  userflowIdentify,
} from '../../utils/userflowUtils';

import InsightsPage from './insights/InsightsPage';
import SettingsPage from './settings/SettingsPage';
import {
  isLoggedInVar,
  lastVisitedPageVar,
  mapVar,
} from '../../services/cache';
import { useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../../services/fetches/hekatonQueries';
import DownloadsPage from './downloads/DownloadsPage';

const { Sider } = Layout;
const { SubMenu } = Menu;
message.config({
  duration: 10,
});

interface PlatformContentComponentProps {
  collapsed: boolean;
}

const COLLAPSED_WIDTH = '48px';
const EXPANDED_WIDTH = '200px';

const PlatformContent = (props: PlatformContentComponentProps) => {
  const { collapsed } = props;
  const { data: profileData } = useQuery(GET_USER_PROFILE);

  if (collapsed) {
    setTimeout(() => {
      const mapCanvas: any =
        document.getElementsByClassName('mapboxgl-canvas')[0];
      if (mapCanvas) {
        mapCanvas.style.width = '100%';
      }
      mapVar()?.resize();
    }, 400);
  }

  if (profileData) {
    if (profileData.me === null) {
      firebase.auth().signOut();
      return <Redirect from="" to="/login" noThrow />;
    }

    const {
      me: { id, email, first_name, last_name },
    } = profileData;
    initializeUserflow();
    userflowIdentify(id, `${first_name} ${last_name}`, email);
  }

  return (
    <Layout
      style={{ marginLeft: collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH }}
      className="site-layout transition-all duration-300"
    >
      <Router>
        <InsightsPage path="aperture" />
        <DownloadsPage path={'downloads'} />
        <SettingsPage path="settings/*" />
      </Router>
    </Layout>
  );
};

const PlatformPage = (props: RouteComponentProps) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([
    props.location?.pathname || '',
  ]);
  const handleCollapse = (collapse: boolean) => setCollapsed(collapse);
  const PATH = props.location?.pathname || '';

  useEffect(() => {
    setSelectedKeys([PATH]);
  }, [PATH]);

  const handleClick = (clickDetails: any) => {
    const key = clickDetails.key;

    if (key === 'logout') {
      firebase.auth().signOut();
    } else if (key === 'learn') {
      window.open('https://docs.atlasai.co/');
    } else {
      setSelectedKeys([key]);
    }
  };

  if (!isLoggedIn) {
    lastVisitedPageVar(PATH);
    return <Redirect from="" to="/login" noThrow />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }} hasSider={true}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={handleCollapse}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        width={EXPANDED_WIDTH}
        collapsedWidth={COLLAPSED_WIDTH}
      >
        <div style={{ height: 45 }}></div>
        <Menu
          theme="dark"
          selectedKeys={selectedKeys}
          mode="inline"
          style={{ width: collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH }}
          onClick={handleClick}
        >
          <Menu.Item
            key="/platform/aperture"
            style={{}}
            icon={
              <EnvironmentOutlined
                style={{ position: 'relative', top: '-2px' }}
              />
            }
          >
            <Link to="aperture">Aperture</Link>
          </Menu.Item>
          <Menu.Item
            key="/platform/downloads"
            style={{}}
            icon={
              <DownloadOutlined style={{ position: 'relative', top: '-2px' }} />
            }
          >
            <Link to="downloads">Downloads</Link>
          </Menu.Item>

          <Menu.Item
            key="learn"
            style={{}}
            icon={
              <ReadOutlined style={{ position: 'relative', top: '-2px' }} />
            }
          >
            Learn
          </Menu.Item>
          <SubMenu
            key="settings"
            style={{}}
            icon={
              <SettingOutlined style={{ position: 'relative', top: '-2px' }} />
            }
            title="Settings"
          >
            <Menu.Item key="/platform/settings/profile">
              <Link to="settings/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="/platform/settings/security">
              <Link to="settings/security">Security</Link>
            </Menu.Item>
            <Menu.Item key="/platform/settings/subscription">
              <Link to="settings/subscription">Subscription</Link>
            </Menu.Item>
            <Menu.Item key="/platform/settings/sales">
              <Link to="settings/sales">Contact Sales</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="logout"
            style={{}}
            icon={
              <LogoutOutlined style={{ position: 'relative', top: '-2px' }} />
            }
          >
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <PlatformContent collapsed={collapsed} />
    </Layout>
  );
};

export default PlatformPage;
