import React from 'react';
import { DeepLayerFamily } from '../../../../types';
import GlobalFilterSection from './GlobalFilterSection';
import DomainSection from './DomainSection';
import LayerTag from './LayerTag';
import { FilterOutlined } from '@ant-design/icons';
import ReportBugButton from './ReportBugButton';
import { sortAlphabetically } from '../../../../utils/utils';

interface MainMenuProps {
  filterLayerFamily: DeepLayerFamily | null;
  productFamilyMap: Map<string, DeepLayerFamily>;
  onFilter: () => void;
  onLayerFamily: () => void;
  accessibleFamilyMap: Map<string, DeepLayerFamily>;
}

const getProductFamiliesByDomain = (
  productFamilyMap: Map<string, DeepLayerFamily>
): Map<string, DeepLayerFamily[]> => {
  const productFamiliesByDomain = new Map<string, DeepLayerFamily[]>();
  const productFamilies = Array.from(productFamilyMap.values());

  productFamilies.forEach((productFamily) => {
    const { domain } = productFamily;

    if (domain && productFamiliesByDomain.has(domain)) {
      productFamiliesByDomain.set(domain, [
        ...productFamiliesByDomain.get(domain)!,
        productFamily,
      ]);
      return;
    }

    if (domain) {
      productFamiliesByDomain.set(domain, [productFamily]);
    }
  });

  return productFamiliesByDomain;
};

const getDomainSections = (
  productFamilyMap: Map<string, DeepLayerFamily>,
  onLayerFamily: () => void
) => {
  const productFamiliesByDomain = getProductFamiliesByDomain(productFamilyMap);
  const domains = Array.from(productFamiliesByDomain.keys()).sort(
    sortAlphabetically.bind(this, false)
  );

  return domains.map((domain, idx, arr) => (
    <DomainSection
      key={domain}
      domain={domain}
      layerFamilies={productFamiliesByDomain.get(domain)!}
      onLayerFamily={onLayerFamily}
      last={idx === arr.length - 1}
    />
  ));
};

const MainMenu = (props: MainMenuProps) => {
  const {
    filterLayerFamily,
    productFamilyMap,
    onFilter,
    onLayerFamily,
    accessibleFamilyMap,
  } = props;
  const domainSections = getDomainSections(productFamilyMap, onLayerFamily);

  return (
    <>
      {filterLayerFamily !== null ? (
        <div className="mt-4 px-2 py-6 bg-white">
          <LayerTag
            colorClass="lavender-web"
            text="global filters"
            icon={<FilterOutlined className="text-xs inline-block" />}
          />
          <GlobalFilterSection
            onFilter={onFilter}
            filterLayerFamily={filterLayerFamily}
            accessibleFamilyMap={accessibleFamilyMap}
          />
        </div>
      ) : null}

      <div className="mt-6 px-2 py-6 bg-white">
        {domainSections}
        <ReportBugButton />
      </div>
    </>
  );
};

export default MainMenu;
