const populationColorRamp = [
  '#ffffd9',
  '#edf8b1',
  '#c7e9b4',
  '#7fcdbb',
  '#41b6c4',
  '#1d91c0',
  '#225ea8',
  '#253494',
  '#081d58',
];

const populationDensityColorRamp = [
  '#ffffcc',
  '#ffeda0',
  '#fed976',
  '#feb24c',
  '#fd8d3c',
  '#fc4e2a',
  '#e31a1c',
  '#bd0026',
  '#800026',
];

const consumptionColorRamp = [
  '#fff7ec',
  '#fee8c8',
  '#fdd49e',
  '#fdbb84',
  '#fc8d59',
  '#ef6548',
  '#d7301f',
  '#b30000',
  '#7f0000',
];

const electrificationColorRamp = [
  '#fcfbfd',
  '#efedf5',
  '#dadaeb',
  '#bcbddc',
  '#9e9ac8',
  '#807dba',
  '#6a51a3',
  '#54278f',
  '#3f007d',
];

const assetWealthColorRamp = [
  '#f1f558',
  '#e9d361',
  '#ddb369',
  '#cd946f',
  '#b97776',
  '#a05b7b',
  '#834182',
  '#5d2b88',
  '#131b90',
];

const assetGrowthColorRamp = [
  '#d73027',
  '#f46d43',
  '#fdae61',
  '#fee090',
  '#ffffbf',
  '#e0f3f8',
  '#abd9e9',
  '#74add1',
  '#4575b4',
];

const cropAreaColorRamp = [
  '#EDFCF0',
  '#b0f2bc',
  '#89e8ac',
  '#67dba5',
  '#4cc8a3 ',
  '#38b2a3',
  '#2c98a0',
  '#257d98',
  '#144452',
];

const cropProductionColorRamp = [
  '#e4f1e1',
  '#CDE5C8',
  '#b4d9cc',
  '#89c0b6',
  '#63a6a0',
  '#448c8a',
  '#287274',
  '#0d585f',
  '#052124',
];

const accessToElectricityColorRamp = [
  '#FCEFED',
  '#f9ddda',
  '#f2b9c4',
  '#e597b9',
  '#ce78b3',
  '#ad5fad',
  '#834ba0',
  '#573b88',
  '#372555',
];

const withoutAccessToElectricityColorRamp = [
  '#F9EFFB',
  '#f3e0f7',
  '#e4c7f1',
  '#d1afe8',
  '#b998dd',
  '#9f82ce',
  '#826dba',
  '#63589f',
  '#41396A',
];

const colorRamps: Map<string, string[]> = new Map();

colorRamps.set(
  'without_access_to_electricity_population',
  withoutAccessToElectricityColorRamp
);
colorRamps.set(
  'without_access_to_electricity_area',
  withoutAccessToElectricityColorRamp
);

colorRamps.set(
  'access_to_electricity_population',
  accessToElectricityColorRamp
);
colorRamps.set('access_to_electricity_area', accessToElectricityColorRamp);

colorRamps.set('population', populationColorRamp);
colorRamps.set('time_since_electrification', electrificationColorRamp);
colorRamps.set('population_density', populationDensityColorRamp);
colorRamps.set('recently_electrified_areas', electrificationColorRamp);
colorRamps.set('electrification', electrificationColorRamp);
colorRamps.set('consumption', consumptionColorRamp);
colorRamps.set('spending', consumptionColorRamp);
colorRamps.set('asset_wealth', assetWealthColorRamp);
colorRamps.set('asset_growth', assetGrowthColorRamp);

colorRamps.set('sorghum_area', cropAreaColorRamp);
colorRamps.set('rice_area', cropAreaColorRamp);
colorRamps.set('maize_area', cropAreaColorRamp);

colorRamps.set('sorghum_production', cropProductionColorRamp);
colorRamps.set('rice_production', cropProductionColorRamp);
colorRamps.set('maize_production', cropProductionColorRamp);

export { colorRamps };
