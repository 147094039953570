import React from 'react';

interface ColorDefinitionComponentProps {
  color: string;
  definition: string;
}

const ColorDefinition = (props: ColorDefinitionComponentProps) => {
  const { color, definition } = props;

  return (
    <div className="flex py-1 items-center">
      <div
        className="mr-2"
        style={{ width: 16, height: 16, backgroundColor: color }}
      />
      {definition}
    </div>
  );
};

export default ColorDefinition;
