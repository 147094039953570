import { Region } from '../types';

const SOUTH_ASIA_COUNTRY_CODES: Region[] = [
  {
    id: 23,
    name: 'Bangladesh',
  },
  {
    id: 36,
    name: 'Bhutan',
  },
  {
    id: 105,
    name: 'India',
  },
  {
    id: 142,
    name: 'Maldives',
  },
  {
    id: 169,
    name: 'Nepal',
  },
  {
    id: 173,
    name: 'Pakistan',
  },
  {
    id: 131,
    name: 'Sri Lanka',
  },
];

const AFRICA_COUNTRY_CODES: Region[] = [
  { id: 65, name: 'Algeria' },
  { id: 3, name: 'Angola' },
  { id: 20, name: 'Benin' },
  { id: 38, name: 'Botswana' },
  { id: 22, name: 'Burkina Faso' },
  { id: 18, name: 'Burundi' },
  { id: 46, name: 'Cameroon' },
  { id: 52, name: 'Cabo Verde' },
  { id: 39, name: 'Central African Republic' },
  { id: 217, name: 'Chad' },
  { id: 51, name: 'Comoros' },
  { id: 45, name: "Côte d'Ivoire" },
  { id: 47, name: 'Democratic Republic of the Congo' },
  { id: 61, name: 'Djibouti' },
  { id: 67, name: 'Egypt' },
  { id: 89, name: 'Equatorial Guinea' },
  { id: 68, name: 'Eritrea' },
  { id: 72, name: 'Ethiopia' },
  { id: 79, name: 'Gabon' },
  { id: 87, name: 'Gambia' },
  { id: 83, name: 'Ghana' },
  { id: 85, name: 'Guinea' },
  { id: 88, name: 'Guinea-Bissau' },
  { id: 118, name: 'Kenya' },
  { id: 132, name: 'Lesotho' },
  { id: 127, name: 'Liberia' },
  { id: 128, name: 'Libya' },
  { id: 141, name: 'Madagascar' },
  { id: 157, name: 'Malawi' },
  { id: 146, name: 'Mali' },
  { id: 153, name: 'Mauritania' },
  { id: 138, name: 'Morocco' },
  { id: 152, name: 'Mozambique' },
  { id: 160, name: 'Namibia' },
  { id: 162, name: 'Niger' },
  { id: 164, name: 'Nigeria' },
  { id: 48, name: 'Republic of Congo' },
  { id: 191, name: 'Rwanda' },
  { id: 194, name: 'Senegal' },
  { id: 200, name: 'Sierra Leone' },
  { id: 203, name: 'Somalia' },
  { id: 254, name: 'South Africa' },
  { id: 206, name: 'South Sudan' },
  { id: 193, name: 'Sudan' },
  { id: 207, name: 'São Tomé and Príncipe' },
  { id: 230, name: 'Tanzania' },
  { id: 218, name: 'Togo' },
  { id: 226, name: 'Tunisia' },
  { id: 231, name: 'Uganda' },
  { id: 69, name: 'Western Sahara' },
  { id: 255, name: 'Zambia' },
  { id: 256, name: 'Zimbabwe' },
  { id: 212, name: 'eSwatini' },
];

const COUNTRY_CODE_MAP = new Map();

[...SOUTH_ASIA_COUNTRY_CODES, ...AFRICA_COUNTRY_CODES].forEach(
  ({ id, name }) => {
    COUNTRY_CODE_MAP.set(id, name);
  }
);

export { AFRICA_COUNTRY_CODES, SOUTH_ASIA_COUNTRY_CODES, COUNTRY_CODE_MAP };
