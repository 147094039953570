import React, { FunctionComponent } from 'react';
import { Link } from '@reach/router';
import Layout, { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';

interface AuthenticationPageComponentProps {
  title: string;
  description: string;
  linkTo: string;
  linkText: string;
}

const AuthenticationPage: FunctionComponent<
  AuthenticationPageComponentProps
> = (props) => {
  const { title, description, children, linkTo, linkText } = props;

  return (
    <Layout className="h-screen">
      <Sider width={460} className="bg-white">
        <div className="p-10">
          <div>
            <a
              href="https://www.atlasai.co/product"
              className="px-2 py-1 rounded font-semibold text-xs bg-blue-100 text-blue-700 hover:bg-blue-200 transition-all duration-300"
            >
              Back to website
            </a>
          </div>

          <div className="mt-12">
            <h1 className="font-semibold text-3xl text-gray-900">{title}</h1>
            <p className="mt-2  tracking-wide mb-8 text-gray-700">
              {description}
            </p>

            {children}

            <div>
              <Link to={linkTo} className="text-sm text-center underline">
                {linkText}
              </Link>
            </div>
          </div>
        </div>
      </Sider>
      <Content className="bg-auth-page-background bg-cover bg-right"></Content>
    </Layout>
  );
};

export default AuthenticationPage;
