import { Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';
import { Region } from '../../../../types';
import RegionImage from './RegionImage';

interface RegionRadioGroupComponentProps {
  regions: Region[];
  selectedRegionId: string | number | null;
  handleRegionSelect: (regionId: string) => void;
}

const RegionRadioGroup = (props: RegionRadioGroupComponentProps) => {
  const { regions, selectedRegionId, handleRegionSelect } = props;
  const [hover, setHover] = useState<string | null>(null);

  if (regions.length === 0 || selectedRegionId === null) {
    return null;
  }

  const handleMouseEnter = (id: string) => {
    setHover(id);
  };

  const handleMouseLeave = (id: string) => {
    setHover(null);
  };

  const radioButtons = regions.map((region) => {
    const { id, name } = region;

    return (
      <Radio.Button
        onMouseEnter={handleMouseEnter.bind(this, id)}
        onMouseLeave={handleMouseLeave.bind(this, id)}
        value={id}
        key={id}
        className="w-full h-72"
      >
        <div className="w-full text-center text-lg mt-4">
          <p className="mb-5">{name}</p>
          <RegionImage
            region={region}
            hover={id === hover}
            selected={id === selectedRegionId}
          />
        </div>
      </Radio.Button>
    );
  });

  const handleChange = (evt: RadioChangeEvent) => {
    handleRegionSelect(evt.target.value);
  };

  return (
    <Radio.Group
      className="grid grid-cols-2 gap-6"
      onChange={handleChange}
      value={selectedRegionId}
    >
      {radioButtons}
    </Radio.Group>
  );
};

export default RegionRadioGroup;
