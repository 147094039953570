import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, PageHeader } from 'antd';
import React from 'react';

interface BackHeaderComponentProps {
  onBack: () => void;
}

const BackHeader = (props: BackHeaderComponentProps) => {
  const { onBack } = props;
  return (
    <div className="bg-white p-2 flex items-center">
      <Button icon={<ArrowLeftOutlined />} type="text" onClick={onBack} />
      <h1 className="pl-2 text-lg font-semibold">Back</h1>
    </div>
  );
};

export default BackHeader;
