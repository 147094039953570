import React from 'react';
import { Router } from '@reach/router';
import ProfilePage from './ProfilePage';
import SalesPage from './SalesPage';
import SecurityPage from './SecurityPage';
import SubscriptionPage from './SubscriptionPage/SubscriptionPage';

const SettingsContent = () => {
  return (
    <div className="bg-white shadow">
      <Router>
        <ProfilePage path="profile" />
        <SalesPage path="sales" />
        <SecurityPage path="security" />
        <SubscriptionPage path="subscription" />
      </Router>
    </div>
  );
};

export default SettingsContent;
