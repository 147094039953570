import {
  CompassOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Divider, Form, Input, List } from 'antd';
import React, { useState } from 'react';
import { COUNTRY_CODE_MAP } from '../../../../../constants/countryCodes';
import { RESOLUTION_MAP } from '../../../../../constants/resolutions';
import { DownloadLayerFamily } from '../../../../../types';
import StepTitle from './StepTitle';
import { formatTimePeriod } from '../../Legend/LegendUtils';

interface ConfirmStepContentComponentProps {
  countryIds: number[];
  geolevel: string;
  products: Map<string, DownloadLayerFamily>;
  downloadName: string;
  setDownloadName: (downloadName: string) => void;
}

interface ProductDatum {
  name: string;
  vintage: string;
}

const ConfirmStepContent = (props: ConfirmStepContentComponentProps) => {
  const { countryIds, geolevel, products, downloadName, setDownloadName } =
    props;
  const [showDetails, setShowDetails] = useState(false);

  const productFamilyList = [];
  const productData: ProductDatum[] = [];

  products.forEach(({ name, layers }) => {
    productFamilyList.push(name);

    productData.push({
      name,
      vintage: layers
        .map(({ time_period }) => formatTimePeriod(time_period))
        .join(', '),
    });
  });

  return (
    <div>
      <div className="mb-8">
        <StepTitle title="Name Your Download" />
        <Form layout={'vertical'}>
          <Form.Item name="name">
            <Input
              defaultValue={downloadName}
              placeholder="Enter a name for the download."
              value={downloadName}
              onChange={(evt) => {
                setDownloadName(evt.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </div>

      <Divider />

      <div>
        <StepTitle title="Download Summary" />

        <Card>
          <List.Item.Meta
            avatar={
              <Avatar icon={<GlobalOutlined />} className="bg-green-400 mt-1" />
            }
            title={<h1 className="text-sm font-semibold">Countries</h1>}
            description={
              <p className="text-sm">
                {countryIds
                  .map((code) => COUNTRY_CODE_MAP.get(code))
                  .join(', ')}
              </p>
            }
          />
        </Card>

        <Card className="mt-2">
          <List.Item.Meta
            avatar={
              <Avatar
                icon={<CompassOutlined />}
                className="bg-purple-300 mt-1"
              />
            }
            title={
              <span className="text-sm font-semibold">
                Geographic Level
                <InfoCircleOutlined className="ml-2 text-gray-400" />
              </span>
            }
            description={RESOLUTION_MAP.get(geolevel).label}
          />
        </Card>

        <Card className="mt-2">
          <div className="flex justify-between">
            <List.Item.Meta
              avatar={
                <Avatar
                  icon={<DatabaseOutlined />}
                  className="bg-blue-300 mt-1"
                />
              }
              title={<span className="text-sm font-semibold">Datasets</span>}
              description={productFamilyList.join('; ')}
            />
            <Button
              type="link"
              className="mt-2"
              onClick={
                showDetails
                  ? () => {
                      setShowDetails(false);
                    }
                  : () => {
                      setShowDetails(true);
                    }
              }
            >
              {showDetails ? 'Hide Details' : 'Details'}
            </Button>
          </div>

          {showDetails ? (
            <List
              bordered
              className="mt-5 border-gray-200"
              dataSource={productData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.name}
                    description={item.vintage}
                  />
                </List.Item>
              )}
            />
          ) : null}
        </Card>
      </div>
    </div>
  );
};

export default ConfirmStepContent;
