import React from 'react';
import { Menu, Spin, Tag, Tooltip } from 'antd';
import DownloadMenuTitle from './DownloadMenuTitle';
import { useQuery } from '@apollo/client';
import { GET_API_ACCESS } from '../../../../services/fetches/hekatonQueries';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Item, ItemGroup } = Menu;

interface DownloadMenuComponentProps {
  handleClick: any;
}

const DownloadMenu = (props: DownloadMenuComponentProps) => {
  const { handleClick } = props;
  const { loading, data } = useQuery(GET_API_ACCESS);

  return (
    <Spin spinning={loading}>
      <Menu
        onClick={handleClick}
        selectedKeys={[]}
        style={{
          boxShadow:
            '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        }}
      >
        <ItemGroup title={<DownloadMenuTitle title={'Image'} />}>
          <Item key={'png'} style={{ marginBottom: 0 }}>
            Download map as PNG
          </Item>
        </ItemGroup>
        <ItemGroup title={<DownloadMenuTitle title={'Data'} />}>
          <Item
            key={'csv'}
            style={{ marginBottom: 0 }}
            disabled={
              data
                ? !data.me.activeSubscription.subscriptionCatalog.api_access
                : true
            }
          >
            Download areas as CSV
            {data &&
            data.me &&
            !data.me.activeSubscription.subscriptionCatalog.api_access ? (
              <Tooltip
                placement="bottomRight"
                title="Downloading data is possible via a paid subscription to Aperture"
              >
                <Tag className="border-none bg-white ml-2">
                  <InfoCircleOutlined />
                </Tag>
              </Tooltip>
            ) : null}
          </Item>
          <Item
            key={'custom'}
            style={{ marginBottom: 8 }}
            disabled={
              data
                ? !data.me.activeSubscription.subscriptionCatalog.api_access
                : true
            }
          >
            Customize export...{' '}
            <Tag
              className="border border-blue-600 ml-2 text-blue-600"
              color="#F0F5FF"
            >
              NEW
            </Tag>
          </Item>
        </ItemGroup>
      </Menu>
    </Spin>
  );
};

export default DownloadMenu;
