import React from 'react';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import BrowsersDuotone from '../../../assets/images/browsers-duotone.png';

const MobileInsightsPage = () => {
  const handleClick = () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard
      .writeText('https://aperture.atlasai.co/platform/aperture')
      .then(
        () => {},
        (err) => {
          console.error('Async: Could not copy text: ', err);
        }
      );
  };

  return (
    <div className="bg-white h-screen text-center py-12 px-5">
      <div className="flex justify-center">
        <img src={BrowsersDuotone} alt="Browser" style={{ height: 150 }} />
      </div>
      <h1 className="font-semibold text-xl mt-2">
        To access data insights please use a Desktop/Tablet
      </h1>
      <p className="text-sm mt-3">
        Due to the map based data visualizations at a continent scale out
        current interface is currently optimized for larger screens.{' '}
      </p>
      <Button
        className="mt-8 rounded border-blue-500 text-blue-500 font-semibold bg-blue-100"
        onClick={handleClick}
      >
        <CopyOutlined className="relative" style={{ top: '-2px' }} />
        Copy App Link
      </Button>
    </div>
  );
};

export default MobileInsightsPage;
