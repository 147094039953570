import React, { useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Map } from 'mapbox-gl';
import {
  createSelectionActiveVar,
  currLayerVar,
  currRegionVar,
  mapVar,
  popupVar,
  selectedFeaturesCountVar,
  selectedFeaturesVar,
  zoomVar,
} from '../../../../services/cache';
import {
  cleanUp,
  getDefaultMapOptions,
  handleLoad,
  removePrevLayer,
  addCurrLayer,
  removeFilterLayersAndSources,
  handleSelectionDeactivation,
} from './mapUtils';
import { useReactiveVar } from '@apollo/client';
import { Layer } from '../../../../types';
import SelectionAlert from './SelectionAlert';
import SelectionCursor from './SelectionCursor';
import ZoomMessage from './ZoomMessage';
import { RESOLUTION_MAP } from '../../../../constants/resolutions';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

const InsightsMap = () => {
  const currLayer = useReactiveVar(currLayerVar);
  const selectionActive = useReactiveVar(createSelectionActiveVar);
  const currZoom = useReactiveVar(zoomVar);
  const popup = useReactiveVar(popupVar);
  const currMap = useReactiveVar(mapVar);
  const currRegion = useReactiveVar(currRegionVar);

  const mapRef = useRef<HTMLDivElement | null>(null);
  const [prevLayer, setPrevLayer] = useState<Layer | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [zoomAlertActive, setZoomAlertActive] = useState(false);

  const hidePopup = useCallback(() => {
    if (popup && popup.isOpen()) {
      popup.remove();
    }
  }, [popup]);

  useEffect(() => {
    hidePopup();
    handleSelectionDeactivation();
    selectedFeaturesVar([]);
    selectedFeaturesCountVar(0);
  }, [currLayer]);

  useEffect(() => {
    removeFilterLayersAndSources();
  }, [currRegion]);

  useEffect(() => {
    if (currMap && currZoom && currLayer) {
      const { resolution } = currLayer;
      const minZoom = RESOLUTION_MAP.get(resolution)!.minzoom!;

      if (currZoom < minZoom) {
        setZoomAlertActive(true);
      } else {
        setZoomAlertActive(false);
      }
    }
  }, [currZoom, currMap, currLayer]);

  useEffect(() => {
    const currMap = new Map(getDefaultMapOptions(mapRef.current!));
    setLoaded(false);
    currMap.addControl(new mapboxgl.AttributionControl(), 'top-right');
    mapVar(currMap);
    currMap.on('idle', () => {
      setLoaded(true);
      handleLoad();
    });
    return cleanUp;
  }, []);

  useEffect(() => {
    const map = mapVar();

    if (map) {
      if (prevLayer && currLayer) {
        removePrevLayer(prevLayer, currLayer);
        setPrevLayer(currLayer);
        addCurrLayer(currLayer);
      }

      if (prevLayer === null && currLayer) {
        setPrevLayer(currLayer);
        addCurrLayer(currLayer);
      }

      map.resize();
    }
  }, [currLayer, loaded, prevLayer]);

  return (
    <div ref={mapRef} className={'w-full h-full'}>
      <ZoomMessage active={zoomAlertActive} />
      <SelectionAlert selectionIsActive={selectionActive} />
      <SelectionCursor selectionIsActive={selectionActive} />
    </div>
  );
};

export default InsightsMap;
