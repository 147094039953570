import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface AddFilterButtonComponentProps {
  handleClick: () => void;
}

const AddFilterButton = (props: AddFilterButtonComponentProps) => {
  const { handleClick } = props;
  return (
    <Button type="primary" icon={<PlusOutlined />} onClick={handleClick}>
      Add Filter
    </Button>
  );
};

export default AddFilterButton;
