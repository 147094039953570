import React from 'react';
import { Filter } from '../../../../types';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { events, rudderTrack } from '../../../../utils/rudderUtils';

interface AppliedFilterListItemProps {
  filter: Filter;
  deleteFilter: any;
}

const AppliedFilterListItem = (props: AppliedFilterListItemProps) => {
  const { filter, deleteFilter } = props;

  const { label } = filter;
  const [productFamilyName, subtext] = label.split(' is ');

  const handleDelete = () => {
    deleteFilter();
    rudderTrack(events.filterDeleted);
  };

  return (
    <div className="flex border justify-between mt-2 px-2 py-3">
      <div className="pr-2">
        <p className="font-semibold">{productFamilyName}</p>
        <p className="text-xs leading-6 text-gray-800">is {subtext}</p>
      </div>
      <div
        className="flex justify-center hover:bg-red-100 transition-all duration-300"
        style={{ width: 32, height: 32 }}
      >
        <Button
          type="text"
          danger
          size="middle"
          icon={<DeleteOutlined />}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default AppliedFilterListItem;
