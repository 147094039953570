import React, { useEffect } from 'react';
import { AutoComplete, Input } from 'antd';
import { useState } from 'react';
import { geocode } from '../../../services/fetches/mapboxGeocodeQuery';
import { GeocodeFeature } from '../../../types';
import { OptionData } from 'rc-select/lib/interface';
import { useCallback } from 'react';
import { zoomToLngLat } from './Map/mapUtils';
import mapboxgl from 'mapbox-gl';

interface GeocoderComponentProps {
  disabled: boolean;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const Geocoder = (props: GeocoderComponentProps) => {
  const { disabled } = props;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<OptionData[]>([]);
  const [featureMap, setFeatureMap] = useState<Map<string, GeocodeFeature>>(
    new Map()
  );

  const onSearch = async (searchText: string) => {
    const features = await geocode(searchText);
    const newOptions: OptionData[] = [];
    const newFeatureMap = new Map<string, GeocodeFeature>();

    features.forEach((feature) => {
      const id: any = feature.id ? feature.id : '';

      newOptions.push({ label: feature.place_name, value: id });
      newFeatureMap.set(id, feature);
    });

    setOptions(newOptions);
    setFeatureMap(newFeatureMap);
  };

  const onSelect = useCallback(
    (featureId) => {
      const { geometry, place_name, place_type } = featureMap.get(featureId)!;
      const geom: any = geometry;
      const point: GeoJSON.Point = geom;
      const lngLat: mapboxgl.LngLatLike = [
        point.coordinates[0],
        point.coordinates[1],
      ];
      setValue(place_name);
      zoomToLngLat(lngLat, place_type[0]);
    },
    [featureMap]
  );

  const onChange = (searchText: string) => {
    setValue(searchText);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AutoComplete
      disabled={disabled}
      value={value}
      className="autocomplete"
      onChange={onChange}
      onSelect={onSelect}
      onSearch={onSearch}
      style={
        windowDimensions.width >= 768 && windowDimensions.width <= 1194
          ? { width: 400 }
          : { width: 496 }
      }
      options={options}
      placeholder={'Search address or place'}
    >
      <Input.Search />
    </AutoComplete>
  );
};

export default Geocoder;
