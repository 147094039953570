import { Feature } from 'geojson';

export interface FilterAttribute {
  id: string;
  name: string;
  description: string;
  filterType: string;
  filterInfo: any;
  layerFamily: DeepLayerFamily;
}

export interface Filter {
  id: number;
  label: string;
  filterType: string;
  filterInfo: any;
  expressions: ([string, string, number] | (string | any)[])[];
}

export interface Region {
  id: string | number;
  name: string;
  description?: string;
  flyTo?: FlyTo;
  defaultImgUrl?: string;
  hoverImgUrl?: string;
  selectedImgUrl?: string;
  layers?: Layer[];
  layerFamilies?: LayerFamily[];
}

export interface FlyTo {
  center: [number, number];
  zoom: number;
}

export interface StatisticColumn {
  id: string;
  title: string;
}

export interface Statistic {
  id: string;
  label: string;
  value: string;
}

export interface Layer {
  id: string;
  name: string;
  time_period: string;
  resolution: string;
  style: string | null;
  product_id: string;
  product_family_id: string;
  filterType: string;
  filterDescription: string | null;
  end_date: string;
  familyName?: string;
  regionId?: string;
}

export interface LayerFamily {
  name?: string;
  domain?: string;
  description?: string | null;
  coverage?: string;
  last_update?: string | null;
  update_frequency?: string;
  human_id: string;
  resolutions?: string[];
  product_family_id: string;
  category_labels: string[];
  unit: string;
  unitDescription: string;
  roundTo?: number;
}

export interface LayersByFamily {
  layerFamily: LayerFamily;
  layers: Layer[];
}

export interface ProductDescription {
  description: string;
  title: string;
  type: string;
  temporal_extent: string;
  temporal_resolution: string;
  coverage: string;
  spatial_resolution: string;
  unit: string;
}

export interface LayerAndFamily {
  layer: Layer;
  layerFamily: LayerFamily;
}
export interface KeyVal {
  label: string;
  value: string;
}

export interface DescriptiveKeyVal extends KeyVal {
  description: string;
  disabled?: boolean;
}

export interface LegalAgreement {
  title: string;
  content: JSX.Element;
}

export interface SignUpFormVals {
  firstName: string;
  lastName: string;
  organization: string;
  password: string;
  email: string;
}

export enum SelectionMode {
  NewSelection = 'NEW_SELECTION',
  AddToSelection = 'ADD_TO_SELECTION',
  Deselection = 'DESELECTION',
}

export enum DownloadJobStatus {
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
}

export interface DownloadDatum {
  key: string;
  jobName: string;
  dataUri: string;
  timeRequested: string;
  jobStatus: DownloadJobStatus;
}

export enum AtlasOperator {
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  LessThan = 'LESS_THAN',
  GreaterThan = 'GREATER_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  In = 'IN',
  NotIn = 'NOT_IN',
}

export enum MapboxOperator {
  GreaterThanOrEqualTo = '>=',
  LessThanOrEqualTo = '<=',
  Any = 'any',
}

export enum OutputFields {
  FeatureId = 'FEATURE_ID',
  GeographicLevel = 'GEOGRAPHIC_LEVEL',
  Name = 'NAME',
  FeatureGeometry = 'FEATURE_GEOMETRY',
}

export enum OutputFormat {
  Csv = 'CSV',
}

export enum DownloadTypes {
  AllCSV = 'all-csv',
  SelectedCSV = 'selected-csv',
  FilteredCSV = 'filtered-csv',
  CountryCSV = 'country-csv',
}

export interface AtlasPoint {
  x: number;
  y: number;
}

export interface FilterInput {
  operator: AtlasOperator;
  values: number[];
}

export interface ProductInstanceFilterInput {
  productInstanceId: string;
  filters?: FilterInput[];
}

export interface StartJobInput {
  productInstanceIds?: string[];
  productInstanceFilters?: ProductInstanceFilterInput[];
  geographicFilter?: string[];
  jobName?: string;
  outputFields?: OutputFields[];
  outputFormat?: OutputFormat;
}

export interface Geolevel {
  id: string;
  lod: number;
  admin: boolean;
  label: string;
  description: string;
  minzoom?: number;
  maxzoom?: number;
}

export interface AtlasBoundingBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface DeepLayerFamily extends LayerFamily {
  layers: Layer[];
}

export interface CategoricalOption {
  label: string;
  value: string;
}

export interface RawOption {
  value: number;
  description: string;
}

export enum PromptKeys {
  Attributes = 'ATTRIBUTES',
  Continuous = 'CONTINUOUS',
  Categorical = 'CATEGORICAL',
}

export interface Distribution {
  productInstanceId: string;
  bucketId: number;
  bucketStart: number;
  bucketEnd: number;
  count: number;
}

export interface LabelDatum {
  key: string;
  layerFamilyName: string;
  label: string;
}

export interface LegendBin {
  min: number;
  max: number;
  binId: number;
}

export enum StyleLayerType {
  Product = 'PRODUCT',
  Filter = 'FILTER',
  Selection = 'SELECTION',
}

export interface DownloadJob {
  jobId: string;
  jobName: string;
  dataUri: string;
  timeRequested: any;
  jobStatus: any;
}

export interface Metric {
  boundaryName: string;
  value: number;
}

export interface DataInsightRecord {
  key: number;
  region: string;
  value: string;
}

export interface GeocodeFeature extends Feature {
  place_name: string;
  place_type: PlaceType[];
  id: string;
}

export enum PlaceType {
  Country = 'country',
  Region = 'region',
  Postcode = 'postcode',
  District = 'district',
  Place = 'place',
  Locality = 'locality',
  Neighborhood = 'neighborhood',
  Address = 'address',
}

export interface DownloadLayerFamily {
  description: string;
  name: string;
  product_family_id: string;
  layers?: DownloadLayer[];
}

export interface DownloadLayer {
  product_family_id: string;
  product_id: string;
  resolution: string;
  time_period: string;
}

export interface GeometryData {
  name: string;
  geometry: string;
}

export interface GeoJsonData {
  data: GeometryData[];
}
