import { Geolevel } from '../types';

const RESOLUTIONS: Geolevel[] = [
  {
    id: 'admin_level_0',
    lod: 0,
    admin: true,
    label: 'National',
    description: 'Equivalent to an Admin Level 0 region',
    minzoom: 0,
    maxzoom: 2,
  },
  {
    id: 'admin_level_1',
    lod: 1,
    admin: true,
    label: 'Sub-National',
    description:
      'Equivalent to an Admin Level 1 region. Countries have different names - States, Counties, etc',
    minzoom: 2,
    maxzoom: 5,
  },
  {
    id: 'admin_level_2',
    lod: 2,
    admin: true,
    label: 'Local',
    description:
      'This is equivalent to an Admin Level 2 region. Countries have different names - Sub-Counties, Districts, etc',
    minzoom: 5,
    maxzoom: 7,
  },
  {
    id: 'point',
    lod: 3,
    admin: false,
    label: 'Settlement',
    description: 'This represents an area of 4 km²',
    minzoom: 7,
    maxzoom: 7,
  },
];

const RESOLUTION_MAP = new Map<string, Geolevel>();

RESOLUTIONS.forEach((resolution) => {
  RESOLUTION_MAP.set(resolution.id, resolution);
});

export { RESOLUTIONS, RESOLUTION_MAP };
